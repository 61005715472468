import React, { useState } from "react";
import {
  setConnectionPopup,
  setNewConnections,
} from "../../../features/connections";
import { useDispatch, useSelector } from "react-redux";
import { ImCancelCircle } from "react-icons/im";

const ConnectionsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { connections } = useSelector((state) => state.connections);
  const [selectedType, setSelectedType] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const providerOptions = ["MySQL", "PostgreSQL", "SQL Server", "Oracle"];
  // Extract only parent objects (unique connection types)
  const uniqueTypes = [...new Set(connections.map((conn) => conn.type))];

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSelectedType(type);

    // Find the first matching parent connection
    const parent = connections.find((conn) => conn.type === type);
    setSelectedParent(parent);

    // Reset form fields based on selected parent
    const newFormData =
      parent?.input_items?.reduce((acc, item) => {
        acc[item.name] = item.name === "provider" ? providerOptions[0] : ""; // Default provider value
        return acc;
      }, {}) || {};
    setFormData(newFormData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Remove error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() ? "" : "This field is required",
    }));
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim()) {
        newErrors[key] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    dispatch(
      setNewConnections({
        parent_id: selectedParent?.id,
        newConnection: {
          ...formData,
          conn_id: Math.floor(1000 + Math.random() * 9000),
        },
      })
    );

    dispatch(
      setConnectionPopup({
        show: true,
        message: `${formData?.connection_name} Connection is Created`,
      })
    );

    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-30"
        onClick={onClose}
      ></div>
      <div className="relative bg-white p-6 shadow-lg max-w-md w-full min-h-[88vh] max-h-[88vh] overflow-y-scroll">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <ImCancelCircle />
        </button>
        <h2 className="text-lg font-semibold">Create New Connection</h2>
        <p className="mt-2 text-gray-600">
          Select a connection type and enter details.
        </p>

        {/* Select Type Dropdown */}

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Select Type
          </label>
          <select
            className="w-full p-2 border rounded"
            value={selectedType}
            onChange={handleTypeChange}
          >
            <option value="">-- Select Type --</option>
            {uniqueTypes.map((type) => (
              <option key={type} value={type}>
                {type.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* Dynamic Inputs based on Selected Type */}
        {selectedParent && (
          <form className="mt-4 space-y-3">
            {selectedParent.input_items.map((item, index) => (
              <div key={index}>
                <label className="block text-sm font-medium text-gray-700">
                  {item.label}
                </label>
                {item?.name === "provider" ? (
                  <select
                    className="w-full p-2 border rounded"
                    value={formData[item.name] || ""}
                    onChange={handleInputChange}
                    name={item.name} // Corrected: Use item.name dynamically
                  >
                    {providerOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    name={item.name}
                    value={formData[item.name] || ""}
                    onChange={handleInputChange}
                    className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 ${errors[item.name]
                        ? "border-red-500 focus:ring-red-500"
                        : "border-gray-300 focus:ring-blue-500"
                      }`}
                  />
                )}

                {errors[item.name] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[item.name]}
                  </p>
                )}
              </div>
            ))}

            <button
              type="button"
              className="w-full mt-4 px-4 py-2 bg-blue-600 text-white hover:bg-blue-700"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ConnectionsModal;