import React, { useState } from "react";
import ProjectNameInput from "../ProjectNameInput";
import RunnerInput from "../RunnerInput";
import YamlGenerator from "../YamlGenerator";
import Buttonbar from "../Buttonbar";
import UndoRedo from "../UndoRedo";
import withEventTracking from "../../hoc/withEventTracking";
import yaml from "js-yaml";
import FileSaver from "file-saver";
import { BiRedo, BiUndo } from "react-icons/bi";

const Topbar = () => {
  const [projectName, setProjectName] = useState(""); // State for project name
  const [selectedRunner, setSelectedRunner] = useState(""); // State for runner selection
  const [canvasItems, setCanvasItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [propertiesData, setPropertiesData] = useState({});

  const [history, setHistory] = useState({
    past: [],
    present: [],
    future: [],
  });

  const updateHistory = (newPresent) => {
    setHistory((prevHistory) => ({
      past: prevHistory.present
        ? [...prevHistory.past, prevHistory.present]
        : prevHistory.past,
      present: newPresent,
      future: [],
    }));
    setCanvasItems(newPresent);
  };

  const handleSaveAsYaml = () => {
    const yamlContent = yaml.dump({
      projectName: projectName,
      runnerName: selectedRunner,
      items: canvasItems,
    });
    const blob = new Blob([yamlContent], { type: "text/yaml" });
    FileSaver.saveAs(blob, "canvas.yaml");
  };

  const handleNewCanvas = () => {
    if (canvasItems.length > 0) {
      const confirmSave = window.confirm(
        "You have unsaved changes. Do you want to save your current work before creating a new canvas?"
      );
      if (confirmSave) {
        const itemsData = canvasItems.map((item) => {
          const storedData = sessionStorage.getItem(
            `propertiesData-${item.id}`
          );
          const parsedData = storedData ? JSON.parse(storedData) : {};
          return { ...item, properties: parsedData };
        });
        const yamlContent = yaml.dump({ items: itemsData });
        const blob = new Blob([yamlContent], { type: "text/yaml" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "canvas.yaml";
        a.click();
        URL.revokeObjectURL(url);
      }
    }
    updateHistory([]);
    localStorage.removeItem("canvasState");
  };

  const handleFileOpen = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/yaml") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const text = e.target.result;
          const parsedData = yaml.load(text);
          const newCanvasItems = parsedData.items.map((item) => ({
            ...item,
            uniqueId: `${item.path}-${+new Date()}`,
          }));
          updateHistory(newCanvasItems);
          setProjectName(parsedData.projectName || "");
          setSelectedRunner(parsedData.runnerName || "");
        } catch (error) {
          console.error("Error parsing YAML file:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleDeleteItem = (id) => {
    const newCanvasItems = canvasItems.filter((item) => item.uniqueId !== id);
    updateHistory(newCanvasItems);
    setSelectedItem(null);
    setPropertiesData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });

    localStorage.setItem("canvasState", JSON.stringify(newCanvasItems));
  };

  const undo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.past.length === 0) return prevHistory;

      const previous = prevHistory.past[prevHistory.past.length - 1];
      const newPast = prevHistory.past.slice(0, prevHistory.past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [prevHistory.present, ...prevHistory.future],
      };
    });
  };

  const redo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.future.length === 0) return prevHistory;

      const next = prevHistory.future[0];
      const newFuture = prevHistory.future.slice(1);

      return {
        past: [...prevHistory.past, prevHistory.present],
        present: next,
        future: newFuture,
      };
    });
  };

  return (
    <div className="flex md:justify-between lg:justify-evenly place-items-center w-full">
      <div className="flex w-1/2">
        {/* <ProjectNameInput value={projectName} onChange={setProjectName} />
        <RunnerInput value={selectedRunner} onChange={setSelectedRunner} /> */}
        {/* <YamlGenerator canvasItems={canvasItems} projectName={projectName} runnerName={selectedRunner} /> */}

        {/* <UndoRedo /> */}
      </div>
      <div className="flex justify-between place-items-center w-1/2">
        <Buttonbar
          onSave={handleSaveAsYaml}
          onNewCanvas={handleNewCanvas}
          onOpen={handleFileOpen}
          onDelete={handleDeleteItem}
        />
        <div className="w-1/2 flex justify-center place-items-center">
          <button className=" rounded-full px-2" onClick={undo}>
            <BiUndo className="navicons" />
          </button>
          <button className=" rounded-full  px-2" onClick={redo}>
            <BiRedo className="navicons" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withEventTracking(Topbar);
