import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login_credentials: {
    email: "",
    password: "",
  },
  git_credentials: {
    user_name: "Lokesh1379",
    repo_name: "etl-testing",
    token: "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",
  },
  isSavedInGit: false,
  userWantTo: "login",
  selectedBranch: "master",
  userGitBranches: [],
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setLoginCredentials: (state, { payload }) => {
      state.login_credentials = payload;
    },
    setGitCredentials: (state, { payload }) => {
      state.git_credentials = payload;
    },
    setIsSavedInGit: (state, { payload }) => {
      state.isSavedInGit = payload;
    },
    setUserWantTo: (state, { payload }) => {
      state.userWantTo = payload;
    },
    setSelectedBarnch: (state, { payload }) => {
      state.selectedBranch = payload;
    },
    setUserGitBranches: (state, { payload }) => {
      state.userGitBranches = payload;
    },
  },
});

export const {
  setGitCredentials,
  setLoginCredentials,
  setIsSavedInGit,
  setUserWantTo,
  setSelectedBarnch,
  setUserGitBranches,
} = userDataSlice.actions;
export default userDataSlice.reducer;
