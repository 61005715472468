import React, { useState, useEffect } from "react";
import withEventTracking from "../../hoc/withEventTracking";
import { fetchYaml } from "../../utils/fetchYaml";
import {
  IoMdArrowDropright,
  IoIosArrowUp,
  IoMdArrowDropdown,
  IoIosArrowDown,
} from "react-icons/io";

import { RiDraggable } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RiMenuUnfold2Line } from "react-icons/ri";
import { getSidebarOpen } from "../../features/commonProperties";
import Connections from "./components/Connections";
import ConnectionPopup from "./components/ConnectionPopup";

const Sidebar = ({ onDragStart }) => {
  const [svgIcons, setSvgIcons] = useState([]);
  const [items, setItems] = useState([]);
  const [openItems, setOpenItems] = useState({});
  const dispatch = useDispatch();
  const { sidebarOpen, showPopup } = useSelector((state) => state.commonProps);
  const { connectionPopup } = useSelector((state) => state.connections);
  useEffect(() => {
    const loadIcons = async () => {
      const data = await fetchYaml("/path/iconYaml/sidebarIcons.yaml");
      if (data?.icons) {
        setSvgIcons(data.icons);
      }
    };

    const loadItems = async () => {
      const data = await fetchYaml("/path/iconYaml/sideBarItems.yaml");
      if (data?.items) {
        setItems(data.items);
      }
    };

    loadIcons();
    loadItems();
  }, []);

  const toggleItem = (id) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <nav className="overflow-y-scroll con_sidebar ">
      {items.map((item) => (
        <div key={item.id} className="">
          {/* Main Item */}
          <div
            className="flex justify-between place-items-center activity_names cursor-pointer text-gray-700 p-2 m-1  "
            onClick={() =>
              (item.subcomponents || item.subitems) && toggleItem(item.id)
            }
          >
            <div className="flex justify-between place-items-center ">
              <img
                src={item?.imgpath}
                alt=""
                className="w-5 h-5 mx-1 con_images "
              />
              <span className="font-semibold hover:scale-105">{item.name}</span>
            </div>
            {(item.subcomponents || item.subitems) && (
              <span>
                {openItems[item.id] ? (
                  <IoIosArrowUp className="react_icons_colors" />
                ) : (
                  <IoIosArrowDown className="react_icons_colors" />
                )}
              </span>
            )}
          </div>

          {/* Transformations and Remaining Subcomponents */}
          {item.subcomponents && (
            <div
              className={`w-11/12 transition-all duration-300 ease-in-out overflow-y-scroll hide_scrollbar ${
                openItems[item.id] ? "block" : "hidden"
              }`}
            >
              {item.subcomponents.map((sub) => (
                <div
                  key={sub.id}
                  className="transition-all duration-300  text-sm px-4 py-2 border  cursor-move flex justify-between items-center mx-4 my-2 movables hover:text-white "
                  draggable
                  onDragStart={(e) => onDragStart(e, sub)}
                >
                  <div className="sub_items flex items-center">
                    <img
                      src={sub?.imgpath}
                      alt=""
                      className="w-5 h-5 mx-1 con_images  "
                    />
                    <span className="hover:scale-105">{sub.name}</span>
                  </div>
                  <RiDraggable className="hover:movable_icons" />
                </div>
              ))}
            </div>
          )}

          {/* Subitems */}
          {item.subitems && (
            <div
              className={`transition-all duration-300 ease-in-out  overflow-y-scroll  hide_scrollbar ${
                openItems[item.id] ? "block" : "hidden"
              }`}
            >
              {item.subitems.map((subitem) => (
                <div key={subitem.id}>
                  <div
                    className="sub_items py-2 cursor-pointer flex justify-start pl-5 items-center my-1 "
                    onClick={() => toggleItem(subitem.id)}
                  >
                    {subitem.subcomponents && (
                      <span>
                        {openItems[subitem.id] ? (
                          <IoMdArrowDropdown className="react_icons_colors" />
                        ) : (
                          <IoMdArrowDropright className="react_icons_colors" />
                        )}
                      </span>
                    )}
                    <div className="flex items-center">
                      <img
                        src={subitem?.imgpath}
                        alt=""
                        className="w-5 h-5 mx-1 con_images"
                      />
                      {subitem.name}
                    </div>
                  </div>
                  {/* connections Sub Items */}
                  {subitem.subcomponents && (
                    <div
                      className={`w-11/12 transition-all duration-300 ease-in-out overflow-y-scroll  hide_scrollbar ${
                        openItems[subitem.id] ? "block" : "hidden"
                      }`}
                    >
                      {subitem.subcomponents.map((sub) => (
                        <div
                          key={sub.id}
                          className="sub_items m-2 p-2  hover:text-white movables  transition-all duration-300 cursor-move flex justify-between items-center border "
                          draggable
                          onDragStart={(e) => onDragStart(e, sub)}
                        >
                          <div className="flex items-center">
                            <img
                              src={sub?.imgpath}
                              alt=""
                              className="w-5 h-5 mx-1 con_images "
                            />
                            <span className="font-medium hover:scale-105">
                              {sub.name}
                            </span>
                          </div>
                          <RiDraggable className=" hover:movable_icons" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div>
        <Connections />
        {connectionPopup.show && <ConnectionPopup />}
      </div>
    </nav>
  );
};

export default withEventTracking(Sidebar);
