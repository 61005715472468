import React from "react";

const UndoRedo = ({ onUndo, onRedo }) => {
  const handleUndo = () => {
    onUndo();
  };

  const handleRedo = () => {
    onRedo();
  };

  return (
    <div className="4">
      <span>
        <button
          className="bg-gray-300 mr-2 rounded-full py-1 px-2"
          onClick={handleUndo}
        >
          Undo
        </button>
        <button
          className="bg-gray-300 rounded-full py-1 px-2"
          onClick={handleRedo}
        >
          Redo
        </button>
      </span>
    </div>
  );
};

export default UndoRedo;
