import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
const initialState = {
  ddlData: [],
  currentDataset: {},
  currentSQLquerry: {},
  flattenedData: [],
};

// Helper function to add items recursively
const addItemRecursively = (tree, parentId, newItem) => {
  if (!parentId) {
    return [...tree, newItem];
  }

  return tree.map((node) => {
    if (
      node.id === parentId &&
      (node.type === "folder" || node.type === "dataset")
    ) {
      return { ...node, children: [...node.children, newItem] };
    }
    if (node.children) {
      return {
        ...node,
        children: addItemRecursively(node.children, parentId, newItem),
      };
    }
    return node;
  });
};

// Helper function to rename an item
const renameItemRecursively = (tree, itemId, newName) => {
  return tree.map((node) => {
    if (node.id === itemId) {
      return { ...node, displayName: newName };
    }
    if (node.children) {
      return {
        ...node,
        children: renameItemRecursively(node.children, itemId, newName),
      };
    }
    return node;
  });
};

// Helper function to toggle folder open/close
const toggleFolderRecursively = (tree, folderId) => {
  return tree.map((node) => {
    if (node.id === folderId) {
      return { ...node, open: !node.open };
    }
    if (node.children) {
      return {
        ...node,
        children: toggleFolderRecursively(node.children, folderId),
      };
    }
    return node;
  });
};

// Helper function to update DDL inside the correct file node
const updateDDLRecursively = (tree, fileId, query) => {
  return tree.map((node) => {
    if (node.id === fileId && node.type === "file") {
      return {
        ...node,
        DDL: [query], // Store query as a single string
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateDDLRecursively(node.children, fileId, query),
      };
    }
    return node;
  });
};

// Helper function to update a dataset inside ddlData
const updateDatasetRecursively = (tree, datasetId, updatedDataset) => {
  return tree.map((node) => {
    if (node.id === datasetId) {
      return { ...node, ...updatedDataset }; // Update dataset in ddlData
    }
    if (node.children) {
      return {
        ...node,
        children: updateDatasetRecursively(
          node.children,
          datasetId,
          updatedDataset
        ),
      };
    }
    return node;
  });
};
const extractDatasetById = (ddlData, datasetId) => {
  const findDataset = (tree, datasetId) => {
    for (const node of tree) {
      if (node.type === "dataset" && node.id === datasetId) {
        return node;
      }
      if (node.children) {
        const found = findDataset(node.children, datasetId);
        if (found) return found;
      }
    }
    return null;
  };
  for (const folder of ddlData) {
    const dataset = findDataset(folder.children || [], datasetId);
    if (dataset) {
      return {
        name: dataset.displayName,
        datasets: [
          {
            dataset_name: dataset.datasetName,
            DDLs: dataset.children
              ?.filter((file) => file.type === "file")
              .flatMap((file) => file.DDL || []),
            connections: dataset.connections || [],
          },
        ],
      };
    }
  }
  for (const dataset of ddlData) {
    // const dataset = findDataset(folder.children || [], datasetId);
    if (dataset) {
      return {
        name: dataset.displayName,
        datasets: [
          {
            dataset_name: dataset.datasetName,
            DDLs: dataset.children
              ?.filter((file) => file.type === "file")
              .flatMap((file) => file.DDL || []),
            connections: dataset.connections || [],
          },
        ],
      };
    }
  }

  return null; // If dataset ID is not found
};
const ddlDataSlice = createSlice({
  name: "ddlData",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { parentId, type } = action.payload;
      const newItem = {
        id: uuid(),
        type,
        displayName:
          type === "dataset"
            ? "New Dataset"
            : type === "folder"
            ? "New Folder"
            : "new_file.sql", // Assign .sql extension for DDL files
        open: false,
        ...(type === "file"
          ? { DDL: [] } // Create DDL array for files
          : { children: [] }), // Create children array for folders/datasets
        ...(type === "dataset" && {
          connections: [],
          datasetName: "",
        }), // Add connections for dataset
      };

      state.ddlData = addItemRecursively(state.ddlData, parentId, newItem);
    },

    renameItem: (state, action) => {
      const { itemId, newName } = action.payload;
      state.ddlData = renameItemRecursively(state.ddlData, itemId, newName);
    },

    toggleFolder: (state, action) => {
      const { folderId } = action.payload;
      state.ddlData = toggleFolderRecursively(state.ddlData, folderId);
    },
    setddlData: (state, { payload }) => {
      state.ddlData = payload;
    },
    setCurrentDataSet: (state, { payload }) => {
      state.currentDataset = payload;
    },
    setCurrentSQLquerry: (state, { payload }) => {
      state.currentSQLquerry = payload;
    },
    updateDatasetInDDL: (state, action) => {
      const { datasetId, updatedDataset } = action.payload;
      state.ddlData = updateDatasetRecursively(
        state.ddlData,
        datasetId,
        updatedDataset
      );
    },

    updateSQLQuery: (state, action) => {
      const { fileId, query } = action.payload;
      state.ddlData = updateDDLRecursively(state.ddlData, fileId, query);
    },
    flattenDDLTree: (state, { payload }) => {
      const flattenedData = extractDatasetById(state.ddlData, payload.itemId);
      if (flattenedData) {
        state.flattenedData = flattenedData;
      }
    },
  },
});

export const {
  setddlData,
  addItem,
  renameItem,
  toggleFolder,
  setCurrentDataSet,
  updateDatasetInDDL,
  setCurrentSQLquerry,
  updateSQLQuery,
  flattenDDLTree,
} = ddlDataSlice.actions;
export default ddlDataSlice.reducer;
