import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Registration from "../Registration";
import LoginForm from "../Login";
import { useSelector } from "react-redux";

const Forms = () => {
  const { userWantTo } = useSelector((state) => state.userData);

  return (
    <>
      <div className="flex w-screen min-h-screen">
        <SwitchTransition>
          <CSSTransition
            key={userWantTo}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <div className="w-full">
              {userWantTo === "register" ? <Registration /> : <LoginForm />}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </>
  );
};

export default Forms;
