import React from "react";

const PiplineName = () => {
  return (
    <div className="flex flex-col items-center  text-white h-full">
      {"PipeLines".split("").map((letter, index) => (
        <span key={index} className="block font-bold">
          {letter}
        </span>
      ))}
    </div>
  );
};

export default PiplineName;
