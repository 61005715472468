import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDropdown, closeDropdown, setActiveMedallion } from "../../../features/medallionSlice";
import { CiMenuKebab } from "react-icons/ci";
import { FaMedal } from "react-icons/fa";
import { AiFillGold, AiOutlineClose } from "react-icons/ai";
import { HiCube } from "react-icons/hi";
import { RiDatabase2Fill, RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import { FaIdCardAlt } from "react-icons/fa";
import { GiTeePipe } from "react-icons/gi";

const Medallion = () => {
  const dispatch = useDispatch();
  const { isDropdownOpen, activeMedallion, medallions } = useSelector((state) => state.medallion);
  const [isMedallionVisible, setIsMedallionVisible] = useState(false); // Toggle for Medallion details

  const dropdownOptions = [
    { label: "Load-Bronze", icon: <RiDatabase2Fill className="mr-2 text-blue-500" /> },
    { label: "Bronze-Silver", icon: <HiCube className="mr-2 text-blue-500" /> },
    { label: "Silver-Gold", icon: <AiFillGold className="mr-2 text-blue-500" /> },
  ];

  const handleSelectMedallion = (label) => {
    dispatch(setActiveMedallion(label)); // Set selected medallion
    dispatch(closeDropdown()); // Close the dropdown after selection
    setIsMedallionVisible(true); // Show Medallion details
  };

  return (
    <div className="relative cursor-pointer z-40 flex flex-col hover:bg-gray-50 transition p-1 border-b hover:rounded">
      {/* Medallion Header */}
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center" onClick={() => setIsMedallionVisible(!isMedallionVisible)}>
          <span>{isMedallionVisible ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}</span>
          <FaMedal className="mx-1 text-[rgb(38,154,248)]" />
          <h2 className="text-md">Medallion</h2>
        </div>

        {/* Kebab Menu Icon */}
        <div className="p-1 hover:bg-white relative" onClick={() => dispatch(toggleDropdown())}>
          <CiMenuKebab />
        </div>
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
          {/* Close Button */}
          <div className="flex justify-between items-center p-2 border-b">
            <span className="text-sm font-semibold">Select Medallion</span>
            <AiOutlineClose className="cursor-pointer text-gray-500 hover:text-gray-700" onClick={() => dispatch(closeDropdown())} />
          </div>
          <ul>
            {dropdownOptions.map((option, index) => (
              <li
                key={index}
                className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelectMedallion(option.label)}
              >
                {option.icon}
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Medallion Details - Show only when a Medallion is selected */}
      {isMedallionVisible && (
        <div className="mt-2 p-3 border rounded-md bg-gray-100 relative">
          {/* Close Button */}
          <AiOutlineClose
            className="absolute top-2 right-2 cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={() => setIsMedallionVisible(false)}
          />
          {activeMedallion ? (
            <>
              <h3 className="text-md font-semibold">{activeMedallion}</h3>
              {medallions[activeMedallion] && medallions[activeMedallion].length > 0 ? (
                <ul className="mt-2">
                {medallions[activeMedallion].map((medallion) => (
                  <li key={medallion.id} className="p-2 bg-white shadow rounded">
                    <p className="text-xs flex items-center">
                      <FaIdCardAlt className="text-[10px] text-blue-500 mr-1" />  
                      <strong>ID:</strong>  
                      <span className="break-all ml-1">{medallion.id}</span>
                    </p>
                    <p className="text-xs flex items-center">
                      <GiTeePipe className="text-[10px] text-blue-500 mr-1" />
                      <strong>Pipeline Name:</strong>  
                      <span className="break-all ml-1">{medallion.pipeline_name || "N/A"}</span>
                    </p>
                  </li>
                ))}
              </ul>
              
              ) : (
                <p className="mt-2 text-gray-500">No data available</p>
              )}
            </>
          ) : (
            <div className="flex items-center p-1">
              <img src="/images/sidebar/box.png" className="w-5 h-5" alt="" />
              <span>No Medallion Found</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Medallion;
