import React, { useState, useEffect } from "react";
import withEventTracking from "../../hoc/withEventTracking";
import { fetchYaml } from "../../utils/fetchYaml";
import axios from "axios";
import { getConnectionSidebar } from "../../features/commonProperties";
import { useDispatch, useSelector } from "react-redux";

const TreeNode = ({
  node,
  expanded,
  toggleExpand,
  connectionDetails,
  setNewConnectionName,
  onEdit,
}) => {
  const hasChildren = node.children && node.children.length > 0;
  const dispatch = useDispatch();
  const { connectionSidebar } = useSelector((state) => state?.commonProps);
  const [detailExpanded, setDetailExpanded] = useState({});

  const handleNewConnection = (type) => {
    setNewConnectionName(type);
    sessionStorage.setItem("newConnectionName", type);
    dispatch(getConnectionSidebar(!connectionSidebar));
  };

  const toggleDetailExpand = (index) => {
    setDetailExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const updateYamlOnEdit = async (data) => {
    try {
      const yamlData = await fetchYaml("/path/to/updated/yaml/file.yaml");
    } catch (error) {
      console.error("Error updating YAML data:", error);
    }
  };

  return (
    <li className="border p-2   bg-white mb-2">
      <div className="flex justify-between  items-center">
        <div className="flex items-center">
          <img src={node.imgpath} className="w-4 h-4 mx-2" alt="" />
          <span className="font-medium">{node.name}</span>
        </div>
        <button
          className="text-xl font-bold cursor-pointer"
          onClick={() => toggleExpand(node)}
        >
          {expanded[node.id] ? "-" : "+"}
        </button>
      </div>

      {expanded[node.id] && (
        <div className="mt-2 pl-4">
          <p className="text-sm text-gray-600">ID: {node.id}</p>
          <p className="text-sm text-gray-600">Type: {node.type}</p>

          {/* New Connection Button */}
          <div className="mt-2">
            {node.type === "s3" && (
              <button
                className=" bg-blue-500 text-white px-2 py-1 "
                onClick={() => handleNewConnection("S3")}
              >
                New S3 Connection
              </button>
            )}
            {node.type === "adls" && (
              <button
                className="mr-2 bg-green-500 text-white px-2 py-1 "
                onClick={() => handleNewConnection("ADLS")}
              >
                New ADLS Connection
              </button>
            )}
            {node.type === "jdbc" && (
              <button
                className="bg-yellow-500 text-white px-2 py-1 "
                onClick={() => handleNewConnection("JDBC")}
              >
                New JDBC Connection
              </button>
            )}
          </div>

          <ul className="mt-2">
            {connectionDetails[node.type] &&
              connectionDetails[node.type].map((conn, index) => (
                <li key={index} className="bg-gray-100 p-2  mt-1">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold">
                      Connection Name: {conn.conn_data.connection_name}
                    </p>
                    <div className="flex space-x-2">
                      <button
                        className="text-blue-500 font-bold cursor-pointer"
                        onClick={() => toggleDetailExpand(index)}
                      >
                        {detailExpanded[index] ? "-" : "+"}
                      </button>
                      <button
                        className="text-gray-500 hover:text-gray-800 cursor-pointer"
                        onClick={() => {
                          sessionStorage.setItem(
                            "s3ConnectionData",
                            JSON.stringify(conn.conn_data)
                          );
                          setNewConnectionName(conn.conn_type);
                          sessionStorage.setItem(
                            "newConnectionName",
                            conn.conn_type
                          );
                          const dataToEdit = {
                            ...conn.conn_data,
                            type: conn.conn_type,
                            id: conn.id,
                          };
                          window.alert("Data ready for editing.");
                          onEdit(dataToEdit);
                          updateYamlOnEdit(dataToEdit);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-4 h-4 fill-current"
                        >
                          <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {detailExpanded[index] && (
                    <div className="ml-4 mt-1">
                      {Object.entries(conn.conn_data).map(
                        ([key, value]) =>
                          key !== "connection_name" && (
                            <p key={key} className="text-sm">
                              {key.replace(/_/g, " ")}: {value}
                            </p>
                          )
                      )}
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const ConnectionDetails = ({ setNewConnectionName, onEdit }) => {
  const [connections, setConnections] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [connectionDetails, setConnectionDetails] = useState({});
  const { currentUrl } = useSelector((state) => state.commonProps);
  // Function to clean API response
  const cleanApiResponse = (rawResponse) => {
    try {
      const startIndex = rawResponse.indexOf("{");
      const jsonResponse = JSON.parse(rawResponse.slice(startIndex));

      if (jsonResponse.data) {
        jsonResponse.data = jsonResponse.data.map((item) => ({
          ...item,
          conn_data: item.conn_data ? JSON.parse(item.conn_data) : null,
        }));
      }

      return jsonResponse;
    } catch (error) {
      console.error("Error cleaning API response:", error);
      return null;
    }
  };

  const fetchConnectionDetails = async (connType) => {
    try {
      const response = await axios.get(
        `${currentUrl?.url}connections_readByConnType.php?conn_type=${connType}`
      );
      const cleanedData = cleanApiResponse(response.data);
      setConnectionDetails((prevDetails) => ({
        ...prevDetails,
        [connType]: cleanedData?.data || [],
      }));
    } catch (error) {
      console.error(`Failed to fetch ${connType} connection details:`, error);
    }
  };

  const toggleExpand = (node) => {
    if (!expanded[node.id] && !connectionDetails[node.type]) {
      fetchConnectionDetails(node.type);
    }
    setExpanded((prev) => ({
      ...prev,
      [node.id]: !prev[node.id],
    }));
  };

  useEffect(() => {
    const loadConnections = async () => {
      try {
        const data = await fetchYaml("/path/connections/connectionsData.yaml");
        if (data && data.connections) {
          const connectionArray = Object.values(data.connections);
          setConnections(connectionArray);
        }
      } catch (error) {
        console.error("Failed to load connection data:", error);
      }
    };

    loadConnections();
  }, []);

  return (
    <div className="w-full px-2 ">
      <h4 className="text-lg font-semibold mb-4">Connection Details</h4>
      <ul className="space-y-2">
        {connections.map((connection) => (
          <TreeNode
            key={connection.id}
            node={connection}
            expanded={expanded}
            toggleExpand={toggleExpand}
            connectionDetails={connectionDetails}
            setNewConnectionName={setNewConnectionName}
            onEdit={onEdit}
          />
        ))}
      </ul>
    </div>
  );
};

export default withEventTracking(ConnectionDetails);
