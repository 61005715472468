import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
const initialState = {
  connections: [
    {
      id: uuid(),
      connection_name: "S3 Connection",
      type: "s3",
      imgpath: "/images/sidebar/s32.png",
      status: "active",
      input_items: [
        {
          label: "Connection Name",
          name: "connection_name",
        },
        {
          label: "Access Name",
          name: "accessname",
        },
        {
          label: "Secret Name",
          name: "secretname",
        },
        {
          label: "Bucket Name",
          name: "bucketname",
        },
      ],
      children: [],
    },
    {
      id: uuid(),
      connection_name: "ADLS Connection",
      type: "adls",
      imgpath: "/images/sidebar/azure.png",
      status: "inactive",
      input_items: [
        {
          label: "Connection Name",
          name: "connection_name",
        },
        {
          label: "Tenant ID",
          name: "tenantid",
        },
        {
          label: "Client ID",
          name: "clientid",
        },
        {
          label: "Client secret",
          name: "clientsecret",
        },
      ],
      children: [],
    },
    {
      id: uuid(),
      connection_name: "JDBC Connection",
      type: "jdbc",
      imgpath: "/images/sidebar/readjdbc1.png",
      status: "active",
      input_items: [
        {
          label: "Connection Name",
          name: "connection_name",
        },
        {
          label: "JDBC URL",
          name: "url",
        },
        {
          label: "User Name",
          name: "username",
        },
        {
          label: "Password",
          name: "password",
        },
        {
          label: "Database",
          name: "database",
        },
        {
          label: "Provide",
          name: "provider",
        },
        {
          label: "Driver Path",
          name: "driver_path",
        },
      ],
      children: [],
    },
  ],

  selectedConnection: {},
  connectionPopup: { show: false, message: "" },
};

const connectionSLice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setNewConnections: (state, { payload }) => {
      state.connections = state.connections.map((connection) => {
        if (connection?.id === payload.parent_id) {
          return {
            ...connection,
            children: [...connection.children, payload?.newConnection], // Append properly
          };
        } else {
          return connection;
        }
      });
    },
    setSelectedConnection: (state, { payload }) => {
      state.selectedConnection = payload;
    },
    setConnectionPopup: (state, { payload }) => {
      state.connectionPopup = payload;
    },
  },
});
export const { setNewConnections, setConnectionPopup, setSelectedConnection } =
  connectionSLice.actions;
export default connectionSLice.reducer;
