import {safeStringify} from './jsonUtils';

class EventLogger {
    constructor() {
      this.events = JSON.parse(sessionStorage.getItem('userEvents')) || [];
    }
  
    logEvent(event) {
      this.events.push(event);
      sessionStorage.setItem('userEvents', safeStringify(this.events));
    }
  
    clearEvents() {
      this.events = [];
      sessionStorage.setItem('userEvents', safeStringify(this.events));
    }
  
    getEvents() {
      return this.events;
    }
  }
  
  export const eventLogger = new EventLogger();
  