import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserWantTo } from "../../../features/userData";
import axios from "axios";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email_id: "",
    password: "",
  });
  const { currentUrl } = useSelector((state) => state.commonProps);
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error on input
  };

  // Validate form before submission
  const validateForm = () => {
    let newErrors = {};

    if (!formData.email_id.trim()) {
      newErrors.email_id = "email_id is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email_id)) {
      newErrors.email_id = "Enter a valid email_id address";
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 3) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${currentUrl?.url}etl_login.php`,
          formData,
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data.status_code === "2000") {
        }
      } catch (error) {
        console.lo(error);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/3 bg-gradient-to-b from-blue-600 to-blue-400 text-white flex flex-col justify-center items-center p-8">
        <h2 className="text-2xl font-bold text-center">Welcome Back!</h2>
        <p className="mt-4 text-lg text-center">
          Login to Haarg ETL account to access
        </p>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-2/3 flex flex-col justify-center items-center px-6 py-10">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <p className="text-gray-600 mb-6 text-center">
          Manage all your Account efficiently
        </p>

        <form className="w-full max-w-md" onSubmit={handleSubmit}>
          {/* email_id Field */}
          <div className="mb-4">
            <label className="block font-medium text-start property_labels">
              email_id
            </label>
            <input
              type="text"
              name="email_id"
              value={formData.email_id}
              onChange={handleChange}
              className="border  focus:ring-2 focus:ring-blue-500 w-full px-2 py-1 outline-none"
            />
            {errors.email_id && (
              <p className="text-red-500 text-sm mt-1">{errors.email_id}</p>
            )}
          </div>

          {/* Password Field */}
          <div className="mb-4">
            <label className="block font-medium text-start property_labels">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="border  focus:ring-2 focus:ring-blue-500 w-full px-2 py-1 outline-none"
            />
            {errors.password && (
              <p className="text-red-500 text-[13px] mt-1">{errors.password}</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-blue-600 text-white w-full py-2  hover:bg-blue-700 transition duration-300"
          >
            Login
          </button>
        </form>

        {/* Register Link */}
        <p className="mt-4 text-gray-600 text-[13px] text-center">
          Want to create a new account?{" "}
          <span
            onClick={() => dispatch(setUserWantTo("register"))}
            className="text-blue-600 cursor-pointer font-semibold"
          >
            Register
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
