import React, { useState, useEffect } from "react";
import cronstrue from "cronstrue";
import "cronstrue/locales/en";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import axios from "axios";
import { getShowPopup } from "../../../features/commonProperties";
import { setTriggers } from "../../../features/triggers";

const CronExpression = ({ onClose, trigger, isUpdateing, closeUpdateing }) => {
  const { currentPipeLineTasks, activePipeline } = useSelector(
    (state) => state.pipeLines
  );
  const { showPopup, currentUrl } = useSelector((state) => state.commonProps);
  const dispatch = useDispatch();
  const [minStartDate, setMinStartDate] = useState("");
  const { triggers } = useSelector((state) => state.triggers);
  useEffect(() => {
    // Get current date and time in the correct format for datetime-local input
    const now = new Date();
    const formattedNow = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);

    setMinStartDate(formattedNow);
  }, []);

  // Set initial state with existing trigger data if available
  const [formData, setFormData] = useState({
    cron_expression: "* * * * *",
    trigger_name: "",
    start_date: "",
    end_date: "2029-12-31T23:59",
  });

  useEffect(() => {
    if (trigger) {
      setFormData({
        cron_expression: trigger.cron_expression,
        trigger_name: trigger.trigger_name,
        start_date: trigger.start_date,
        end_date: trigger.end_date,
      });
    }
  }, [trigger]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(isUpdateing, trigger);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      pipeline_name: currentPipeLineTasks?.job_name,
      cron_expression: formData.cron_expression,
      trigger_name: formData.trigger_name,
      pipeline_params: currentPipeLineTasks?.Global_variables,
      start_date: formData.start_date,
      end_date: formData.end_date,
    };

    const newTrigger = {
      u_id: isUpdateing ? trigger?.u_id : uuid(),
      edit: true,
      ...data,
    };

    const updatedTriggers = triggers?.map((triggerGroup) => {
      if (triggerGroup.pipeline_id === activePipeline) {
        // Check if the trigger already exists
        const triggerExists = triggerGroup.triggers.some(
          (t) => t.u_id === newTrigger.u_id
        );

        return {
          ...triggerGroup,
          triggers: triggerExists
            ? triggerGroup.triggers.map(
                (t) => (t.u_id === newTrigger.u_id ? newTrigger : t) // Update trigger
              )
            : [...triggerGroup.triggers, newTrigger], // Add new trigger
        };
      }
      return triggerGroup;
    });

    // If pipeline doesn't exist, add a new one
    const pipelineExists = triggers?.some(
      (trigger) => trigger.pipeline_id === activePipeline
    );

    const finalTriggers = pipelineExists
      ? updatedTriggers
      : [...triggers, { pipeline_id: activePipeline, triggers: [newTrigger] }];

    console.log(finalTriggers);
    dispatch(setTriggers(finalTriggers));
    dispatch(getShowPopup(true));
    onClose();
    setTimeout(() => {
      dispatch(getShowPopup(false));
    }, 2000);
    closeUpdateing();

    // const updatedTriggers = triggers?.some(trigger => trigger.pipeline_id === activePipeline)
    // ? triggers.map(trigger =>
    //     trigger.pipeline_id === activePipeline
    //       ? { ...trigger, triggers: [...trigger.triggers, newTrigger] }  // Add new trigger
    //       : trigger
    //   )
    // : [...triggers, { pipeline_id: activePipeline, triggers: [newTrigger] }]; // Add new pipeline

    // if (isUpdateing) {
    //   try {
    //     const response = await axios.put(
    // `${currentUrl?.url}get_triggersByPipelineId.php`,
    //       updatedata,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     if (response.data?.status_code === 2000) {
    //       onClose();
    //       dispatch(getShowPopup(true));

    //       setTimeout(() => {
    //         dispatch(getShowPopup(false));
    //       }, 2000);
    //       closeUpdateing();
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }
    // } else {
    //   try {
    //     const response = await axios.post(
    //       `${currentUrl?.url}triggers_insert.php`,
    //       data,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );
    //     if (response.data?.status_code === 2000) {
    //       onClose();
    //       dispatch(getShowPopup(true));
    //       setTimeout(() => {
    //         dispatch(getShowPopup(false));
    //       }, 2000);
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }
    // }
  };

  let description;
  try {
    description = cronstrue.toString(formData.cron_expression, {
      locale: "en",
    });
  } catch (err) {
    description = "Invalid cron expression";
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-2 md:px-6">
      <h1 className="text-3xl font-extrabold text-gray-800">
        {trigger?.u_id ? "Edit JOB Trigger" : "Schedule JOB Trigger"}
      </h1>
      <form
        className="bg-white w-full max-w-xl text-center px-2 md:px-6 h-[80vh] overflow-y-scroll con_sidebar"
        onSubmit={handleSubmit}
      >
        <div className="mt-6 p-4">
          <p className="text-xl font-semibold text-[rgb(38,154,248)]">
            {description}
          </p>
          <p className="text-gray-600 mt-3 font-mono text-lg bg-gray-200 p-2 inline-block">
            {formData.cron_expression}
          </p>
        </div>

        {/* Cron Expression Input */}
        {/* Cron Expression Input */}
        <div className="flex flex-col items-center w-full">
          <label className="font-semibold text-gray-700 mb-2">
            Enter Schedule Expression to Trigger the JOB (Cron Format)
          </label>
          <input
            type="text"
            name="cron_expression"
            value={formData.cron_expression}
            onChange={handleChange}
            className="p-3 w-full border border-gray-400 outline-none text-center text-2xl tracking-widest"
            placeholder="*/5 * * * *"
          />

          {/* Helper Text for Cron Expression Format */}
          <div className="text-sm text-gray-600 mt-2">
            Format:
            <span className="font-mono block text-blue-600">
              * * * * * → (Minutes, Hours, Days, Months, Weekday)
            </span>
            Example:{" "}
            <span className="font-mono text-green-600">0 12 * * 1</span> (Runs
            every Monday at 12 PM)
          </div>

          {/* Cron Breakdown */}
          <div className="grid grid-cols-5 gap-2 mt-3 text-sm text-gray-700">
            <div className="text-center">
              <p className="font-bold">Min</p>
              <p className="bg-gray-200 px-2 py-1 rounded">
                {formData.cron_expression?.split(" ")[0] || "*"}
              </p>
            </div>
            <div className="text-center">
              <p className="font-bold">Hour</p>
              <p className="bg-gray-200 px-2 py-1 rounded">
                {formData.cron_expression?.split(" ")[1] || "*"}
              </p>
            </div>
            <div className="text-center">
              <p className="font-bold">Day</p>
              <p className="bg-gray-200 px-2 py-1 rounded">
                {formData.cron_expression?.split(" ")[2] || "*"}
              </p>
            </div>
            <div className="text-center">
              <p className="font-bold">Month</p>
              <p className="bg-gray-200 px-2 py-1 rounded">
                {formData.cron_expression?.split(" ")[3] || "*"}
              </p>
            </div>
            <div className="text-center">
              <p className="font-bold">Weekday</p>
              <p className="bg-gray-200 px-2 py-1 rounded">
                {formData.cron_expression?.split(" ")[4] || "*"}
              </p>
            </div>
          </div>
        </div>

        {/* Trigger Name Input */}
        <div className="flex flex-col items-start w-full">
          <label htmlFor="trigger_name" className="font-semibold text-gray-700">
            Trigger Name
          </label>
          <input
            type="text"
            name="trigger_name"
            id="trigger_name"
            value={formData.trigger_name}
            onChange={handleChange}
            className="border border-gray-400 w-full px-3 py-2"
          />
        </div>

        {/* Start Date Input */}
        <div className="flex flex-col items-start w-full mt-4">
          <label htmlFor="start_date" className="font-semibold text-gray-700">
            Start Date & Time
          </label>
          <input
            type="datetime-local"
            name="start_date"
            id="start_date"
            value={formData.start_date}
            onChange={handleChange}
            min={minStartDate} // Prevents selection of past dates
            className="border border-gray-400 w-full px-3 py-2"
          />
        </div>

        {/* End Date Input */}
        <div className="flex flex-col items-start w-full mt-4">
          <label htmlFor="end_date" className="font-semibold text-gray-700">
            End Date & Time
          </label>
          <input
            type="datetime-local"
            name="end_date"
            id="end_date"
            value={formData.end_date}
            onChange={handleChange}
            className="border border-gray-400 w-full px-3 py-2"
          />
        </div>

        {/* Submit Button */}
        <div className="w-full flex justify-between place-items-center p-4">
          <button
            type="button"
            onClick={onClose}
            className="bg-blue-300 text-white px-6 py-1 rounded-sm shadow-md hover:bg-blue-600 transition-all"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-1 rounded-sm shadow-md hover:bg-blue-600 transition-all"
          >
            {trigger?.u_id ? "Update" : "Save"}
          </button>
        </div>
      </form>

      {/* Popup Notification */}
      <div
        className={`fixed bottom-4 right-4 bg-green-600 z-50 text-white px-6 py-3 rounded-lg shadow-lg transition-transform duration-500 ease-in-out ${
          showPopup ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
        }`}
        style={{ visibility: showPopup ? "visible" : "hidden" }}
      >
        Trigger {trigger ? "Updated" : "Saved"}
      </div>
    </div>
  );
};

export default CronExpression;
