import { FaUserEdit } from "react-icons/fa";
import React from "react";
const UserProfile = () => {
  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-full md:w-64 bg-white p-5 shadow-md">
        <h2 className="text-xl font-bold text-blue-600">CRM</h2>
        <nav className="mt-5">
          <ul>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Dashboard
            </li>
            <li className="p-3 bg-blue-500 text-white rounded cursor-pointer">
              Accounts
            </li>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Contacts
            </li>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Leads
            </li>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Calendar
            </li>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Activities
            </li>
            <li className="p-3 hover:bg-gray-200 rounded cursor-pointer">
              Reports
            </li>
          </ul>
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-auto">
        {/* Top Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
          <input
            type="text"
            placeholder="Search Anything..."
            className="border p-2 rounded w-full md:w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
            + Add Users
          </button>
        </div>

        {/* User Profile */}
        <div className="bg-white p-6 rounded shadow-md w-full max-w-3xl mx-auto">
          <h3 className="text-lg font-semibold mb-4">My Profile</h3>
          <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6">
            <img
              src="https://via.placeholder.com/80"
              alt="Profile"
              className="w-20 h-20 rounded-full border-4 border-gray-300"
            />
            <div className="text-center md:text-left">
              <h4 className="text-xl font-semibold">Jack Adams</h4>
              <p className="text-gray-500">Product Designer</p>
              <button className="text-blue-500 flex items-center justify-center md:justify-start mt-2 hover:text-blue-700 transition">
                <FaUserEdit className="mr-2" /> Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
