import React, { useEffect, useState, useRef } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePipeline,
  getNodesData,
  setNodeEdges,
  setPipeLineTasks,
} from "../../../features/PipeLines";
import {
  getSelectedNodeId,
  setdeleteModalOpen,
} from "../../../features/nodesData";
import { getCurrentTab } from "../../../features/commonProperties";

const DeleteNodeModal = () => {
  const { deleteNodeData, isDeleteModalaOpen } = useSelector(
    (state) => state.totalNodes
  );
  const [inputValue, setInputValue] = useState("");
  const {
    pipeLines,
    currentPiplineData,
    currentPipeLineTasks,
    nodesData,
    nodeEdges,
  } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteModalaOpen && deleteNodeData) {
      setInputValue(deleteNodeData.label || "");
    }
  }, [isDeleteModalaOpen, deleteNodeData]);

  if (!isDeleteModalaOpen) return null;

  const onConfirmDelete = () => {
    const afterNodeDelete = currentPiplineData?.job_nodes?.filter(
      (node) => node?.uniqueId !== deleteNodeData?.id
    );

    const afterTaskDelete = currentPipeLineTasks?.tasks?.filter(
      (task) => task?.u_id !== deleteNodeData?.id
    );
    const deletedNodes = nodesData?.map((node) => {
      if (node?.id === currentPiplineData?.id) {
        return { ...node, job_nodes: afterNodeDelete };
      } else {
        return node;
      }
    });
    const deletedTasks = pipeLines?.map((task) => {
      if (task?.id === currentPipeLineTasks?.id) {
        return { ...task, tasks: afterTaskDelete };
      } else {
        return task;
      }
    });
    console.log(nodeEdges);
    const updatedEdges = nodeEdges.filter(
      (edge) => !edge?.id.includes(deleteNodeData?.id)
    );
    console.log(updatedEdges);
    dispatch(setPipeLineTasks(deletedTasks));
    dispatch(getNodesData(deletedNodes));
    setTimeout(() => {
      dispatch(getSelectedNodeId("parent"));
      dispatch(getCurrentTab("parameters"));
    }, 100);
    dispatch(setdeleteModalOpen(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30"></div>
      <div className="relative bg-white p-6  shadow-lg max-w-md w-full">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={() => dispatch(setdeleteModalOpen(false))}
        >
          <ImCancelCircle />
        </button>
        <label htmlFor="">Are you sure want to Delete </label>
        <form className="mt-4 space-y-3">
          <input
            type="text"
            value={inputValue}
            disabled
            onChange={(e) => setInputValue(e.target.value)}
            className="mt-1 p-2 w-full border border-black focus:outline-none "
          />

          <div className="flex justify-end place-items-center mt-4 h-full gap-2">
            <button
              type="button"
              className="mx-2 px-4 border property_labels border-red-600 rounded-[3px] py-1 bg-red-600 text-white  "
              onClick={onConfirmDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="rounded-[2px] mx-2 px-4 border property_labels py-1 border-black hover:border-[rgb(38,154,248)] hover:text-white bg-white text-black hover:bg-[rgb(38,154,248)]"
              onClick={() => dispatch(setdeleteModalOpen(false))}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteNodeModal;
