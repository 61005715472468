import React, { useState, useEffect } from "react";
import { CheckCircle } from "lucide-react";

const SuccessAnimation = ({ onComplete }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      if (onComplete) onComplete();
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, [onComplete]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-30">
      <div
        className="bg-white shadow-xl rounded-sm p-6 flex flex-col items-center gap-3 
      animate-fade-in scale-90 "
      >
        <CheckCircle className="text-green-500 w-16 h-16 animate-scale-in" />
        <p className="text-lg font-semibold text-gray-700 animate-fade-in">
          Success! Data Saved to git
        </p>
      </div>
    </div>
  );
};

export default SuccessAnimation;
