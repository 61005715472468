import React, { useEffect, useState } from "react";
import { GrLinkNext } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedNodeId, getValidateJob } from "../../features/nodesData";
import activities from "../../data/sidebar";
import { getCurrentTab } from "../../features/commonProperties";
import { getFocusErrorInput } from "../../features/PipeLines";

const JobValidation = ({ refs }) => {
  const { validateJob } = useSelector((state) => state?.totalNodes);
  const [inputError, setInputErrors] = useState([]);
  const { validationErrors, activePipeline } = useSelector(
    (state) => state.pipeLines
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const errors = validationErrors?.find((err) => err?.id === activePipeline);
    setInputErrors(errors?.input_errors);
  }, [validationErrors, activePipeline]);

  const showErrorInput = (error) => {
    dispatch(getSelectedNodeId(error?.parent_id));
    dispatch(getFocusErrorInput(error?.field));
    if (error.error_in === "settings") {
      dispatch(getCurrentTab("settings"));
    } else {
      dispatch(getCurrentTab("general"));
    }
  };
  const getImagePath = (errorId) => {
    for (let activity of activities) {
      const subcomponent = activity?.subcomponents?.find((sub) => {
        if (sub?.subcomponents) {
          const innerImage = sub?.subcomponents.find((image) => {
            return image?.id === errorId;
          });
          return innerImage;
        } else {
          return sub.id === errorId;
        }
      });
      if (subcomponent) {
        if (subcomponent?.subcomponents) {
          return (
            subcomponent?.subcomponents?.find((image) => image?.id === errorId)
              ?.imgpath || "/images/sidebar/box.png"
          );
        } else {
          return subcomponent.imgpath || "/images/sidebar/box.png";
        }
      }
    }

    return "/images/sidebar/box.png";
  };

  return (
    <div
      className={`fixed top-0 right-0 h-screen w-11/12 md:w-1/2 lg:w-2/5 pt-10 z-40 bg-white border-l-2 text-black shadow-lg transform transition-transform duration-400 ease-in-out ${
        validateJob ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {inputError?.length > 0 ? (
        <div className="w-full ">
          <h3 className="w-full border-b text-start p-1 mt-2 pl-4 text-black">
            Job Validation Output
          </h3>
          <div className="w-full overflow-y-scroll h-[85vh] con_sidebar pb-10">
            <ul>
              {inputError?.map((error, index) => (
                <li
                  key={index}
                  className="flex justify-start place-items-center border-b hover:bg-gray-100 w-full px-5 py-3 h-full"
                  onClick={() => showErrorInput(error)}
                >
                  <div className="p-1 m-1">
                    <img
                      src={getImagePath(error.parent_id)}
                      className="w-7 h-7"
                      alt="Task Icon"
                    />
                  </div>
                  <div className="pl-3">
                    <h2 className="text-sm">{error.task_name}</h2>
                    <p className="text-sm text-[rgb(38,154,248)]">
                      {error.field}: {error.error}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div className="w-full text-center mt-10">
          <p style={{ color: "green" }}>No validation errors! 🎉</p>
        </div>
      )}
      <div className="absolute bottom-0 w-full bg-white p-1 px-3">
        <button
          onClick={() => dispatch(getValidateJob(false))}
          className="close_button hover:scale-105 text-white px-5 py-1"
        >
          <GrLinkNext />
        </button>
      </div>
    </div>
  );
};

export default JobValidation;
