import React from 'react';
import ConfigSidebar from '../ConfigSidebar';

const Configurations = () => {
  return (
    <div className="flex">
      <ConfigSidebar />
      <div className="flex-1 text-center p-7">
        {/* <h3 className="text-4xl font-bold mb-4 right-36 mt-8">Configurations</h3> */}
        {/* <p className="text-lg right-10">Here's how we can make configurations..!!!</p> */}
      </div>
    </div>
  );
};

export default Configurations;
