import React, { useEffect, useState } from "react";
import { getShowPopup } from "../../../features/commonProperties";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";

const TabSettings = () => {
  const [tabSettings, setTabSettings] = useState({});
  const { pipeLines, currentPipeLineTasks, activePipeline } = useSelector(
    (state) => state?.pipeLines
  );

  useEffect(() => {
    setTabSettings(currentPipeLineTasks?.settings);
  }, [currentPipeLineTasks]);
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTabSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setTabSettings((prevSettings) => ({
      ...prevSettings,
      isElapsedTimeMetricChecked: checked,
      elapsed_time_metric: "",
    }));
  };

  const validateForm = () => {
    const { concurrancy, elapsed_time_metric, isElapsedTimeMetricChecked } =
      tabSettings;
    if (!concurrancy) {
      alert("Concurrency field is required.");
      return false;
    }
    if (isElapsedTimeMetricChecked && !elapsed_time_metric) {
      alert("Elapsed time metric field is required.");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedSettings = { ...currentPipeLineTasks, settings: tabSettings };

    const updatedPipeline = pipeLines?.map((pipeline) => {
      if (pipeline?.id === activePipeline) {
        return updatedSettings;
      } else {
        return pipeline;
      }
    });

    if (validateForm()) {
      dispatch(getShowPopup(true));
      setTimeout(() => {
        dispatch(getShowPopup(false));
      }, 2000);
      dispatch(setPipeLineTasks(updatedPipeline));
      dispatch(setCurrentPipeLineTasks(updatedSettings));
    }
  };

  return (
    <div className="w-full md:w-1/2 p-2">
      <form onSubmit={handleSubmit} className="  bg-white space-y-3  ">
        <div className="flex justify-between place-items-center">
          <label
            htmlFor="concurrancy"
            className="text-sm font-medium text-gray-700 property_labels"
          >
            Concurrency
          </label>
          <input
            type="text"
            id="concurrancy"
            name="concurrancy"
            value={tabSettings.concurrancy}
            onChange={handleInputChange}
            className="w-1/2 p-1 border custom_input border-black focus:outline-none focus:ring-2 property_labels focus:ring-blue-400"
          />
        </div>

        {/* Elapsed Time Metric */}
        <div className="flex justify-between place-items-center">
          <label
            htmlFor="elapsed_time_metric"
            className="text-sm font-medium text-gray-700 property_labels"
          >
            Elapsed Time Metric
          </label>
          <div className="flex items-center space-x-4">
            <input
              type="checkbox"
              id="elapsed_time_metric_checkbox"
              checked={tabSettings.isElapsedTimeMetricChecked}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-500  border-black rounded focus:ring-blue-400 "
            />
            <input
              type="text"
              id="elapsed_time_metric"
              name="elapsed_time_metric"
              value={tabSettings.elapsed_time_metric}
              onChange={handleInputChange}
              disabled={!tabSettings.isElapsedTimeMetricChecked}
              className={`w-full p-1 border border-black property_labels custom_input ${
                tabSettings.isElapsedTimeMetricChecked
                  ? ""
                  : "border-gray-200 bg-gray-100"
              } `}
            />
          </div>
        </div>

        <div className="flex justify-end ">
          <button
            type="submit"
            className="relative text-blue-500 px-8 py-2 rounded-sm bg-white isolation-auto z-10 border-2 border-blue-500
          before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-right-full before:hover:right-0 before:rounded-lg hover:text-white before:bg-blue-500 before:-z-10  before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-500 property_labels"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TabSettings;
