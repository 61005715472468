import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnectionPopup } from "../../../features/connections";

const ConnectionPopup = () => {
  const { connectionPopup } = useSelector((state) => state.connections);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsVisible(true); // Start animation
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide after 2 seconds
      setTimeout(() => {
        dispatch(setConnectionPopup({ show: false, message: "" }));
      }, 500); // Delay removal after animation
    }, 2000);

    return () => clearTimeout(timer);
  }, [connectionPopup]);

  return (
    <div
      className={`fixed top-10 flex items-center w-screen justify-center z-50 transition-all duration-500 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
      }`}
    >
      <div className="bg-white py-1 activity_names border-l-8 border-blue-500 px-4 w-11/12 md:w-1/2 shadow-lg text-center text-gray-800 transition-all duration-500">
        {connectionPopup.message}
      </div>
    </div>
  );
};
export default ConnectionPopup;
