// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { YamlProvider } from './components/YamlProvider';
// import './index.css';
// import { ConnectionProvider } from './components/ConnectionContext';

// const rootElement = document.getElementById('root');
// const root = ReactDOM.createRoot(rootElement);

// root.render(
//   <ConnectionProvider>
//     <YamlProvider>
//       <App />
//     </YamlProvider>
//     <ConnectionProvider />,
//     document.getElementById('root')
//     );

// For build

// "start": "react-scripts start",
// "build": "set NODE_OPTIONS=--openssl-legacy-provider && react-scripts build",
// "test": "react-scripts test",
// "eject": "react-scripts eject"

import React from "react";
import ReactDOM from "react-dom/client"; // Ensure you're using 'react-dom/client' for React 18
import App from "./App";
import { YamlProvider } from "./components/YamlProvider";
import { ConnectionProvider } from "./components/ConnectionContext/index"; // Import ConnectionProvider
import { CanvasProvider } from "./components/CanvasProvider";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/Store";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <CanvasProvider>
      <ConnectionProvider>
        <YamlProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </YamlProvider>
      </ConnectionProvider>
    </CanvasProvider>
  </Provider>
);
