import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPanelElement,
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import {
  getCurrentTab,
  getRightSidebar,
  getSourceCodeOpen,
} from "../../features/commonProperties";
import { SelectedConnectionProvider } from "../../context/SelectedConnectionContext";
import YamlGenerator from "../YamlGenerator";
import { VscArrowLeft, VscArrowRight, VscDebugAltSmall } from "react-icons/vsc";
import { IoCheckmark } from "react-icons/io5";
import ActivityName from "../Sidebar/components/ActivityName";
import Sidebar from "../Sidebar";
import yaml from "js-yaml";
import {
  getAllTask,
  getRenderingYAML,
  getSelectedNodeId,
  getUniqid,
  getValidateJob,
  getYamlData,
} from "../../features/nodesData";
import Canvas from "../Canvas";
import PropTabNames from "../Home/components/PropTabNames";
import Properties from "../Properties";
import {
  getNodesData,
  setCurrentPipeLineData,
  setCurrentPipeLineEdges,
  setCurrentPipeLineTasks,
  setExpandActivity,
  setPipeLineTasks,
  validateData,
} from "../../features/PipeLines";
import uuid from "react-uuid";
import JobRun from "../JobRun/JobRun";
import Triggers from "./components/Triggers";
import RunnerInput from "../RunnerInput";
import { CiMenuKebab } from "react-icons/ci";
const Activities = ({ logCustomEvent }) => {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const { expandActivity, currentPipeLineTasks, nodesData, nodeEdges } =
    useSelector((state) => state?.pipeLines);
  //Handling panel

  const refs = useRef({});

  useEffect(() => {
    const groupElement = getPanelGroupElement("group");
    const leftPanelElement = getPanelElement("left-panel");
    const rightPanelElement = getPanelElement("right-panel");
    const resizeHandleElement = getResizeHandleElement("resize-handle");

    refs.current = {
      groupElement,
      leftPanelElement,
      rightPanelElement,
      resizeHandleElement,
    };
  }, []);
  const { pipeLines, activePipeline, currentPiplineData } = useSelector(
    (state) => state.pipeLines
  );
  // canvas Items
  const [yamlData, setYamlData] = useState(null);
  const [canvasItems, setCanvasItems] = useState([]);
  const [propertiesData, setPropertiesData] = useState({});
  const [connectionItems, setConnectionItems] = useState([]);
  const [realTimeValues, setRealTimeValues] = useState({});
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [history, setHistory] = useState({
    past: [],
    present: [],
    future: [],
  });

  const handleConnection = (fromItem, toItem) => {
    const updatesPipeline = currentPiplineData?.job_nodes?.map((item) => {
      if (item.uniqueId === toItem.uniqueId) {
        const dependencies = item.dependency
          ? `${item.dependency},${fromItem.u_id}`
          : `${fromItem.u_id}`;
        return {
          ...item,
          dependency: dependencies,
        };
      }
      return item;
    });
    setPropertiesData((prevData) => {
      const toItemData = prevData[toItem.uniqueId] || {};
      const updatedData = {
        ...toItemData,
        dependency: toItemData.dependency
          ? `${toItemData.dependency},${fromItem.u_id}`
          : `${fromItem.u_id}`,
      };
      return {
        ...prevData,
        [toItem.uniqueId]: updatedData,
      };
    });
  };
  //History Handling

  const updateHistory = (newPresent) => {
    setHistory((prevHistory) => ({
      past: prevHistory.present
        ? [...prevHistory.past, prevHistory.present]
        : prevHistory.past,
      present: newPresent,
      future: [],
    }));
    setCanvasItems(newPresent);
  };

  //handling Node items (Drag Start , Item Drop)

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("item", JSON.stringify(item));
    e.dataTransfer.effectAllowed = "move";
    logCustomEvent("dragStart", { item });
  };

  const handleTouchStart = (event, item) => {
    event.dataTransfer = {
      setData: (type, val) => {
        event.target.dataset[type] = val;
      },
    };

    handleDragStart(event, item);
  };

  const handleItemClick = async (item) => {
    if (!item) {
      console.error("handleItemClick received an undefined item.");
      return;
    }
    setSelectedItem(item);
    try {
      const response = await fetch(item.yamlPath);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const yamlText = await response.text();
      const data = yaml.load(yamlText);
      setYamlData(data);
      dispatch(getYamlData(data));
    } catch (error) {
      console.error("Error reading YAML file:", error);
      setYamlData(null);
    }
  };
  const handleItemDrop = async (item, x, y) => {
    const uniqueId = uuid();
    const newItem = {
      ...item,
      x,
      y,
      uniqueId,
      u_id: uuid(),
      activity_state: false,
    };

    dispatch(getUniqid(uniqueId));
    try {
      const response = await fetch(item.yamlPath);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const yamlText = await response.text();
      const data = yaml.load(yamlText);
      setYamlData(data);
      dispatch(getCurrentTab("general"));
      const isNodeExist = currentPipeLineTasks?.tasks?.some(
        (task) => String(task?.id) === String(item?.id)
      );
      const inputValues = data?.properties?.reduce((acc, item) => {
        acc[item.name] = "";
        return acc;
      }, {});

      const repeatedTimes = currentPipeLineTasks?.tasks.filter(
        (node) => String(node?.id) === String(item?.id)
      );

      const updatedPipelines = pipeLines.map((pipeline) =>
        pipeline.id === activePipeline
          ? {
              ...pipeline,
              tasks: [
                ...pipeline.tasks,
                {
                  u_id: uniqueId,
                  id: item?.id,
                  opr_name: item?.oprName || "",
                  name: isNodeExist
                    ? ` ${item?.name + " copy" + `(${repeatedTimes?.length})`} `
                    : item?.name,
                  description: "",
                  activity_state: false,
                  timeout: "",
                  retry: "",
                  retry_interval: "",
                  secure_input: false,
                  secure_output: false,
                  input_items: [inputValues],
                  user_properties: [],
                },
              ],
            } // Spread tasksData correctly
          : pipeline
      );
      dispatch(setPipeLineTasks(updatedPipelines));
      dispatch(getSelectedNodeId(uniqueId));
      dispatch(getYamlData({ ...data, custom_id: uniqueId }));
      dispatch(getRenderingYAML({ ...data, custom_id: uniqueId }));
      const currentPipeline = nodesData?.find(
        (node) => node?.id === activePipeline
      );
      const currentTasks = pipeLines?.find(
        (task) => task?.id === activePipeline
      );
      const currentNodeedges = nodeEdges?.find((edges) => {
        return edges?.id === activePipeline;
      });
      dispatch(setCurrentPipeLineData(currentPipeline));
      dispatch(setCurrentPipeLineTasks(currentTasks));
      dispatch(setCurrentPipeLineEdges(currentNodeedges));
      const realTimeResponse = await fetch("/path/to/realtime/data.yaml");
      if (!realTimeResponse.ok) {
        throw new Error(
          `Network response was not ok: ${realTimeResponse.statusText}`
        );
      }
      const realTimeText = await realTimeResponse.text();
      const realTimeData = yaml.load(realTimeText);
      setRealTimeValues(realTimeData);
    } catch (error) {
      console.error(
        "Error reading YAML file or fetching real-time data:",
        error
      );
      setYamlData(null);
      setRealTimeValues({});
    }
    const newCanvasItems = [...canvasItems, newItem];
    const updatesNodeData = nodesData.map((node) => {
      if (node?.id === activePipeline) {
        return {
          ...node,
          job_nodes: [...node.job_nodes, newItem],
        };
      }
      return node;
    });
    dispatch(getNodesData(updatesNodeData));
    updateHistory(newCanvasItems);
    logCustomEvent("itemDrop", { item: newItem, x, y });

    setPropertiesData((prevData) => ({
      ...prevData,
      [uniqueId]: { id: item.id, uniqueId, values: {} },
    }));
  };
  //Handling activity  side bars
  const handleActivitySidebar = () => {
    dispatch(setExpandActivity(!expandActivity));
  };
  const closeSideTabs = () => {
    dispatch(getSourceCodeOpen(false));
    dispatch(getRightSidebar(false));
  };

  // Job Validation Handler
  const validateJobsidebar = () => {
    dispatch(getValidateJob(true));
    dispatch(validateData());
  };

  // handle Deleting Node
  const handleDeleteItem = (id) => {
    const newCanvasItems = canvasItems.filter((item) => item.uniqueId !== id);
    updateHistory(newCanvasItems);
    setSelectedItem(null);
    setPropertiesData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });

    localStorage.setItem("canvasState", JSON.stringify(newCanvasItems));
  };
  return (
    <>
      <div
        className={`grid  ${
          expandActivity ? "grid-cols-[45%_1fr]" : "grid-cols-[auto_1fr]"
        }  border-t${
          expandActivity
            ? "md:grid-cols-[25%_1fr] lg:grid-cols-[20%_1fr]"
            : "md:grid-cols-[auto_1fr]"
        } h-[100%]`}
      >
        <div className=" border-r  grid-area-[sidebar2] ">
          <div
            className={`bg-white ${
              !expandActivity && "grid sidebar_close"
            } justify-between h-full`}
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h2 className="w-full p-1 trasform duration-300 text-start font-bold flex justify-between ">
              {expandActivity && "Activities"}
              {expandActivity ? (
                <VscArrowLeft
                  className="w-6 h-6 hover:bg-[rgb(38,154,248)] p-1 hover:text-white"
                  onClick={handleActivitySidebar}
                />
              ) : (
                <VscArrowRight
                  className="w-6 h-6 hover:bg-[rgb(38,154,248)] p-1 hover:text-white"
                  onClick={handleActivitySidebar}
                />
              )}
            </h2>
            {expandActivity ? (
              <Sidebar
                onDragStart={handleDragStart}
                onTouchStart={handleTouchStart}
                setConnectionItems={setConnectionItems}
                items={items}
              />
            ) : (
              <ActivityName />
            )}
          </div>
        </div>

        <div className="bg-gray-100  grid-areas-[canvas] flex-grow">
          <div className="w-full h-full bg-white flex flex-col">
            <div className="flex justify-between place-items-center w-full ">
              <div className="flex w-1/2 ">
                <button
                  onClick={validateJobsidebar}
                  className="  px-2 py-1 flex property_labels justify-center place-items-center m-1 hover:scale-105"
                >
                  <IoCheckmark className="text-xl mx-1 react_icons_colors " />
                  Validate
                </button>
                <button className=" px-2 py-1 property_labels flex justify-center place-items-center m-1 hover:scale-105">
                  <VscDebugAltSmall className="text-xl mx-1 react_icons_colors" />
                  Debug
                </button>
              </div>

              <div className="w-1/2 flex  justify-end place-items-center">
                <div className="px-4 hidden md:flex justify-center  place-items-center">
                  <Triggers />
                  <RunnerInput />
                </div>
                <div className="block relative md:hidden mx-3 ">
                  <span
                    className=" cursor-pointer"
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    <CiMenuKebab width={8} height={8} />
                  </span>
                  {openMenu && (
                    <div className="absolute top-5 right-1 w-56 z-50 border rounded-sm grid items-center bg-white">
                      <Triggers />
                      <RunnerInput />
                    </div>
                  )}
                </div>
                <JobRun />
              </div>
            </div>
            <PanelGroup
              direction="vertical"
              id="group"
              className="border-t border-gray-300 flex-grow overflow-hidden"
            >
              <Panel id="left-panel">
                <Canvas
                  onItemDrop={handleItemDrop}
                  canvasItems={canvasItems}
                  onItemClick={handleItemClick}
                  history={history}
                  setHistory={setHistory}
                  setCanvasItems={setCanvasItems}
                  onConnectItems={handleConnection}
                  onDeleteItem={handleDeleteItem}
                  selectedItem={selectedItem}
                  updateHistory={updateHistory}
                  className="flex-grow w-full"
                />
              </Panel>
              <PanelResizeHandle id="resize-handle" />
              <PropTabNames className="z-30 " />
              <Panel id="right-panel" maxSize={90} minSize={6} defaultSize={50}>
                <div
                  style={{
                    height: "100%",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                  className="con_sidebar"
                >
                  <SelectedConnectionProvider>
                    <div className="input_parameters">
                      <Properties
                        item={selectedItem}
                        yamlData={yamlData}
                        realTimeData={realTimeValues}
                        propertiesData={propertiesData}
                        connectionItems={connectionItems}
                        items={items}
                      />
                    </div>
                  </SelectedConnectionProvider>
                </div>
              </Panel>
            </PanelGroup>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activities;
