// SelectedConnectionContext.js
import React, { createContext, useState, useContext } from 'react';

const SelectedConnectionContext = createContext();

export const useSelectedConnection = () => {
  return useContext(SelectedConnectionContext);
};

export const SelectedConnectionProvider = ({ children }) => {
  const [selectedConnectionData, setSelectedConnectionData] = useState({});

  const updateSelectedConnection = (connectionData) => {
    setSelectedConnectionData(connectionData);
  };

  return (
    <SelectedConnectionContext.Provider value={{ selectedConnectionData, updateSelectedConnection }}>
      {children}
    </SelectedConnectionContext.Provider>
  );
};
