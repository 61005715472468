import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import withEventTracking from '../../hoc/withEventTracking';

Modal.setAppElement('#root');

const InputModal = ({ isOpen, onRequestClose, onSave, item }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (item) {
      setInputValue(item.inputValue || '');
    }
  }, [item]);

  const handleSave = () => {
    if (item) {
      const dataToStore = {
        id: item.id,
        uniqueId: item.uniqueId,
        name: item.name,
        data: inputValue
      };
      const existingData = JSON.parse(sessionStorage.getItem('inputData')) || [];
      const updatedData = [...existingData, dataToStore];
      sessionStorage.setItem('inputData', JSON.stringify(updatedData));

      onSave(item.uniqueId, inputValue);
      setInputValue('');
    } else {
      console.error('Item is undefined or null');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Input Modal"
      className="fixed bottom-0 right-12 mb-64 w-80 max-w-md mx-auto bg-white rounded-t-lg p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end"
    >
      <div className="w-full p-4 relative">
        <h2 className="text-xl mb-4">Enter data for {item?.name}</h2>
        {item ? (
          <>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <div className="flex justify-end mt-4">
              <button onClick={onRequestClose} className="px-4 py-2 bg-gray-500 text-white rounded mr-2">Cancel</button>
              <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
            </div>
          </>
        ) : (
          <p className="text-red-500">Error: Item is not defined</p>
        )}
      </div>
    </Modal>
  );
};

export default withEventTracking(InputModal);












