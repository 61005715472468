import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
const initialState = {
  folders: [],
  activeSchemaFile: {},
};

// Helper function to find a folder recursively
const findFolderById = (folders, id) => {
  for (const folder of folders) {
    if (folder.id === id) return folder;
    const found = findFolderById(folder.subFolders, id);
    if (found) return found;
  }
  return null;
};

// Helper function to delete a folder recursively
const deleteFolderById = (folders, id) => {
  return folders.filter((folder) => {
    if (folder.id === id) return false;
    folder.subFolders = deleteFolderById(folder.subFolders, id);
    return true;
  });
};

const folderSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    addFolder: (state, action) => {
      const { parentId, name } = action.payload;
      const newFolder = {
        id: uuid(),
        name,
        files: [],
        subFolders: [],
        isOpen: false,
        type: "folder",
      };

      if (parentId) {
        const parentFolder = findFolderById(state.folders, parentId);
        if (parentFolder) {
          parentFolder.subFolders.push(newFolder);
        }
      } else {
        state.folders.push(newFolder); // Root level folder
      }
    },

    addFile: (state, action) => {
      const { folderId, fileName } = action.payload;
      const folder = findFolderById(state.folders, folderId);
      if (folder) {
        folder.files.push({
          id: uuid(),
          name: fileName,
          type: "file",
          schema: "",
        });
        state.activeSchemaFile = {
          id: uuid(),
          name: fileName,
          type: "file",
          schema: "",
        };
      }
    },

    renameItem: (state, action) => {
      const { id, newName, isFolder } = action.payload;
      if (isFolder) {
        const folder = findFolderById(state.folders, id);
        if (folder) folder.name = newName;
      } else {
        state.folders.forEach((folder) => {
          const file = folder.files.find((file) => file.id === id);
          if (file) file.name = newName;
        });
      }
    },
    updateFileSchema: (state, { payload }) => {
      const { id, data } = payload;
      state.folders.forEach((folder) => {
        const file = folder.files.find((file) => file.id === id);
        if (file) file.schema = data;
      });
    },
    setOpenFolder: (state, action) => {
      const { id, isFolder } = action.payload;
      if (isFolder) {
        const folder = findFolderById(state.folders, id);
        if (folder) folder.isOpen = !folder.isOpen;
      }
    },
    setActiveSchemaFile: (state, { payload }) => {
      state.activeSchemaFile = payload;
    },
    deleteItem: (state, action) => {
      const { id, isFolder } = action.payload;
      if (isFolder) {
        state.folders = deleteFolderById(state.folders, id);
      } else {
        state.folders.forEach((folder) => {
          folder.files = folder.files.filter((file) => file.id !== id);
        });
      }
    },
  },
});

export const {
  addFolder,
  addFile,
  renameItem,
  deleteItem,
  setOpenFolder,
  updateFileSchema,
  setActiveSchemaFile,
} = folderSlice.actions;
export default folderSlice.reducer;
