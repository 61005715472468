import React from "react";
import { CiMenuKebab } from "react-icons/ci";
import { HiGlobeAsiaAustralia } from "react-icons/hi2";
import { setActiveContainer } from "../../../features/PipeLines";
import { useDispatch } from "react-redux";

const GlobalProperties = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="cursor-pointer z-40 flex justify-between items-center hover:bg-gray-50 transition p-1 border-b hover:rounded">
        <div
          className="flex items-center w-full"
          onClick={() => dispatch(setActiveContainer("global"))}
        >
          <span>
            <CiMenuKebab className="text-white" />
          </span>{" "}
          <HiGlobeAsiaAustralia className="mx-1  text-[rgb(38,154,248)]" />{" "}
          <h2 className="text-md">Global Properties</h2>
        </div>
        <div className="p-1 hover:bg-white relative"></div>
      </div>
    </>
  );
};
export default GlobalProperties;
