import React, { createContext, useState } from 'react';

export const YamlContext = createContext();

export const YamlProvider = ({ children }) => {
  const [yamlData, setYamlData] = useState('');

  return (
    <YamlContext.Provider value={{ yamlData, setYamlData }}>
      {children}
    </YamlContext.Provider>
  );
};

