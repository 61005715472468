import React, { useEffect, useState, useRef } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePipeline,
  setPipeLineTasks,
  setSaveModal,
} from "../../../features/PipeLines";
import SavePipeLine from "./SavePipeLine";

const DeleteModal = ({ pipeLineName }) => {
  const [inputValue, setInputValue] = useState("");
  const { pipeLines, saveModal } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();
  useEffect(() => {
    if (saveModal && pipeLineName) {
      setInputValue(pipeLineName.job_name || "");
    }
  }, [saveModal, pipeLineName]);

  if (!saveModal) return null;

  const onConfirmDelete = () => {
    dispatch(deletePipeline(pipeLineName?.id));
    dispatch(setSaveModal(false));
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30"></div>
      <div className="relative bg-white p-6  shadow-lg max-w-md w-full">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={() => dispatch(setSaveModal(false))}
        >
          <ImCancelCircle />
        </button>
        <label htmlFor="">Save changes before exit </label>
        <form className="mt-4 space-y-3">
          <input
            type="text"
            value={inputValue}
            disabled
            onChange={(e) => setInputValue(e.target.value)}
            className="mt-1 p-2 w-full border border-black focus:outline-none "
          />

          <div className="flex justify-between place-items-center mt-4 h-full ">
            <SavePipeLine />

            <button
              type="button"
              className="rounded-[2px] mx-2 px-4 border property_labels py-1 border-black hover:border-[rgb(38,154,248)] hover:text-white bg-white text-black hover:bg-[rgb(38,154,248)]"
              onClick={onConfirmDelete}
            >
              Discard Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteModal;
