import React, { useState } from "react";
import { Link } from "react-router-dom";
import withEventTracking from "../../hoc/withEventTracking";
import Topbar from "../Topbar";
import yaml from "js-yaml";
import FileSaver from "file-saver";
import { AiOutlineHome } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineSave } from "react-icons/ai";

import MobileView from "./components/MobileView";

const Navbar = () => {
  const [projectName, setProjectName] = useState("");
  const [selectedRunner, setSelectedRunner] = useState("");
  const [canvasItems, setCanvasItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [propertiesData, setPropertiesData] = useState({});
  const [history, setHistory] = useState({
    past: [],
    present: [],
    future: [],
  });
  const [isTopbarOpen, setIsTopbarOpen] = useState(false); // New state for toggling Topbar visibility on small screens

  const updateHistory = (newPresent) => {
    setHistory((prevHistory) => ({
      past: prevHistory.present
        ? [...prevHistory.past, prevHistory.present]
        : prevHistory.past,
      present: newPresent,
      future: [],
    }));
    setCanvasItems(newPresent);
  };

  const undo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.past.length === 0) return prevHistory;

      const previous = prevHistory.past[prevHistory.past.length - 1];
      const newPast = prevHistory.past.slice(0, prevHistory.past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [prevHistory.present, ...prevHistory.future],
      };
    });
  };

  const redo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.future.length === 0) return prevHistory;

      const next = prevHistory.future[0];
      const newFuture = prevHistory.future.slice(1);

      return {
        past: [...prevHistory.past, prevHistory.present],
        present: next,
        future: newFuture,
      };
    });
  };

  const handleSaveAsYaml = () => {
    const yamlContent = yaml.dump({
      projectName: projectName,
      runnerName: selectedRunner,
      items: canvasItems,
    });
    const blob = new Blob([yamlContent], { type: "text/yaml" });
    FileSaver.saveAs(blob, "canvas.yaml");
  };

  const handleFileOpen = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/yaml") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const text = e.target.result;
          const parsedData = yaml.load(text);
          const newCanvasItems = parsedData.items.map((item) => ({
            ...item,
            uniqueId: `${item.path}-${+new Date()}`,
          }));
          updateHistory(newCanvasItems);
          setProjectName(parsedData.projectName || "");
          setSelectedRunner(parsedData.runnerName || "");
        } catch (error) {
          console.error("Error parsing YAML file:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleNewCanvas = () => {
    if (canvasItems.length > 0) {
      const confirmSave = window.confirm(
        "You have unsaved changes. Do you want to save your current work before creating a new canvas?"
      );
      if (confirmSave) {
        const itemsData = canvasItems.map((item) => {
          const storedData = sessionStorage.getItem(
            `propertiesData-${item.id}`
          );
          const parsedData = storedData ? JSON.parse(storedData) : {};
          return { ...item, properties: parsedData };
        });
        const yamlContent = yaml.dump({ items: itemsData });
        const blob = new Blob([yamlContent], { type: "text/yaml" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "canvas.yaml";
        a.click();
        URL.revokeObjectURL(url);
      }
    }
    updateHistory([]);
    localStorage.removeItem("canvasState");
  };

  const handleDeleteItem = (id) => {
    const newCanvasItems = canvasItems.filter((item) => item.uniqueId !== id);
    updateHistory(newCanvasItems);
    setSelectedItem(null);
    setPropertiesData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });

    localStorage.setItem("canvasState", JSON.stringify(newCanvasItems));
  };

  return (
    <>
      <div className="w-screen z-40 navbar text-white flex justify-evenly place-items-center">
        <div className="block md:hidden w-screen ">
          <MobileView />
        </div>
        <div className="w-1/3  justify-between place-items-center  px-5 hidden md:flex  ">
          <span className="text-white font-black">ETL</span>
          <div className=" ">
            <ul className="flex place-items-center  space-x-4">
              <li>
                <Link
                  to="/"
                  className="text-gray-300 hover:text-white"
                  title="Home"
                >
                  <AiOutlineHome fontWeight={600} className="navicons  " />

                  {/* <img src="/images/home.png" className="w-5 h-5" alt="" /> */}
                </Link>
              </li>
              <li>
                <Link
                  to="/configurations"
                  className="text-gray-300 hover:text-white"
                  title="Configurations"
                >
                  <IoSettingsOutline fontWeight={900} className="navicons" />
                  {/* <img src="/images/settings.png" className="w-5 h-5" alt="" /> */}
                </Link>
              </li>
              <li>
                <Link
                  to="/saved-items"
                  className="text-gray-300 hover:text-white"
                  title="Saved Items"
                >
                  <AiOutlineSave className="navicons" />
                  {/* <img
                    src="/images/storage-folder.png"
                    className="w-5 h-5"
                    alt=""
                  /> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-2/3 hidden md:flex justify-between ">
          <Topbar
            undo={undo}
            redo={redo}
            handleSaveAsYaml={handleSaveAsYaml}
            handleFileOpen={handleFileOpen}
            handleNewCanvas={handleNewCanvas}
            handleDeleteItem={handleDeleteItem}
            canvasItems={canvasItems}
          />
        </div>
      </div>
    </>
  );
};

export default withEventTracking(Navbar);
