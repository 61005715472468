import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ConnectionDetails from "../../ConnectionDetails";
import TreeStructure from "./TreeStructure";

const Connections = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-[100]">
      <div
        className="flex justify-between place-items-center activity_names cursor-pointer text-gray-700 p-2 m-1 "
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <img
            src="/images/sidebar/api.png"
            className="w-5 h-5 mx-1 con_images "
            alt=""
          />
          <span className="font-semibold hover:scale-105">Connections</span>
        </div>
        <span>
          {isOpen ? (
            <IoIosArrowUp className="react_icons_colors" />
          ) : (
            <IoIosArrowDown className="react_icons_colors" />
          )}
        </span>
      </div>

      {isOpen && (
        <div
          className={`w-11/12 transition-all duration-300 ease-in-out overflow-y-scroll hide_scrollbar ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {/* {connections.map((sub, index) => (
            <div
              key={index}
              className="transition-all duration-300  text-sm px-4 py-2 border  cursor-move flex justify-between items-center mx-4 my-2 movables hover:text-white "
            >
              <div className="sub_items flex items-center">
                <img
                  src={sub?.imgpath}
                  alt=""
                  className="w-5 h-5 mx-1 con_images  "
                />
                <span className="hover:scale-105">{sub}</span>
              </div>
            </div>
          ))} */}
          <TreeStructure />
        </div>
      )}
    </div>
  );
};

export default Connections;
