import React, { useEffect, useState } from "react";
import RunnerInput from "../RunnerInput";
import { VscDebugStart } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import {
  setTasksOutput,
  setRunningPipelines,
  validateData,
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../features/PipeLines";
import { getValidateJob } from "../../features/nodesData";
import axios from "axios";
import { FaPause, FaStop } from "react-icons/fa";
import Triggers from "../Activity/components/Triggers";
import { getJobStatus, getShowPopup } from "../../features/commonProperties";
const JobRun = () => {
  const {
    validationErrors = [],
    pipeLines,
    activePipeline,
    currentPiplineData,
    currentPipeLineTasks,
    tasksOutput,
    runningPipelines = {},
  } = useSelector((state) => state.pipeLines);
  const { jobstatus, currentUrl, customUrl } = useSelector(
    (state) => state.commonProps
  );
  const { connections } = useSelector((state) => state.connections);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateData());
  }, [currentPiplineData, currentPipeLineTasks]);
  const RunPipeLine = async () => {
    // dispatch(validateData());

    const manditorycolumns = validationErrors?.find(
      (err) => err?.id === activePipeline
    );

    if (manditorycolumns?.input_errors?.length === 0) {
      const newRunningState = {
        ...runningPipelines,
        [activePipeline]: !runningPipelines[activePipeline],
      };
      dispatch(setRunningPipelines(newRunningState));
      let currentJob = pipeLines?.find(
        (pipeline) => pipeline?.id === activePipeline
      );

      if (!currentJob) {
        alert("No job found for the active pipeline.");
        return;
      }

      // Ensure the `id` field exists
      if (!currentJob.id) {
        alert("Missing pipeline ID.");
        return;
      }

      // Assign sequential IDs to tasks, ensure `isSkipped` is properly checked, and add `dependency`
      const updatedTasks = (currentJob.tasks || [])
        .filter((task) => !task?.activity_state) // Filter only non-skipped tasks
        .map((task, index) => ({
          ...task,
          id: index + 1,
          ...(index > 0 ? { dependency: [index] } : {}), // Add dependency only from the second task onward
        }));

      // Update current job with properly structured tasks
      currentJob = {
        ...currentJob,
        tasks: updatedTasks,
      };
      console.log(currentJob, pipeLines);

      // // Ensure input_values are formatted correctly
      // const {
      //   id: pipeline_id,
      //   job_name: pipeline_name,
      //   ...input_values
      // } = currentJob;
      // // Ensure `input_values` is properly structured
      // const formattedInputValues = {
      //   triggers: currentJob.triggers || [],
      //   run_type: currentJob.run_type || "",
      //   tasks: updatedTasks,
      // };
      dispatch(setTasksOutput([...tasksOutput, currentJob]));
      // const data = {
      //   pipeline_id,
      //   pipeline_name,
      //   is_triggered: true,
      //   trigger_id: "TRG-12378",
      //   input_values: input_values, // Ensure it's an object
      //   job_status: "pending",
      //   triggered_by: "admin",
      //   start_date: "2024-02-24 10:00:00",
      //   end_date: null,
      //   error_log: null,
      //   updated_date: null,
      // };
      if (runningPipelines[activePipeline]) {
        console.log(`job killed`);
        // try {
        //   const response = await axios.post(
        //     `${
        //       currentPipeLineTasks?.spark_master_ui +
        //       currentPipeLineTasks?.kill_url
        //     }`,
        //     {
        //       headers: {
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   );
        //   console.log(response)

        //   if (response.data.status === "submitted") {
        //     dispatch(
        //       getJobStatus({
        //         show: true,
        //         message: "Job Killed Successfully",
        //       })
        //     );
        //     setTimeout(() => {
        //       dispatch(getJobStatus({ show: false, message: "" }));
        //     }, 2000);

        //   }
        // } catch (error) {
        //   console.error(
        //     "API Error:",
        //     error.response ? error.response.data : error
        //   );
        // }
      } else {
        try {
          const response = await axios.post(
            customUrl?.isCustom ? customUrl?.url : `${currentUrl?.url}jobs.php`,
            currentJob,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response);
          if (response.data.status === "submitted") {
            dispatch(
              getJobStatus({
                show: true,
                message: "Job Submitted Successfully",
              })
            );
            setTimeout(() => {
              dispatch(getJobStatus({ show: false, message: "" }));
            }, 2000);
            const { tracking_url, job_id, spark_master_ui } = response?.data;
            dispatch(
              setCurrentPipeLineTasks({
                ...currentPipeLineTasks,
                tracking_url,
                job_id,
                spark_master_ui,
              })
            );
            const updateJob_id = pipeLines?.map((pipe) => {
              if (pipe?.id === activePipeline) {
                return {
                  ...currentPipeLineTasks,
                  tracking_url,
                  job_id,
                  spark_master_ui,
                };
              } else {
                return pipe;
              }
            });
            dispatch(setPipeLineTasks(updateJob_id));
          }
        } catch (error) {
          console.error(
            "API Error:",
            error.response ? error.response.data : error
          );
        }
      }
      //   {
      //     "job_id": "1743492135",
      //     "spark_master_ui": "http://192.168.36.92:8080",
      //     "status": "submitted",
      //     "tracking_url": "/spark/job-status/1743492135"
      // }
    } else if (currentPiplineData?.job_nodes?.length === 0) {
      alert("Add some Tasks");
    } else {
      dispatch(getValidateJob(true));
    }
  };

  return (
    <div className="flex justify-end items-center pr-5 rounded-sm">
      {runningPipelines[activePipeline] ? (
        <button
          className="group inline-block rounded-full bg-blue-500 text-neutral-50 shadow-md hover:bg-red-600 px-6 pb-2 pt-2 text-xs font-medium uppercase transition duration-150 ease-in-out focus:outline-none"
          type="button"
          onClick={RunPipeLine}
        >
          <div className="mr-1 inline-block">
            <div className="group-hover:hidden inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-current border-r-transparent"></div>
            <FaStop className="hidden group-hover:inline-block mb-[3px]" />
          </div>
          <span className="group-hover:hidden">Running</span>
          <span className="hidden group-hover:inline">Stop</span>
        </button>
      ) : (
        <button
          className="inline-block rounded-full bg-green-500 text-neutral-50 shadow-md hover:bg-green-600 px-6 pb-1.5 pt-2 text-xs font-medium uppercase transition duration-150 ease-in-out focus:outline-none"
          type="button"
          onClick={RunPipeLine}
        >
          <span className="flex justify-center items-center">
            <VscDebugStart className="run_icon mb-[3px]" />
            Run
          </span>
        </button>
      )}
      <div
        className={`fixed bottom-4 right-4 bg-green-600 z-40 text-white px-6 py-3 rounded-lg shadow-lg transition-transform duration-500 ease-in-out ${
          jobstatus?.show
            ? "translate-y-0 opacity-100"
            : "translate-y-10 opacity-0"
        }`}
        style={{ visibility: jobstatus?.show ? "visible" : "hidden" }}
      >
        Job Submitted Successfully
      </div>
    </div>
  );
};

export default JobRun;
