import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleInfo } from "react-icons/ci";
import { getAllTask, getSelectedNodeId } from "../../../features/nodesData";
import { getShowPopup } from "../../../features/commonProperties";
import { FaStarOfLife } from "react-icons/fa6";
import axios from "axios";
import {
  getNodesData,
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";

const GeneralProperties = () => {
  const { selectedNodeId, tasksData } = useSelector(
    (state) => state.totalNodes
  );
  const [oprName, setOprName] = useState("");
  const {
    pipeLines,
    activePipeline,
    currentPipeLineTasks,
    currentPiplineData,
    nodesData,
    focusErrorInput,
  } = useSelector((state) => state.pipeLines);
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUrl } = useSelector((state) => state.commonProps);
  const [formData, setFormData] = useState({
    u_id: null,
    id: null,
    name: "",
    description: "",
    timeout: "",
    retry: "",
    retry_interval: "",
    secure_input: false,
    secure_output: false,
    input_items: [],
    opr_name: "",
  });
  useEffect(() => {
    async function getConnections(params) {
      try {
        const response = await axios.get(
          `${currentUrl?.url}connections_readAll.php`
        );
        if (response) {
          setConnectionOptions(response.data.data);
        }
      } catch (error) {}
    }
    // getConnections();
  }, []);
  const handleSelectConnection = (connection) => {
    setSelectedConnection(connection);
    setInputValues((prev) => ({
      ...prev,
      connectionId: connection.conn_id,
      connectionName: connection.label,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedTask = {
      ...formData,
      u_id: selectedNodeId,
      opr_name: oprName || "",
    };
    const updatedTasksData = currentPipeLineTasks?.tasks.map((task) => {
      return task.u_id === updatedTask.u_id
        ? { ...task, ...updatedTask, activity_state: task?.activity_state }
        : task;
    });
    // const dd = currentPipeLineTasks?.tasks.map((task) => {
    //   console.log(task);
    //   console.log(formData);
    //   return task.u_id === selectedNodeId
    //     ? { ...task, ...formData, activity_state: task?.activity_state }
    //     : task;
    // });
    // console.log(dd);
    const updatedPipeline = pipeLines.map((pipeLine) => {
      if (pipeLine?.id === currentPiplineData?.id) {
        return { ...pipeLine, tasks: updatedTasksData };
      } else {
        return pipeLine;
      }
    });

    const updatedNodeName = currentPiplineData.job_nodes?.map((node) => {
      if (node?.uniqueId === selectedNodeId) {
        return { ...node, name: formData?.name };
      } else {
        return node;
      }
    });
    const updatedNodesData = nodesData?.map((node) => {
      if (node?.id === activePipeline) {
        return { ...node, job_nodes: updatedNodeName };
      } else {
        return node;
      }
    });
    dispatch(getNodesData(updatedNodesData));
    dispatch(setPipeLineTasks(updatedPipeline));
    dispatch(
      setCurrentPipeLineTasks({
        ...currentPipeLineTasks,
        tasks: updatedTasksData,
      })
    );
    dispatch(getShowPopup(true));
    setTimeout(() => {
      dispatch(getShowPopup(false));
    }, 2000);
  };
  useEffect(() => {
    const selectedNode = currentPipeLineTasks?.tasks.find((task) => {
      return task?.u_id === selectedNodeId;
    });
    setFormData({
      name: selectedNode?.name || "",
      u_id: selectedNode?.u_id || "",
      description: selectedNode?.description || "",
      activity_state: selectedNode?.activity_state || "",
      timeout: selectedNode?.timeout || "",
      retry: selectedNode?.retry || "",
      retry_interval: selectedNode?.retry_interval || "",
      secure_input: selectedNode?.secure_input || false,
      secure_output: selectedNode?.secure_output || false,
      input_items: selectedNode?.input_items || [],
      opr_name: selectedNode?.opr_name || "", // Ensure it gets a default value
    });
    setOprName(selectedNode?.opr_name);
  }, [selectedNodeId, currentPipeLineTasks]);

  useEffect(() => {
    const handleButtonClick = () => {
      const input = document.getElementById(focusErrorInput);
      if (input) {
        input.focus();
        input.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
    handleButtonClick();
  }, [focusErrorInput, selectedNodeId]);
  return (
    <>
      <div className="w-full lg:w-3/5">
        <form onSubmit={handleSubmit} className="space-y-3 p-4 sm:p-6 ">
          {/* Name Field */}
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label
              htmlFor="name"
              className="flex items-center w-full sm:w-1/3  sm:mb-0 property_labels"
            >
              Name <FaStarOfLife fill="red" className="px-1 manditory" />
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  Activity Name you can customise The name you want
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-1 custom_input border border-black outline-none  property_inputs"
              />
              {formErrors.name && (
                <p className="text-red-500 property_labels text-sm mt-1">
                  {formErrors.name}
                </p>
              )}
            </div>
          </div>

          {/* Description Field */}
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label
              htmlFor="description"
              className="block w-full sm:w-1/3  sm:mb-0 property_labels"
            >
              Description
            </label>
            <div className="w-full sm:w-2/3">
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="w-full p-1 outline-none custom_input border border-black  property_inputs"
              />
              {formErrors.description && (
                <p className="text-red-500 property_labels text-sm mt-1">
                  {formErrors.description}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label
              htmlFor="timeout"
              className=" flex items-center w-full sm:w-1/3  sm:mb-0 property_labels"
            >
              Timeout
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  Maximum amount of time an activity can run. Default is 12
                  hours, the minimum is 10 minutes, and the maximum amount of
                  time allowed is 7 days. Format is in D.HH:MM:SS
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="number"
                id="timeout"
                name="timeout"
                value={formData.timeout}
                onChange={handleChange}
                className="w-full p-1 outline-none border border-black custom_input property_inputs"
              />
              {formErrors.timeout && (
                <p className="text-red-500 property_labels text-sm mt-1">
                  {formErrors.timeout}
                </p>
              )}
            </div>
          </div>

          {/* Retry Field */}
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label
              htmlFor="retry"
              className="block w-full sm:w-1/3  sm:mb-0 property_labels"
            >
              Retry
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  Maximum number of retry attempts
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="number"
                id="retry"
                name="retry"
                value={formData.retry}
                onChange={handleChange}
                className="w-full p-1 outline-none border border-black custom_input property_inputs"
              />
              {formErrors.retry && (
                <p className="text-red-500 property_labels text-sm mt-1">
                  {formErrors.retry}
                </p>
              )}
            </div>
          </div>

          {/* Retry Interval Field */}
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label
              htmlFor="retry_interval"
              className="block w-full sm:w-1/3  sm:mb-0 property_labels"
            >
              Retry Interval{" "}
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  The number of seconds between each retry attempt
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="number"
                id="retry_interval"
                name="retry_interval"
                value={formData.retry_interval}
                onChange={handleChange}
                className="w-full p-1 outline-none border border-black custom_input property_inputs"
              />
              {formErrors.retry_interval && (
                <p className="text-red-500 property_labels text-sm mt-1 ">
                  {formErrors.retry_interval}
                </p>
              )}
            </div>
          </div>

          {/* Secure Input and Secure Output */}
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label className="block w-full sm:w-1/3  sm:mb-0 property_labels">
              Secure Input
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  When checked, input from the activity will not be captured in
                  logging
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="checkbox"
                name="secure_input"
                checked={formData.secure_input}
                onChange={handleChange}
                className="w-4 h-4"
              />
            </div>
          </div>
          <div className="flex flex-wrap sm:justify-between sm:items-center">
            <label className="block w-full sm:w-1/3  sm:mb-0 property_labels">
              Secure Output
              <div className="tooltip">
                <CiCircleInfo fill="blue" />
                <span className="tooltiptext">
                  When checked, input from the activity will not be captured in
                  logging
                </span>
              </div>
            </label>
            <div className="w-full sm:w-2/3">
              <input
                type="checkbox"
                name="secure_output"
                checked={formData.secure_output}
                onChange={handleChange}
                className="w-4 h-4"
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="text-center  sm:text-end">
            <button
              type="submit"
              className="cursor-pointer w-full sm:w-24  text-white text-center  property_inputs outline-none items-center bg-blue-600 px-4 py-2 rounded  hover:bg-blue-500 transition-all ease-in duration-200"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GeneralProperties;
