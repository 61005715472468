import React from "react";
import { Link } from "react-router-dom";
import SampleNav from "../../Navbar/components/samplenav";

const MainHome = () => {
  return (
    <>
      <SampleNav />
      <div className="flex w-full flex-col border items-center justify-center px-6 lg:px-20 py-12 bg-gray-100 min-h-screen bg-[url('/images/bg3.png')] bg-cover bg-no-repeat bg-center">
        <div className="lg:w-1/2 text-center ">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Build in the cloud with an Haarg ETL account
          </h1>
          <p className="text-gray-700 mb-6">
            Get started creating, deploying, and managing applications—across
            multiple cloud, on-premises, and at the edge—with scalable and
            cost-efficient ETL services.
          </p>
          <div className="flex justify-center gap-4">
            <Link
              to="/home"
              className="bg-green-500 hover:scale-105 text-white px-6 py-2 rounded-md font-semibold hover:bg-green-600"
            >
              Try Free trail
            </Link>
            <Link
              to={"/user-login"}
              className="border hover:scale-105 border-gray-500 px-6 py-2 rounded-md font-semibold text-gray-900 hover:bg-gray-200"
            >
              Sing-In
            </Link>
          </div>
        </div>

        {/* <div className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center">
        <img
          src="/images/main-bg.jpg"
          alt="Azure Screenshot"
          className="w-full max-w-lg shadow-lg rounded-md"
        />
      </div> */}
      </div>
      <div className="w-full h-screen"></div>
    </>
  );
};

export default MainHome;
