import React from "react";
import { CiMenuKebab } from "react-icons/ci";
import { CgTemplate } from "react-icons/cg";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";

const Templates = () => {
  return (
    <>
      <div className="cursor-pointer z-40 flex justify-between items-center hover:bg-gray-50 transition p-1 border-b hover:rounded">
        <div className="flex items-center w-full">
          <span>
            {true ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}
          </span>{" "}
          <CgTemplate className="mx-1  text-[rgb(38,154,248)]" />{" "}
          <h2 className="text-md">Templates</h2>
        </div>
        <div className="p-1 hover:bg-white relative">
          {" "}
          <CiMenuKebab />
        </div>
      </div>
    </>
  );
};
export default Templates;
