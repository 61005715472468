import React, { useEffect, useState } from "react";
import { getCurrentUrl, getisCustomUrl } from "../../features/commonProperties";
import { useDispatch, useSelector } from "react-redux";

const APISelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { phpUrl, pythonUrl, currentUrl, customUrl } = useSelector(
    (state) => state.commonProps
  );

  const handleChange = ({ target: { value } }) => {
    if (value === phpUrl?.language) {
      dispatch(getCurrentUrl(phpUrl));
    } else {
      dispatch(getCurrentUrl(pythonUrl));
    }
    setSelectedLanguage(value);
    setSubmitted(false);
  };

  const handleCustomUrlToggle = () => {
    dispatch(getisCustomUrl({ isCustom: !customUrl?.isCustom, url: "" }));
    setSubmitted(false);
  };

  const handleCustomUrlChange = ({ target: { value } }) => {
    dispatch(getisCustomUrl({ isCustom: true, url: value }));
    setSubmitted(false);
  };

  const handleSubmit = () => {
    if (customUrl?.isCustom) {
      console.log("Custom URL:", customUrl?.url);
    } else {
      console.log("Selected API:", selectedLanguage);
    }
    setSubmitted(true); // Show thumbs up on submission
  };

  useEffect(() => {
    if (currentUrl) {
      setSelectedLanguage(currentUrl.language);
    }
  }, [currentUrl]);

  return (
    <div className="p-4">
      <label className="block text-sm font-medium text-gray-700">
        Select Language:
      </label>
      <select
        className="w-34 p-2 border rounded mt-2"
        value={selectedLanguage}
        onChange={handleChange}
        disabled={customUrl?.isCustom}
      >
        <option value="">Select API</option>
        <option value="PHP">PHP</option>
        <option value="PYTHON">Python</option>
      </select>

      <div className="mt-2">
        <input
          type="checkbox"
          id="customUrlCheckbox"
          checked={customUrl?.isCustom}
          onChange={handleCustomUrlToggle}
          className="mr-2"
        />
        <label htmlFor="customUrlCheckbox" className="text-sm text-gray-700">
          Custom URL
        </label>
      </div>

      {customUrl?.isCustom && (
        <input
          type="text"
          className="w-full p-2 border rounded mt-2"
          placeholder="Enter custom API URL"
          value={customUrl?.url}
          onChange={handleCustomUrlChange}
        />
      )}

      <button
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded flex items-center"
        onClick={handleSubmit}
      >
        Submit
      </button>

      {submitted && (
        <p className="mt-2 text-green-600 text-lg flex items-center">
          Submitted! 👍
        </p>
      )}

      {selectedLanguage && !customUrl?.isCustom && (
        <p className="mt-2 text-blue-600">
          You selected: {selectedLanguage.toUpperCase()}
        </p>
      )}
    </div>
  );
};

export default APISelector;
