import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTask } from "../../../features/nodesData";
import { getShowPopup } from "../../../features/commonProperties";
import {
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";
import ConnectionsModal from "../../Sidebar/components/ConnectionsModal";
const PropSettings = () => {
  const dispatch = useDispatch();
  const { selectedConnection, connections } = useSelector(
    (state) => state.connections
  );
  const [newConnection, setNewConnection] = useState(false);
  const { yamlData, selectedNodeId } = useSelector((state) => state.totalNodes);
  const { currentPipeLineTasks, pipeLines, focusErrorInput } = useSelector(
    (state) => state?.pipeLines
  );
  const { curretPropTab } = useSelector((state) => state.commonProps);
  const [properties, setProperties] = useState([]);
  const [selectedConnDetails, setSelectedConnDetails] = useState(null);

  useEffect(() => {
    if (yamlData?.properties) {
      const task = currentPipeLineTasks?.tasks?.find(
        (task) => task.u_id === selectedNodeId
      );
      const inputValues = task?.input_items?.[0] || {};
      const updatedProperties = yamlData.properties.map((property) => ({
        ...property,
        value: inputValues[property.name] || "",
      }));

      setProperties(updatedProperties);
    } else {
      setProperties([]);
    }
  }, [yamlData, currentPipeLineTasks]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let updatedProperties = properties.map((property, i) =>
      i === index ? { ...property, value } : property
    );

    // If user selects a connection, update the selected connection details
    if (name === "conn_id") {
      console.log(value);
      const selectedConn = connections
        .flatMap((conn) => conn.children || [])
        .find((child) => child.conn_id === Number(value));

      setSelectedConnDetails(selectedConn || null);
    }

    setProperties(updatedProperties);
  };
  const submitHandler = () => {
    let formattedProperties = properties.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    if ("conn_id" in formattedProperties && selectedConnDetails) {
      formattedProperties = {
        ...formattedProperties,
        conn_id: Number(formattedProperties?.conn_id),
      };
    }
    console.log(formattedProperties);
    const updatedConnections = [...currentPipeLineTasks.connections];

    if (
      selectedConnDetails &&
      !updatedConnections.some(
        (con) => con.conn_id === Number(selectedConnDetails.conn_id)
      )
    ) {
      updatedConnections.push(selectedConnDetails);
    }

    const updatedCrTasks = {
      ...currentPipeLineTasks,
      connections: updatedConnections,
    };
    const updatedTasks = currentPipeLineTasks?.tasks?.map((task) =>
      String(task.u_id) === String(selectedNodeId)
        ? {
            ...task,

            input_items: [formattedProperties],
          }
        : task
    );
    const updatedPipeLines = pipeLines?.map((pipeLine) =>
      pipeLine?.id === currentPipeLineTasks?.id
        ? { ...pipeLine, tasks: updatedTasks, connections: updatedConnections }
        : pipeLine
    );

    dispatch(setPipeLineTasks(updatedPipeLines));
    dispatch(
      setCurrentPipeLineTasks({
        ...currentPipeLineTasks,
        connections: updatedConnections,
        tasks: updatedTasks,
      })
    );
    dispatch(getAllTask(updatedTasks));
    dispatch(getShowPopup(true));
    setTimeout(() => {
      dispatch(getShowPopup(false));
    }, 2000);
  };

  useEffect(() => {
    const handleButtonClick = async () => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      const input = document.getElementById(focusErrorInput);
      if (input) {
        input.focus();
        input.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
    handleButtonClick();
  }, [focusErrorInput, selectedNodeId, curretPropTab]);

  return (
    <div className="space-y-3 p-4 sm:p-6 mb-10 w-full lg:w-3/5">
      {properties.map((property, index) => {
        const isConnectionField = property.name === "conn_id";
        const availableConnections = connections
          .filter((conn) => conn.children && conn.children.length > 0)
          .flatMap((conn) =>
            conn.children.map((child) => ({
              id: child.conn_id,
              name: child.connection_name,
            }))
          );
        return (
          <div
            key={index}
            className="flex flex-wrap sm:justify-between sm:items-center"
          >
            <label
              htmlFor={property.name}
              className="block w-full sm:w-1/3 sm:mb-0 property_labels"
            >
              {property.label}
            </label>
            <div className="w-full sm:w-2/3">
              {isConnectionField ? (
                <div className="flex justify-between place-items-center">
                  <select
                    name="conn_id"
                    id={property.name}
                    value={property.value || ""}
                    onChange={(e) => handleInputChange(e, index)}
                    className="w-full p-1 outline-none border border-black property_labels custom_input"
                  >
                    <option value="">Select a Connection</option>
                    {availableConnections.map((conn) => (
                      <option key={conn.id} value={conn.id}>
                        {conn.name}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={() => setNewConnection(true)}
                    className="px-3 py-[4px] ml-2 border text-[13px] rounded bg-blue-500 text-white"
                  >
                    New
                  </button>
                </div>
              ) : (
                <input
                  type={property.type || "text"}
                  name="value"
                  id={property.name}
                  value={property.value || ""}
                  onChange={(e) => handleInputChange(e, index)}
                  className="w-full p-1 outline-none border border-black property_labels custom_input"
                />
              )}
            </div>
          </div>
        );
      })}

      <button
        onClick={submitHandler}
        className="relative text-blue-500 px-8 py-2 rounded-sm bg-white isolation-auto z-10 border-2 border-blue-500
        before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-right-full before:hover:right-0 before:rounded-lg hover:text-white before:bg-blue-500 before:-z-10 before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-500 property_labels"
      >
        Submit
      </button>
      {newConnection && (
        <ConnectionsModal onClose={() => setNewConnection(false)} />
      )}
    </div>
  );
};

export default PropSettings;
