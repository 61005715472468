import React, { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { sql } from "@codemirror/lang-sql";
import { useDispatch, useSelector } from "react-redux";
import { updateSQLQuery } from "../../../features/DDL";

const SQLEditor = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { currentSQLquerry } = useSelector((state) => state.ddlData);

  const handleQueryChange = (newValue) => {
    setValue(newValue);
    if (currentSQLquerry?.id) {
      dispatch(
        updateSQLQuery({ fileId: currentSQLquerry.id, query: newValue })
      );
    }
  };
  useEffect(() => {
    setValue(currentSQLquerry?.DDL[0]);
  }, [currentSQLquerry]);

  return (
    <div className="h-full border overflow-y-scroll hide_scrollbar">
      <CodeMirror
        value={value}
        style={{ backgroundColor: "black" }}
        extensions={[sql()]}
        onChange={handleQueryChange}
        height="100vh"
        options={{ lineNumbers: true }}
        className="w-full min-h-[100%]"
      />
    </div>
  );
};

export default SQLEditor;
