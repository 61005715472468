// import React, { createContext, useContext, useState, useEffect } from 'react';

// const CanvasContext = createContext();

// export const CanvasProvider = ({ children }) => {
//   const [canvasState, setCanvasState] = useState(()=>{
//     const savedState = sessionStorage.getItem('canvasState');
//     return savedState ? JSON.parse(savedState) : { nodes: [], edges: [] }
// });

// useEffect(() => {
//     sessionStorage.setItem('canvasState', JSON.stringify(canvasState));
//   }, [canvasState]);

//   return (
//     <CanvasContext.Provider value={{ canvasState, setCanvasState }}>
//       {children}
//     </CanvasContext.Provider>
//   );
// };

// export const useCanvas = () => {
//   return useContext(CanvasContext);
// };





import React, { createContext, useContext, useState, useEffect } from 'react';

const CanvasContext = createContext();

export const CanvasProvider = ({ children }) => {
  const [canvasState, setCanvasState] = useState(() => {
    const savedState = sessionStorage.getItem('canvasState');
    return savedState ? JSON.parse(savedState) : { nodes: [], edges: [] };
  });

  useEffect(() => {
    if (canvasState) {
      sessionStorage.setItem('canvasState', JSON.stringify(canvasState));
    }
  }, [canvasState]);

  return (
    <CanvasContext.Provider value={{ canvasState, setCanvasState }}>
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => useContext(CanvasContext);
