import React from "react";
import FolderItem from "./FolderItems";

const FolderList = ({ items, openMenuId, setOpenMenuId }) => {
  return (
    <ul>
      {items.map((item) => (
        <FolderItem
          key={item.id}
          item={item}
          openMenuId={openMenuId}
          setOpenMenuId={setOpenMenuId}
        />
      ))}
    </ul>
  );
};

export default FolderList;
