import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
const initialState = {
  pipeLines: [],
  expandActivity: true,
  pipeLineCount: 1,
  activePipeline: 1,
  nodesData: [],
  currentPiplineData: {},
  currentPipeLineRunner: "",
  currentPipeLineTasks: {},
  validationErrors: [],
  focusErrorInput: "",
  triggers: [],
  activeContainer: "pipelines",
  global_parameters: [],
  saveModal: false,
  tasksOutput: [],
  runningPipelines: {},
  nodeEdges: [],
  currentPipeLineEdges: {},
};

const pipeLinesSlice = createSlice({
  name: "pipeLines",
  initialState,
  reducers: {
    addPipeline: (state) => {
      const uniqueId = uuid();

      const newPipeline = {
        id: uniqueId,
        job_name: `Pipeline ${state.pipeLineCount}`,
        run_type: "",
        Global_variables: [],
        Pipeline_variables: [],
        settings: {
          concurrancy: "",
          elapsed_time_metric: "",
          isElapsedTimeMetricChecked: false,
        },
        connections: [],
        tasks: [],
        isStarted: false,
        triggers: { pipeline_id: uniqueId, triggers_data: [] },
      };
      const newNode = {
        id: uniqueId,
        job_nodes: [],
        run_type: "",
      };
      const newNodeEdges = {
        id: uniqueId,
        edges: [],
      };
      const validationError = { id: uniqueId, input_errors: [] };
      state.nodesData.push(newNode);
      state.pipeLines.push(newPipeline);
      state.nodeEdges.push(newNodeEdges);
      state.validationErrors.push(validationError);
      state.activePipeline = uniqueId;
      state.pipeLineCount++;
    },
    getNodesData: (state, { payload }) => {
      state.nodesData = payload;
    },
    deletePipeline: (state, action) => {
      state.pipeLines = state.pipeLines.filter(
        (pipeline) => pipeline.id !== action.payload
      );
      state.activePipeline = state.pipeLines[0]?.id || null;

      const updatedNodesData = state?.nodesData.filter(
        (node) => node?.id !== action.payload
      );
      state.nodesData = updatedNodesData;
      if (state.pipeLines.length === 0) {
        state.pipeLineCount = 1;
      }
    },
    setActivePipeline: (state, { payload }) => {
      state.activePipeline = payload;
    },
    setPipeLineCount: (state) => {
      state.pipeLineCount = 1;
    },
    setExpandActivity: (state, { payload }) => {
      state.expandActivity = payload;
    },

    setPipeLineTasks: (state, { payload }) => {
      state.pipeLines = payload;
    },
    setCurrentPipeLineData: (state, { payload }) => {
      state.currentPiplineData = payload;
    },
    setPipeLineConnection: (state, { payload }) => {
      state.pipeLines = payload;
    },
    setCurrentPipeLineTasks: (state, { payload }) => {
      state.currentPipeLineTasks = payload;
    },
    validateData: (state) => {
      if (!state.currentPipeLineTasks?.tasks) {
        console.error("No tasks found in the current pipeline.");
        return;
      }

      const newErrors = state.currentPipeLineTasks.tasks
        .filter((task) => !task.activity_state) // Skip validation for tasks with isSkipped === true
        .flatMap((task, index) => {
          const errors = [];

          // General fields validation
          const requiredFields = [
            "name",
            "description",
            "timeout",
            "retry_interval",
          ];
          const errorObj = requiredFields.reduce((acc, field) => {
            if (!task[field] || task[field].toString().trim() === "") {
              acc[field] = `${field.replace("_", " ")} is required`;
            }
            return acc;
          }, {});

          Object.entries(errorObj).forEach(([field, message]) => {
            errors.push({
              id: `${state.activePipeline}-${index + 1}`,
              parent_id: task.u_id,
              task_name: task.name || `Task ${index + 1}`,
              field,
              error: message,
              error_in: "general",
            });
          });

          // Validate input_values dynamically
          if (Array.isArray(task?.input_items)) {
            task.input_items.forEach((input, inputIndex) => {
              Object.entries(input).forEach(([key, value]) => {
                if (!value || value.toString().trim() === "") {
                  errors.push({
                    id: `${state.activePipeline}-${index + 1}-${
                      inputIndex + 1
                    }`,
                    parent_id: task.u_id,
                    task_name: task.name || `Task ${index + 1}`,
                    field: key,
                    error: `${key} is required`,
                    error_in: "settings",
                  });
                }
              });
            });
          }

          return errors;
        });

      // Update validationErrors for the active pipeline
      const existingErrorIndex = state.validationErrors.findIndex(
        (err) => err.id === state.activePipeline
      );

      if (existingErrorIndex !== -1) {
        state.validationErrors[existingErrorIndex].input_errors = newErrors;
      } else {
        state.validationErrors.push({
          id: state.activePipeline,
          input_errors: newErrors,
        });
      }
    },

    setValidationErrors: (state, { payload }) => {
      state.validationErrors = payload;
    },
    getFocusErrorInput: (state, { payload }) => {
      state.focusErrorInput = payload;
    },
    setActiveContainer: (state, { payload }) => {
      state.activeContainer = payload;
    },
    setGlobalParameters: (state, { payload }) => {
      state.global_parameters = payload;
    },
    setSaveModal: (state, { payload }) => {
      state.saveModal = payload;
    },
    setTasksOutput: (state, { payload }) => {
      state.tasksOutput = payload;
    },
    setRunningPipelines: (state, { payload }) => {
      state.runningPipelines = payload;
    },
    setNodeEdges: (state, { payload }) => {
      state.nodeEdges = payload;
    },
    setCurrentPipeLineEdges: (state, { payload }) => {
      state.currentPipeLineEdges = payload;
    },
  },
});

export const {
  addPipeline,
  deletePipeline,
  setActivePipeline,
  setExpandActivity,
  setPipeLineTasks,
  setPipeLineCount,
  getNodesData,
  setCurrentPipeLineData,
  setPipeLineConnection,
  setCurrentPipeLineTasks,
  validateData,
  getFocusErrorInput,
  setValidationErrors,
  setActiveContainer,
  setGlobalParameters,
  setSaveModal,
  setTasksOutput,
  setRunningPipelines,
  setNodeEdges,
  setCurrentPipeLineEdges,
} = pipeLinesSlice.actions;

export default pipeLinesSlice.reducer;
