import React from "react";
import { RiDraggable } from "react-icons/ri";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { LiaAngleRightSolid } from "react-icons/lia"; // Icon for closing
import { useDispatch, useSelector } from "react-redux";
import { getRightSidebar } from "../../features/commonProperties";

const RightSidebar = () => {
  const { rightBar } = useSelector((state) => state.commonProps);
  const dispatch = useDispatch();

  const items = [
    { id: 1, name: "LineageViewer", imagePath: "/images/sidebar/lineage.png" },
    { id: 2, name: "Variables", imagePath: "/images/sidebar/variable.png" },
    {
      id: 3,
      name: "DataScience",
      imagePath: "/images/sidebar/datascience.png",
    },
    { id: 4, name: "ModelTraining", imagePath: "/images/sidebar/training.png" },
  ];

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("item", JSON.stringify(item));
  };

  const toggleSidebar = () => {
    dispatch(getRightSidebar(!rightBar));
  };

  return (
    <div className="h-screen  flex bg-white">
      {/* Sidebar */}
      <div
        className={`fixed right-0 shadow-xl top-0 h-full w-1/2 md:w-1/4 lg:w-1/6 bg-white p-2 pt-10  transform transition-transform duration-300 ease-in-out ${
          rightBar
            ? "translate-x-0 border-l border-blue-500  "
            : "translate-x-full border-l-2"
        }`}
      >
        {/* Toggle Button */}
        <div
          className={`absolute top-8 left-[-1.3rem] mt-4 py-2 cursor-pointer right_toggle flex items-center justify-center`}
          onClick={toggleSidebar}
        >
          <div className="hover:scale-110">
            {rightBar ? (
              <LiaAngleRightSolid size={20} fill="white " />
            ) : (
              <LiaAngleLeftSolid size={20} fill="white" />
            )}
          </div>
        </div>

        {/* Sidebar Content */}
        {rightBar &&
          items.map((item) => (
            <div
              key={item.id}
              style={{ marginTop: "0.5rem" }}
              className="text-base px-2 py-1 border hover:scale-105 hover:text-white cursor-move flex justify-between items-center my-2 hover:bg-blue-600"
              draggable
              onDragStart={(e) => handleDragStart(e, item)}
            >
              <div className="flex items-center cursor-move property_labels">
                <img src={item?.imagePath} className="w-4 h-4 mr-2" alt="" />
                {item.name}
              </div>
              <RiDraggable />
            </div>
          ))}
      </div>
    </div>
  );
};

export default RightSidebar;
