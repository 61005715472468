import React, { useEffect, useState } from "react";
import { GrLinkNext, GrTrigger } from "react-icons/gr";
import { RiEdit2Fill } from "react-icons/ri";
import CronExpression from "./CronExpression";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
import { setTriggers } from "../../../features/triggers";

const Triggers = () => {
  const { currentPipeLineTasks, activePipeline } = useSelector(
    (state) => state.pipeLines
  );
  const { currentUrl } = useSelector((state) => state.commonProps);
  const dispatch = useDispatch();
  const { triggers } = useSelector((state) => state.triggers);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [triggersData, setTriggersData] = useState([]);
  const [editingTrigger, setEditingTrigger] = useState({});
  const [isUpdateing, setIsUpdateing] = useState(false);
  const [deletePopup, setDeletepopup] = useState({
    deleteStatus: false,
    deleteobject: {},
  });

  useEffect(() => {
    if (!activePipeline) return; // Prevent API call if activePipeline is null/undefined

    const data = { pipeline_id: activePipeline || currentPipeLineTasks?.id };
    const jobTriggers = triggers?.find(
      (trigger) => trigger?.pipeline_id === activePipeline
    );
    setTriggersData(jobTriggers?.triggers);

    async function getTrigger() {
      try {
        const response = await axios.post(
          `${currentUrl?.url}get_triggersByPipelineId.php`,
          data, // Pass data correctly as the second argument
          {
            headers: {
              "Content-Type": "application/json", // Corrected header key
            },
          }
        );
        setTriggersData(response.data.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    // getTrigger(); // Call the function
  }, [
    activePipeline,
    currentPipeLineTasks,
    isUpdateing,
    isPopupOpen,
    deletePopup?.deleteStatus,
  ]);

  const newTriggerHandler = () => {
    setIsPopupOpen(true);
    setEditingTrigger({});
  };
  const editHandler = (data) => {
    setIsPopupOpen(true);
    setEditingTrigger(data);
    setIsUpdateing(true);
  };

  const deleteHandler = (data) => {
    setDeletepopup({ deleteStatus: true, deleteobject: data });
  };
  const confirmDelete = async () => {
    const updatedTriggers = triggers
      .map((triggerGroup) => {
        if (triggerGroup.pipeline_id === activePipeline) {
          const filteredTriggers = triggerGroup.triggers.filter(
            (t) => t.u_id !== deletePopup?.deleteobject?.u_id
          ); // Remove trigger

          return filteredTriggers.length > 0
            ? { ...triggerGroup, triggers: filteredTriggers } // Update pipeline if triggers remain
            : null; // Remove pipeline if no triggers left
        }
        return triggerGroup;
      })
      .filter(Boolean);
    dispatch(setTriggers(updatedTriggers));
    setDeletepopup({ deleteStatus: false, deleteobject: {} });
    // const data = { u_id: deletePopup?.deleteobject?.u_id };
    // try {
    //   const response = await axios.post(
    //     `${currentUrl?.url}triggers_delete.php`,
    //     data
    //   );
    //   if (response?.data?.status_code === 2000) {
    //     setDeletepopup({ deleteStatus: false, deleteobject: {} });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <>
      {/* Triggers Button */}
      <button
        onClick={() => setIsOpen(true)}
        className="px-4 py-1 bg-white property_labels roun hover:text-white border hover:bg-blue-500 flex z-40 justify-center place-items-center text-blue-600"
      >
        <GrTrigger /> Triggers
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 h-screen w-11/12 md:w-1/2 lg:w-2/5 pt-10 z-[49] bg-white border-l-2 text-black shadow-lg transform transition-transform duration-400 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="w-full ">
          <h3 className="w-full border-b text-start p-1 mt-2 pl-4 text-black">
            Triggers
          </h3>
          <div className="w-full overflow-y-scroll h-[85vh] con_sidebar pb-10">
            <ul>
              {triggersData?.map((trigger, index) => (
                <li
                  key={index}
                  className="flex justify-between place-items-center border-b hover:bg-gray-100 w-full px-5 py-3 h-full"
                  // onClick={() => showErrorInput(error)}
                >
                  <div className="flex justify-start place-items-center">
                    <div className="flex justify-center place-items-center">
                      <input
                        type="radio"
                        className="mx-1 rounded-full"
                        name="tigger"
                      />
                      <img
                        src="/images/trigger.png"
                        className="w-7 h-7"
                        alt="Task Icon"
                      />
                    </div>
                    <div className="pl-3">
                      <h2 className="text-sm">{trigger.trigger_name}</h2>
                      <p className="text-sm text-[rgb(38,154,248)]">
                        {/* {trigger?.cron_expression}: {trigger?.cron_string} */}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center place-items-center gap-2">
                    <RiEdit2Fill
                      className="h-5 w-5 m-1 hover:bg-white text-blue-500"
                      onClick={() => editHandler(trigger)}
                    />
                    <AiOutlineDelete
                      className="h-5 w-5 m-1 hover:bg-white text-red-500"
                      onClick={() => deleteHandler(trigger)}
                    />
                  </div>
                </li>
              ))}
              <li className="p-2">
                <button
                  onClick={() => newTriggerHandler(true)}
                  className="w-full py-1 bg-[rgb(38,154,248)] rounded-sm shadow-md shadow-[rgb(150,224,253)] text-white "
                >
                  Add new Trigger
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="absolute bottom-0 w-full bg-white p-1 px-3">
          <button
            onClick={() => setIsOpen(false)}
            className="close_button hover:scale-105 text-white px-5 py-1"
          >
            <GrLinkNext />
          </button>
        </div>
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white  rounded-lg shadow-lg w-full">
              <h2 className="text-xl font-bold mb-4">Add New Trigger</h2>
              <CronExpression
                onClose={() => setIsPopupOpen(false)}
                trigger={editingTrigger}
                isUpdateing={isUpdateing}
                closeUpdateing={() => setIsUpdateing(false)}
              />
            </div>
          </div>
        )}
      </div>

      {deletePopup?.deleteStatus && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-30"></div>
          <div className="relative bg-white p-6  shadow-lg max-w-md w-full">
            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
              <ImCancelCircle />
            </button>
            <label htmlFor="">Are you sure want to delete? Trigger</label>

            <input
              type="text"
              value={deletePopup?.deleteobject?.trigger_name}
              disabled
              className="mt-1 p-2 w-full border border-black focus:outline-none "
            />
            <form className="mt-4 space-y-3">
              <div className="flex justify-end place-items-center mt-4 h-full gap-2">
                <button
                  type="button"
                  className="mx-2 px-4 border property_labels border-red-600 rounded-[3px] py-1 bg-red-600 text-white  "
                  onClick={confirmDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="rounded-[2px] mx-2 px-4 border property_labels py-1 border-black hover:border-[rgb(38,154,248)] hover:text-white bg-white text-black hover:bg-[rgb(38,154,248)]"
                  onClick={() =>
                    setDeletepopup({ deleteStatus: false, deleteobject: {} })
                  }
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Triggers;
