import { configureStore, combineReducers } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import commonPropTabSlice from "../features/commonProperties";
import jobDetailsSlice from "../features/generatedJSON";
import nodeDataSlice from "../features/nodesData";
import pipelinesSlice from "../features/PipeLines";
import connectionSlice from "../features/connections";
import jobFilterSlice from "../features/moniterFilters";
import userDataSlice from "../features/userData";
import ddlDataSlice from "../features/DDL";
import medallionSlice from "../features/medallionSlice";
import folderSlice from "../features/schemas";
import triggerSlice from "../features/triggers";
const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: [
    "commonProps",
    "jobData",
    "totalNodes",
    "pipeLines",
    "connections",
    "jobFilters",
    "userData",
    "ddlData",
    "medallion",
    "schemasData",
    "triggers",
  ],
};

const rootReducer = combineReducers({
  commonProps: commonPropTabSlice,
  jobData: jobDetailsSlice,
  totalNodes: nodeDataSlice,
  pipeLines: pipelinesSlice,
  connections: connectionSlice,
  jobFilters: jobFilterSlice,
  userData: userDataSlice,
  ddlData: ddlDataSlice,
  medallion: medallionSlice,
  schemasData: folderSlice,
  triggers: triggerSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
