import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedNodeId: "parent",
  tasksData: [],
  input_values: [],
  yamlData: [],
  renderInputValues: {},
  uniq_id: null,
  renderingyaml: [],
  validateJob: false,
  validationErrors: [],
  deleteNodeData: {},
  isDeleteModalaOpen: false,
};

const nodeDataSlice = createSlice({
  name: "nodesData",
  initialState,
  reducers: {
    getSelectedNodeId: (state, { payload }) => {
      state.selectedNodeId = payload;
    },
    getAllTask: (state, { payload }) => {
      state.tasksData = payload;
    },
    getInputValues: (state, { payload }) => {
      state.input_items = payload;
    },
    getYamlData: (state, { payload }) => {
      state.yamlData = payload;
    },
    getRenderInputValues: (state, { payload }) => {
      state.renderInputValues = payload;
    },
    getUniqid: (state, { payload }) => {
      state.uniq_id = payload;
    },
    getRenderingYAML: (state, { payload }) => {
      if (!Array.isArray(state.renderingyaml)) {
        state.renderingyaml = [];
      }

      const isExist = state.renderingyaml.some(
        (data) => String(data.custom_id) === String(payload.custom_id)
      );
      if (!isExist) {
        state.renderingyaml.push(payload);
      }
    },
    getValidateJob: (state, { payload }) => {
      state.validateJob = payload;
    },

    getdeleteNodeData: (state, { payload }) => {
      state.deleteNodeData = payload;
    },
    setdeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalaOpen = payload;
    },
  },
});

export const {
  getSelectedNodeId,
  getAllTask,
  getInputValues,
  getYamlData,
  getRenderInputValues,
  getUniqid,
  getRenderingYAML,
  getdeleteNodeData,
  setdeleteModalOpen,
  getValidateJob,
} = nodeDataSlice.actions;

export default nodeDataSlice.reducer;
