import React, { useState } from "react";
import { MdOutlineClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  filterJobs,
  setEndTime,
  setJobStatus,
  setPipelineName,
  setStartTime,
} from "../../../../features/moniterFilters";
const PipeLineFilters = () => {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { pipelineName, jobStatus, startTime, endTime } = useSelector(
    (state) => state.jobFilters
  );
  const handleFilterChange = (type, value) => {
    switch (type) {
      case "pipelineName":
        dispatch(setPipelineName(value));
        break;
      case "jobStatus":
        dispatch(setJobStatus(value));
        break;
      case "startTime":
        dispatch(setStartTime(value));
        break;
      case "endTime":
        dispatch(setEndTime(value));
        break;
      default:
        break;
    }

    // Apply filter after state update
    setTimeout(() => {
      dispatch(filterJobs());
    }, 100);
  };
  return (
    <div className="hidden md:flex w-full  bg-gray-100 justify-end place-items-center">
      <div>
        <div className="flex justify-between place-items-end space-x-2 w-full  px-3">
          <div className="flex justify-between place-items-center space-x-2">
            <div className="relative w-60">
              <label
                htmlFor="pipeline_name"
                className="px-2 grid text-center property_labels "
              >
                Pipeline Name
                <input
                  type="text"
                  placeholder="pipeline name"
                  value={pipelineName}
                  onChange={(e) =>
                    handleFilterChange("pipelineName", e.target.value)
                  }
                  className="w-full p-[3px] pl-3 pr-8 border  border-gray-600 rounded-full outline-none property_labels"
                />
              </label>
              {/* Clear (×) Icon */}
              {search && (
                <MdOutlineClear
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-red-500 cursor-pointer hover:text-gray-700"
                  onClick={() => setSearch("")}
                />
              )}
            </div>
            <div>
              <label
                htmlFor="status"
                className="px-2 grid text-center property_labels "
              >
                Status
                <select
                  name="status"
                  value={jobStatus}
                  onChange={(e) =>
                    handleFilterChange("jobStatus", e.target.value)
                  }
                  className="w-24 rounded-full text-[13px] p-[3px] outline-none border border-gray-600"
                  id=""
                >
                  <option value="all">All</option>
                  <option value="success">Success</option>
                  <option value="pending">Pending</option>
                  <option value="failure">Failure</option>
                </select>
              </label>
            </div>
            <div>
              <label
                htmlFor="start_data "
                className="px-2 grid text-center property_labels "
              >
                Start Date
                <input
                  type="datetime-local"
                  id="start_data"
                  name="start_date"
                  value={startTime}
                  onChange={(e) =>
                    handleFilterChange("startTime", e.target.value)
                  }
                  className="border border-gray-600 outline-none rounded-full property_labels p-[2px] px-3"
                />
              </label>
            </div>
            <div>
              <label
                htmlFor="end_data"
                className="px-2 grid text-center property_labels "
              >
                End Date
                <input
                  type="datetime-local"
                  id="end_date"
                  name="end_date"
                  value={endTime}
                  onChange={(e) =>
                    handleFilterChange("endTime", e.target.value)
                  }
                  className="border border-gray-600 outline-none rounded-full property_labels p-[2px] px-3"
                />
              </label>
            </div>
          </div>
          <div className="">
            <button
              onClick={() => dispatch(clearFilters())}
              className="bg-red-500 property_labels rounded-full text-white mb-1 p-1 px-2"
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipeLineFilters;
