import axios from 'axios';
import yaml from 'js-yaml';

export const fetchYaml = async (filePath) => {
  try {
    const response = await axios.get(filePath);
    const data = yaml.load(response.data);
    return data;
  } catch (error) {
    console.error('Error fetching YAML file:', error);
    return null;
  }
};
