import React from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import SQLEditor from "./components/SQLEditor";
import { useSelector } from "react-redux";

const DDL = () => {
  const { ddlData } = useSelector((state) => state.ddlData);
  return (
    <>
      <PanelGroup
        direction="vertical"
        id="group"
        className="border-t border-gray-300 flex-grow overflow-hidden"
      >
        <Panel id="left-panel">
          <SQLEditor />
        </Panel>
        <PanelResizeHandle id="resize-handle" />
        <Panel id="right-panel" maxSize={90} minSize={6} defaultSize={50}>
          <div
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "10px",
            }}
            className="con_sidebar border"
          >
            properties
            {/* <button onClick={() => console.log(ddlData)}>save</button> */}
          </div>
        </Panel>
      </PanelGroup>
    </>
  );
};

export default DDL;
