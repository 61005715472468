import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveContainer } from "../../../features/PipeLines";
import { TbDeviceHeartMonitorFilled } from "react-icons/tb";
import { CiMenuKebab } from "react-icons/ci";

const Moniter = () => {
  const dispatch = useDispatch();
  const { activeContainer } = useSelector((state) => state.pipeLines);
  return (
    <>
      <div className="cursor-pointer z-40 flex justify-between items-center hover:bg-gray-50 transition p-1 border-b hover:rounded">
        <div
          className="flex items-center w-full"
          onClick={() => dispatch(setActiveContainer("moniter"))}
        >
          <span>
            <CiMenuKebab className="text-white" />
          </span>{" "}
          <TbDeviceHeartMonitorFilled className="mx-1  text-[rgb(38,154,248)]" />{" "}
          <h2 className="text-md">Moniter</h2>
        </div>
        <div className="p-1 hover:bg-white relative"></div>
      </div>
    </>
  );
};

export default Moniter;
