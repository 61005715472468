import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserWantTo } from "../../../features/userData";
import axios from "axios";

const Registration = () => {
  const dispatch = useDispatch();
  const { currentUrl } = useSelector((state) => state.commonProps);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    password: "",
    confirm_password: "",
    address: "",
    city: "",
    state: "",
    country: "",
    organization_name: "",
    total_no_of_employees: "",
    organization_type: "",
    phone_number: "",
  });

  const [errors, setErrors] = useState({}); // State for storing validation errors

  const validateForm = () => {
    let newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key.replace(/_/g, " ")} is required`;
      }
    });
    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formFields = [
    { label: "First Name", name: "first_name" },
    { label: "Last Name", name: "last_name" },
    { label: "Email", name: "email_id", type: "email" },
    { label: "Password", name: "password", type: "password" },
    { label: "Confirm Password", name: "confirm_password", type: "password" },
    { label: "Address", name: "address" },
    { label: "City", name: "city" },
    { label: "State", name: "state" },
    { label: "Country", name: "country" },
    { label: "Organisation Name", name: "organization_name" },
    { label: "Organisation Type", name: "organization_type" },
    { label: "No of Employees", name: "total_no_of_employees" },
    { label: "Mobile No", name: "phone_number" },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error when user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${currentUrl?.url}etl_register.php`,
          formData,
          { headers: { "Content-Type": "application/json" } }
        );
        if (response) {
        }
      } catch (error) {
        console.lo(error);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/3 bg-gradient-to-b h-auto md:h-screen from-blue-600 to-blue-400 text-white flex flex-col justify-center items-center p-6 md:p-8">
        <h2 className="text-2xl font-bold">HAARG ETL</h2>
        <p className="mt-4 text-lg text-center">
          A few clicks away from creating your HAARG ETL
        </p>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-2/3 p-2 md:p-5 flex flex-col h-auto md:h-screen justify-center items-center">
        <div className="w-full max-w-3xl">
          <h2 className="text-2xl font-bold text-start">Register</h2>
          <p className="text-[15px] text-blue-500">
            Manage all your ETL efficiently
          </p>

          <form
            className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2"
            onSubmit={handleSubmit}
          >
            {formFields.map(({ label, name, type = "text" }) => (
              <div key={name} className="w-full">
                <label className="block font-medium capitalize property_labels text-start">
                  {label}
                </label>
                <input
                  type={type}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  className={`border px-2 py-1 outline-none w-full ${
                    errors[name] ? "border-red-500" : "focus:border-blue-500"
                  }`}
                />
              </div>
            ))}
            {/* <div>
              {errors.length >= 1 && (
                <p className="text-red-500 text-sm mt-1">feilds are required</p>
              )}
            </div> */}
            <div className="md:col-span-2 flex justify-start">
              <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded-sm hover:bg-blue-700"
              >
                Create Account
              </button>
            </div>
          </form>

          <p className="mt-4 text-gray-600 text-sm">
            Already have an account?{" "}
            <span
              onClick={() => dispatch(setUserWantTo("login"))}
              className="text-blue-600 cursor-pointer"
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registration;
