import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInputValues } from "../../../features/nodesData";
import { getShowPopup } from "../../../features/commonProperties";
import uuid from "react-uuid";
import {
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";

const UserProperties = () => {
  const [userPropertiesArray, setUserPropertiesArray] = useState([]);
  const [error, setError] = useState("");
  const { selectedNodeId } = useSelector((state) => state.totalNodes);
  const { currentPipeLineTasks, pipeLines } = useSelector(
    (state) => state?.pipeLines
  );
  const dispatch = useDispatch();

  // Initialize userPropertiesArray with input_values on component load
  useEffect(() => {
    const inputs = currentPipeLineTasks?.tasks?.find((task) => {
      return task?.u_id === selectedNodeId;
    });
    setUserPropertiesArray(inputs?.user_properties || []);
  }, [selectedNodeId, currentPipeLineTasks]);
  const handleAddRow = () => {
    setUserPropertiesArray((prevState) => [
      ...prevState,
      { name: "", value: "", id: uuid() },
    ]);
    setError("");
  };

  const handleChange = (index, field, value) => {
    setUserPropertiesArray((prevState) =>
      prevState.map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      )
    );
    setError("");
  };

  const handleDeleteSelectedRows = () => {
    // const updatedProperties = userPropertiesArray.filter(
    //   (row) => !row.isSelected
    // );
    // setUserPropertiesArray(updatedProperties);
    // dispatch(getInputValues(updatedProperties));
  };

  const handleSubmit = () => {
    const isValid = userPropertiesArray.every(
      (row) => row.name.trim() !== "" && row.value.trim() !== ""
    );

    if (!isValid) {
      setError("Please fill in all Name and Value fields before submitting.");
      return;
    }

    const updatedTasks = currentPipeLineTasks?.tasks?.map((task) => {
      if (task?.u_id === selectedNodeId) {
        return { ...task, user_properties: userPropertiesArray };
      } else {
        return task;
      }
    });
    dispatch(
      setCurrentPipeLineTasks({ ...currentPipeLineTasks, tasks: updatedTasks })
    );
    const updatesPipeLines = pipeLines?.map((pipeLine) => {
      if (pipeLine?.id === currentPipeLineTasks?.id) {
        return { ...pipeLine, tasks: updatedTasks };
      } else {
        return pipeLine;
      }
    });
    dispatch(setPipeLineTasks(updatesPipeLines));
    dispatch(getInputValues(userPropertiesArray));
    setError("");
    dispatch(getShowPopup(true));
    setTimeout(() => {
      dispatch(getShowPopup(false));
    }, 2000);
  };

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleAddRow}
          className="text-blue-500 flex items-center hover:underline property_labels"
        >
          <img
            src="/images/add.png"
            className="w-4 h-4 border border-blue-500 rounded mx-2"
            alt=""
          />
          New
        </button>

        {/* Show delete button only when at least one row is selected */}
        {/* {userPropertiesArray.some((row) => row.isSelected) && (
          <button
            onClick={handleDeleteSelectedRows}
            className="text-red-500 flex items-center hover:underline property_labels"
          >
            <img
              src="/images/delete.png"
              className="w-4 h-4 border border-red-500 rounded mx-2"
              alt=""
            />
            Delete
          </button>
        )} */}
      </div>

      {error && <div className="text-red-500">{error}</div>}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="space-y-4"
      >
        {userPropertiesArray?.length > 0 && (
          <table className="table-auto w-full">
            <thead>
              <tr className="border-b">
                <th className="p-1 w-1/12"></th>
                <th className="p-1 text-start property_labels">Name</th>
                <th className="p-1 text-start property_labels border-l">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {userPropertiesArray.map((row, index) => (
                <tr key={row.id} className="border-b">
                  <td className="p-2 text-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={row.isSelected || false}
                      onChange={(e) =>
                        handleChange(index, "isSelected", e.target.checked)
                      }
                    />
                  </td>
                  <td className="p-1">
                    <input
                      type="text"
                      placeholder="Name"
                      value={row.name}
                      onChange={(e) =>
                        handleChange(index, "name", e.target.value)
                      }
                      className="w-full p-1 border border-black input_values custom_input"
                    />
                  </td>
                  <td className="p-1 border-l">
                    <input
                      type="text"
                      placeholder="Value"
                      value={row.value}
                      onChange={(e) =>
                        handleChange(index, "value", e.target.value)
                      }
                      className="w-full p-1 border border-black input_values custom_input"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {userPropertiesArray.length > 0 && (
          <div className="flex justify-end">
            <button
              type="submit"
              className="relative text-blue-500 px-8 py-2 rounded-sm bg-white isolation-auto z-10 border-2 border-blue-500
              before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-right-full before:hover:right-0 before:rounded-lg hover:text-white before:bg-blue-500 before:-z-10  before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-500 property_labels"
            >
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserProperties;
