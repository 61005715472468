// import React, { useState } from "react";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import GitCredentialsPopup from "../../git";
// import { setSaveModal } from "../../../features/PipeLines";
// const SavePipeLine = () => {
//   const { pipeLines, nodesData } = useSelector((state) => state.pipeLines);
//   const { selectedBranch, userGitBranches, git_credentials } = useSelector(
//     (state) => state.userData
//   );
//   const dispatch = useDispatch();
//   const { ddlData } = useSelector((state) => state.ddlData);
//   const [isPopupOpen, setPopupOpen] = useState(false);
//   const { user_name, repo_name, token } = git_credentials;
//   const [folder, setFolder] = useState({
//     name: "my-folder",
//     files: [
//       {
//         name: "pipelineData.json",
//         content: pipeLines, // JSON object
//       },
//       {
//         name: "nodeData.json",
//         content: nodesData, // JSON object
//       },
//       {
//         name: "ddlData.json",
//         content: ddlData,
//       },
//     ],
//   });

//   const githubApi = axios.create({
//     baseURL: `https://api.github.com/repos/${user_name}/${repo_name}`,
//     headers: {
//       Authorization: `token ${token}`,
//       "Content-Type": "application/json",
//     },
//   });

//   // Function to check if a file exists and get its SHA
//   const getFileSHA = async (filePath) => {
//     try {
//       const response = await githubApi.get(
//         `/contents/${filePath}?ref=${selectedBranch}`
//       );
//       return response.data.sha; // Return SHA if file exists
//     } catch (error) {
//       if (error.response?.status === 404) {
//         return null; // File does not exist
//       }
//       console.error(
//         `Failed to check file SHA for ${filePath}:`,
//         error.response?.data || error.message
//       );
//       return null;
//     }
//   };

//   // Function to upload or update files on GitHub
//   const uploadFolderToGitHub = async (e) => {
//     e.preventDefault();
//     for (const file of folder.files) {
//       const filePath = `${folder.name}/${file.name}`;
//       const jsonData = JSON.stringify(file.content, null, 2);
//       const fileContent = btoa(unescape(encodeURIComponent(jsonData))); // Encode as Base64

//       const existingSHA = await getFileSHA(filePath); // Get SHA if the file exists

//       try {
//         await githubApi.put(`/contents/${filePath}`, {
//           message: existingSHA ? `Update ${file.name}` : `Add ${file.name}`,
//           content: fileContent,
//           branch: selectedBranch,
//           ...(existingSHA && { sha: existingSHA }), // Include SHA only if updating
//         });
//       } catch (error) {
//         console.error(
//           `Failed to upload ${file.name}:`,
//           error.response?.data || error.message
//         );
//         alert(`Failed to upload ${file.name}`);
//         return;
//       }
//     }
//     dispatch(setSaveModal(false));
//     alert("Folder uploaded successfully!");
//     // close();
//   };

//   return (
//     <>
//       <button
//         onClick={uploadFolderToGitHub}
//         className="bg-[rgb(38,154,248)] border border-[rgb(38,154,248)] hover:bg-blue-400 text-[13px] text-white py-1 px-2"
//       >
//         Save Changes
//       </button>

//       <GitCredentialsPopup
//         isOpen={isPopupOpen}
//         onClose={() => setPopupOpen(false)}
//       />
//     </>
//   );
// };

// export default SavePipeLine;

import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import GitCredentialsPopup from "../../git";
import { setSaveModal } from "../../../features/PipeLines";

const SavePipeLine = () => {
  const { pipeLines, nodesData } = useSelector((state) => state.pipeLines);
  const { selectedBranch, git_credentials } = useSelector(
    (state) => state.userData
  );
  const { triggers } = useSelector((state) => state.triggers);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const { ddlData } = useSelector((state) => state.ddlData);
  const { user_name, repo_name, token } = git_credentials;

  const [selectedFile, setSelectedFile] = useState("pipelineData.json"); // Default file selection

  const folder = {
    name: "my-folder",
    files: {
      "pipelineData.json": pipeLines,
      "nodeData.json": nodesData,
      "ddlData.json": ddlData,
      "triggers.json": triggers,
    },
  };

  const githubApi = axios.create({
    baseURL: `https://api.github.com/repos/${user_name}/${repo_name}`,
    headers: {
      Authorization: `token ${token}`,
      "Content-Type": "application/json",
    },
  });

  // Function to check if a file exists and get its SHA
  const getFileSHA = async (filePath) => {
    try {
      const response = await githubApi.get(
        `/contents/${filePath}?ref=${selectedBranch}`
      );
      return response.data.sha; // Return SHA if file exists
    } catch (error) {
      if (error.response?.status === 404) {
        return null; // File does not exist
      }
      console.error(`Failed to check file SHA for ${filePath}:`, error);
      return null;
    }
  };

  // Function to upload or update a single file on GitHub
  const uploadFileToGitHub = async (e) => {
    e.preventDefault();
    const filePath = `${folder.name}/${selectedFile}`;
    const fileContent = JSON.stringify(folder.files[selectedFile], null, 2);
    const base64Content = btoa(unescape(encodeURIComponent(fileContent))); // Encode as Base64

    const existingSHA = await getFileSHA(filePath);

    try {
      const response = await githubApi.put(`/contents/${filePath}`, {
        message: existingSHA ? `Update ${selectedFile}` : `Add ${selectedFile}`,
        content: base64Content,
        branch: selectedBranch,
        ...(existingSHA && { sha: existingSHA }), // Include SHA only if updating
      });
      console.log(response);
      alert(`${selectedFile} uploaded successfully!`);
    } catch (error) {
      console.error(`Failed to upload ${selectedFile}:`, error);
      alert(`Failed to upload ${selectedFile}`);
    }
  };

  return (
    <>
      <div className="flex gap-2 items-center">
        <select
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.value)}
          className="border p-1"
        >
          {Object.keys(folder.files).map((file) => (
            <option key={file} value={file}>
              {file}
            </option>
          ))}
        </select>

        <button
          onClick={uploadFileToGitHub}
          className="bg-[rgb(38,154,248)] border border-[rgb(38,154,248)] hover:bg-blue-400 text-[13px] text-white py-1 px-2"
        >
          Save
        </button>
      </div>

      <GitCredentialsPopup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
      />
    </>
  );
};

export default SavePipeLine;
