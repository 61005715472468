import React from "react";

const ActivityName = () => {
  return (
    <div className="flex flex-col items-center text-white h-full">
      {"Activities".split("").map((letter, index) => (
        <span key={index} className="block text-lg font-bold">
          {letter}
        </span>
      ))}
    </div>
  );
};

export default ActivityName;
