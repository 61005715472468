import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-monokai";
import { updateFileSchema } from "../../../../features/schemas";

const SchemaEditor = ({ fileId }) => {
  const dispatch = useDispatch();
  const { activeSchemaFile } = useSelector((state) => state.schemasData);
  const [content, setContent] = useState(activeSchemaFile?.schema || "");
  const [format, setFormat] = useState("json");
  useEffect(() => {
    const getFileFormat = () => {
      if (activeSchemaFile?.name) {
        const extension = activeSchemaFile?.name
          ?.split(".")
          .pop()
          .toLowerCase();

        switch (extension) {
          case "json":
            return setFormat("json");
          case "txt":
            return setFormat("txt");
          case "csv":
            return setFormat("csv");
          case "xml":
            return setFormat("xml");
          case "yml":
            return setFormat("yml");
          default:
            return setFormat("json");
        }
      }
    };
    getFileFormat();
  }, [activeSchemaFile]);

  const handleEditorChange = (value) => {
    setContent(value);
    dispatch(
      updateFileSchema({
        id: activeSchemaFile.id,
        data: value,
        isFolder: false,
      })
    );
  };
  useEffect(() => {
    setContent(activeSchemaFile?.schema || "");
  }, [activeSchemaFile]);
  return (
    <div className="w-full bg-gray-900 text-white shadow-lg">
      <div className=" w-full  h-full overflow-hidden">
        <div className="flex  justify-end items-center">
          <div className="p-1 pr-5">
            <label className="text-lg px-3">File Name:</label>
            <input
              type="text"
              className="px-3 py-1 border rounded"
              disabled
              value={activeSchemaFile?.name}
            />
          </div>
        </div>
        <AceEditor
          mode={format === "yaml" ? "yaml" : "json"}
          theme="monokai"
          onChange={handleEditorChange} // Updates Redux immediately
          value={content}
          editorProps={{ $blockScrolling: true }}
          style={{ width: "100%", height: "100%" }}
          setOptions={{
            fontSize: 14,
            fontFamily: "monospace",
            tabSize: 2,
            useSoftTabs: true,
            showPrintMargin: false,
            wrap: true,
            highlightActiveLine: true,
          }}
        />
      </div>
    </div>
  );
};

export default SchemaEditor;
