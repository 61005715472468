import React, { useContext, useState } from "react";
import { YamlContext } from "../YamlProvider";
import withEventTracking from "../../hoc/withEventTracking";
import { eventLogger } from "../../utils/eventLogger";
import yaml from "js-yaml";
import { VscDebugStart } from "react-icons/vsc";
import RunnerInput from "../RunnerInput";
import { useSelector } from "react-redux";
import { validateData } from "../../features/PipeLines";

const YamlGenerator = ({
  canvasItems,
  logCustomEvent,
  projectName,
  runnerName,
}) => {
  const { setYamlData } = useContext(YamlContext);
  const [connections, setConnections] = useState([]);
  const { yamlData } = useSelector((state) => state.totalNodes);
  const { pipeLines, currentPipeLineTasks, validationErrors } = useSelector(
    (state) => state?.pipeLines
  );
  const { currentUrl } = useSelector((state) => state.commonProps);
  const fetchConnectionData = async (connType) => {
    try {
      const response = await fetch(
        `${currentUrl?.url}connections_readByConnType.php?conn_type=${connType}`
      );
      const text = await response.text();

      const cleanText = text.replace(/^.*?\{/, "{");
      const jsonData = JSON.parse(cleanText);

      if (jsonData.status_code === 2000 && jsonData.data) {
        return jsonData.data.map((item) => {
          const connData = JSON.parse(item.conn_data);
          return {
            conn_id: item.conn_id,
            name: connData.connection_name,
            input_items: [
              { url: connData.jdbc_url || "" },
              { username: connData.username || "" },
              { password: connData.password || "" },
              { database: connData.value || "" },
            ],
          };
        });
      }
    } catch (error) {
      console.error("Error fetching connection data:", error);
    }
    return [];
  };

  const handleClick = async () => {
    const events = eventLogger.getEvents();
    logCustomEvent("generateYAML", { canvasItems, events });

    const jdbcConnections = await fetchConnectionData("JDBC");

    setConnections(jdbcConnections);

    const itemsData = canvasItems.map((item) => {
      const uniqueKey = `propertiesData-${item.uniqueId || item.id}`;
      const storedData = sessionStorage.getItem(uniqueKey);
      const parsedData = storedData ? JSON.parse(storedData) : null;

      let inputItems = [];
      if (parsedData) {
        switch (item.name) {
          case "ReadJDBC":
            inputItems.push({
              conn_id: parsedData["connection id"] || "",
              tbname: parsedData["table name"] || "",
              query: parsedData["query"] || "",
            });
            break;

          case "Union":
            inputItems.push({
              left_table: parsedData["left_table"] || "",
              right_table: parsedData["right_table"] || "",
              union_type: parsedData["union"] || "",
            });
            break;

          case "WriteJDBC":
            inputItems.push({
              conn_id: parsedData["connection id"] || "",
              tbname: parsedData["table name"] || "",
            });
            break;

          default:
            break;
        }
      }

      const { uniqueId, id, dependency, ...restProperties } = parsedData || {};
      const cleanUId = uniqueId ? uniqueId.replace("undefined-", "") : item.id;

      return {
        ...item,
        u_id: cleanUId,
        dependency: dependency || [1, 2],
        input_items: inputItems,
      };
    });

    const yamlContent = yaml.dump({
      job_name: projectName,
      run_type: runnerName,
      connections: jdbcConnections, // Only JDBC connections
      tasks: itemsData,
    });

    setYamlData(yamlContent);

    const blob = new Blob([yamlContent], { type: "text/yaml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.yaml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    alert("YAML generated and events stored.");
  };

  const RunPipeLine = () => {
    // validateData();
    // if (validationErrors?.lenght === 0) {
    //   console.log(validationErrors, "running Job");
    // } else {
    //   console.log(validationErrors, "validation ERRORS");
    // }
  };

  return (
    <>
      <div className=" flex justify-end place-items-center pr-5 rounded-sm">
        <div className="px-4">
          <RunnerInput />
        </div>
        <button
          onClick={RunPipeLine}
          className="flex justify-start place-items-center border py-1 pr-1 rounded run_btn text-white"
        >
          <VscDebugStart className="run_icon" />
          <span className=""> Run Job</span>
        </button>
      </div>
    </>
  );
};

export default withEventTracking(YamlGenerator);
