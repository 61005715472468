import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Activities from "../components/Activity/Activities";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePipeline,
  setActivePipeline,
  setPipeLineCount,
  setSaveModal,
} from "../features/PipeLines";
import { getSelectedNodeId } from "../features/nodesData";
import DeleteModal from "../components/pipelines/components/DeletepipeLine";

const Tabs = ({ logCustomEvent }) => {
  const { pipeLines, pipeLineCount, activePipeline, saveModal } = useSelector(
    (state) => state.pipeLines
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();
  const [currentPipeLines, setCurrentPipeLines] = useState([]);
  const [deletePipeLine, setDeletePipeLine] = useState(null);

  useEffect(() => {
    const newPipeLines = pipeLines.map((single) => {
      return {
        ...single,
        content: <Activities logCustomEvent={logCustomEvent} />,
      };
    });
    setCurrentPipeLines(newPipeLines);
  }, [pipeLines, pipeLineCount, activePipeline]);
  useEffect(() => {
    const handleButtonClick = () => {
      const input = document.getElementById(String(activePipeline));
      if (input) {
        input.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
    handleButtonClick();
  }, [activePipeline]);
  const handleWheel = (event) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += event.deltaY; // Scroll horizontally
    }
  };
  const handleDeletePipelline = (pipeLine) => {
    setDeletePipeLine(pipeLine);
    dispatch(setSaveModal(true));
  };
  return (
    <div className="h-full w-full grid grid-rows-[auto_1fr]" key={"outerDiv"}>
      <div
        className="bg-white flex justify-start place-items-start border-b overflow-auto hide_scrollbar"
        ref={scrollContainerRef}
        onWheel={handleWheel} // Attach the wheel event
      >
        {currentPipeLines.map((tab) => (
          <>
            <div
              key={tab.id}
              id={tab.id}
              className={`py-2 px-2 cursor-pointer border-r border-gray-300  ${
                activePipeline === tab.id
                  ? "bg-white border-b-none  border-t "
                  : "bg-gray-100  border-b "
              } flex items-center`}
              onClick={() => {
                dispatch(setActivePipeline(tab.id));
                dispatch(getSelectedNodeId("parent"));
              }}
            >
              <p className="px-2 text-sm w-40">{tab.job_name}</p>
              <AiOutlineClose
                className=" text-red-500 cursor-pointer hover:border"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeletePipelline(tab);
                }}
              />
            </div>
          </>
        ))}
      </div>
      <div className=" overflow-auto" key={"innerDiv"}>
        {currentPipeLines.map((tab) =>
          activePipeline === tab.id ? <>{tab.content}</> : null
        )}
      </div>
      {saveModal && <DeleteModal pipeLineName={deletePipeLine} />}
    </div>
  );
};

export default Tabs;
