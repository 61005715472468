import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedBarnch,
  setUserGitBranches,
} from "../../../features/userData";
import axios from "axios";
import {
  getNodesData,
  setActivePipeline,
  setPipeLineTasks,
} from "../../../features/PipeLines";
import { getSelectedNodeId } from "../../../features/nodesData";
import { getCurrentTab } from "../../../features/commonProperties";
import { setddlData } from "../../../features/DDL";
const GitBranches = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { pipeLines, nodesData } = useSelector((state) => state.pipeLines);
  const { selectedBranch, userGitBranches, git_credentials } = useSelector(
    (state) => state.userData
  );
  const { user_name, repo_name, token } = git_credentials;

  const handleSelect = (option) => {
    dispatch(setSelectedBarnch(option));
    setIsOpen(false);
  };
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/repos/${user_name}/${repo_name}/branches`,
          {
            headers: {
              // Replace with your token if necessary
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        dispatch(setUserGitBranches(data));
      } catch (err) {
        console.log(err);
      }
    };

    fetchBranches();
  }, [git_credentials]);

  useEffect(() => {
    const githubApi = axios.create({
      baseURL: `https://api.github.com/repos/${user_name}/${repo_name}/contents`,
      headers: {
        Authorization: `token ${token}`,
      },
    });

    async function getSavedPipeLines() {
      try {
        // Fetch file list
        const response = await githubApi.get(
          `/my-folder?ref=${selectedBranch}`
        );
        const fileList = response.data; // Array of files

        if (!Array.isArray(fileList)) {
          throw new Error("Invalid response format");
        }

        // Fetch file contents and assign them to variables
        for (const file of fileList) {
          if (file.download_url) {
            const fileResponse = await axios.get(file.download_url);
            if (file.name === "nodeData.json") {
              dispatch(getNodesData(fileResponse.data));
            } else if (file.name === "pipelineData.json") {
              dispatch(setActivePipeline(fileResponse?.data[0]?.id));
              dispatch(setPipeLineTasks(fileResponse.data));
              dispatch(getSelectedNodeId("parent"));
              dispatch(getCurrentTab("parameters"));
            } else if (file.name === "ddlData.json") {
              dispatch(setddlData(fileResponse.data));
            }
          }
        }
      } catch (error) {
        console.error(
          "Error fetching folder contents:",
          error.response?.data || error.message
        );
        // setError("Failed to fetch folder contents.");
      }
    }

    // getSavedPipeLines();
  }, [selectedBranch]);

  return (
    <div className="relative  w-[100%]">
      {/* Testing Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className=" px-4 py-1 font-medium rounded-sm border  flex justify-between items-center"
      >
        {selectedBranch}
        <span className="">&#9662;</span>
      </button>

      {/* Testing Menu */}
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white z-50 border rounded-sm shadow-lg">
          {userGitBranches?.map((option, index) => (
            <div
              key={index}
              onClick={() => handleSelect(option.name)}
              className="px-4 py-1 cursor-pointer hover:bg-blue-100"
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GitBranches;
