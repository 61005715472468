import React, { useState } from "react";
import { IoCodeSlash } from "react-icons/io5";

import { IoMdCopy } from "react-icons/io";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentTab,
  getSourceCodeOpen,
  getSourdeObject,
} from "../../../features/commonProperties";
import { Handle } from "react-flow-renderer";
import { current } from "@reduxjs/toolkit";
import uuid from "react-uuid";
import {
  getNodesData,
  setCurrentPipeLineData,
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";
import {
  getdeleteNodeData,
  getRenderingYAML,
  getSelectedNodeId,
  setdeleteModalOpen,
} from "../../../features/nodesData";
import yaml from "js-yaml";
import DeleteNodeModal from "../../Activity/components/DeleteNodeModal";

const GeneratedNodes = ({ data }) => {
  const dispatch = useDispatch();
  const [yamlData, setYamlData] = useState(null);
  const { selectedNodeId } = useSelector((state) => state.totalNodes);
  const { pipeLines, currentPiplineData, currentPipeLineTasks, nodesData } =
    useSelector((state) => state.pipeLines);

  const sourceCodeHandler = (sourceObject) => {
    const sourceCode = currentPipeLineTasks?.tasks?.find(
      (task) => task?.u_id === sourceObject?.id
    );
    dispatch(getSourdeObject(sourceCode));
    dispatch(getSourceCodeOpen(true));
  };

  const copyNode = async (data) => {
    const u_id = uuid();
    const unqId = uuid();

    try {
      const response = await fetch(data.path);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const yamlText = await response.text();
      const newdata = yaml.load(yamlText);
      dispatch(getRenderingYAML({ ...newdata, custom_id: unqId }));
    } catch (error) {
      console.log(error);
    }

    const copyNode = currentPiplineData?.job_nodes?.find(
      (node) => node?.uniqueId === data?.id
    );
    const copyTask = currentPipeLineTasks?.tasks?.find(
      (task) => task?.u_id === data?.id
    );

    const newNode = {
      ...copyNode,
      name: copyNode?.name + " copy",
      u_id: u_id,
      uniqueId: unqId,
      x: copyNode?.x + 160,
      y: copyNode?.y + 80,
    };
    const newTask = {
      ...copyTask,
      name: copyTask?.name + " copy",
      u_id: unqId,
    };

    const tasks = {
      ...currentPipeLineTasks,
      tasks: [...currentPipeLineTasks?.tasks, newTask],
    };
    const nodes = {
      ...currentPiplineData,
      job_nodes: [...currentPiplineData?.job_nodes, newNode],
    };

    const addedNode = nodesData?.map((pipe) => {
      if (pipe?.id === currentPiplineData?.id) {
        return { ...pipe, job_nodes: nodes?.job_nodes };
      } else {
        return pipe;
      }
    });
    const addedTask = pipeLines?.map((pipe) => {
      if (pipe?.id === currentPipeLineTasks?.id) {
        return { ...pipe, tasks: tasks?.tasks };
      } else {
        return pipe;
      }
    });

    dispatch(setPipeLineTasks(addedTask));
    dispatch(getNodesData(addedNode));
    setTimeout(() => {
      dispatch(getSelectedNodeId(newNode?.uniqueId));
    }, 100);
  };

  const deleteNodeHandler = (node) => {
    dispatch(setdeleteModalOpen(true));
    dispatch(getdeleteNodeData(node));
  };

  const skipTasks = (id, value) => {
    const skippedtasks = currentPipeLineTasks?.tasks?.map((task) => {
      if (task?.u_id === id) {
        return { ...task, activity_state: value };
      } else {
        return task;
      }
    });

    const skippedNodes = currentPiplineData.job_nodes?.map((node) => {
      if (node?.uniqueId === id) {
        return { ...node, activity_state: value };
      } else {
        return node;
      }
    });

    const updatedNode = { ...currentPiplineData, job_nodes: skippedNodes };

    const updatedNodesData = nodesData?.map((node) => {
      if (node?.id === currentPiplineData?.id) {
        return updatedNode;
      } else return node;
    });
    const updatedTasks = { ...currentPipeLineTasks, tasks: skippedtasks };
    const updatedPipeline = pipeLines.map((pipeline) => {
      if (pipeline?.id === currentPipeLineTasks?.id) {
        return updatedTasks;
      } else return pipeline;
    });

    dispatch(setCurrentPipeLineTasks(updatedTasks));
    dispatch(setPipeLineTasks(updatedPipeline));
    dispatch(setCurrentPipeLineData(updatedNode));
    dispatch(getNodesData(updatedNodesData));
  };
  return (
    <>
      <div
        className={`${
          data.activity_state ? "opacity-70" : ""
        } custom-node rounded-none shadow-md hover:bg-red-200 z-[49]  border flex place-items-between text-center ${
          selectedNodeId === data.id ? "#e3f2fa" : "bg-white"
        }`}
        style={{
          minHeight: "60px",
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
          justifyContent: "center",
          border:
            selectedNodeId === data.id
              ? "2px solid #004edf"
              : "2px solid black",
          backgroundColor: selectedNodeId === data.id ? "#e3f2fa" : "white",
        }}
      >
        <div className="flex justify-center place-items-between ">
          <img
            src={data.image}
            alt={data.label}
            className="w-5 h-5 mx-1"
            style={{
              objectFit: "cover",
            }}
          />
          <span className="text-sm font-semibold">{data.label}</span>
        </div>
        <div
          className={`w-full ${
            selectedNodeId === data.id ? "flex" : "hidden"
          } justify-center mt-4 place-items-center h-1/2`}
        >
          {data?.activity_state === true && (
            <div className="border bg-white absolute rounded-sm top-6 z-50 px-2 py-1 opacity-100 ">
              Task Skipped
            </div>
          )}
          <div
            className="absolute flex justify-between space-x-2  w-full px-2"
            style={{
              bottom: "-20px", // Moves below the node
              right: "-0px", // Adjusts to the right
            }}
          >
            {data?.activity_state === true && (
              <div className="node_tooltip ">
                <AiOutlineCheck
                  onClick={() => skipTasks(data?.id, !data?.activity_state)}
                  className="mx-2 border-2 text-green-400 border-blue-600   rounded-b-md border-t-0 node_icons cursor-pointer"
                />
                <span className="node_icon_titles"> Add Task</span>
              </div>
            )}
            {data?.activity_state === false && (
              <div className="node_tooltip ">
                <AiOutlineClose
                  onClick={() => skipTasks(data.id, !data?.activity_state)}
                  className="mx-2 border-2 text-red-500 border-blue-600  rounded-b-md z-50  border-t-0 node_icons cursor-pointer"
                />
                <span className="node_icon_titles"> Skip Task</span>
              </div>
            )}
          </div>
          <div className="node_tooltip">
            <IoCodeSlash
              className="mx-2 node_icons cursor-pointer"
              onClick={() => sourceCodeHandler(data)}
            />
            <span className="node_icon_titles"> Source Code.</span>
          </div>
          <div className="node_tooltip">
            <IoMdCopy
              className="mx-2 node_icons cursor-pointer"
              onClick={() => copyNode(data)}
            />
            <span className="node_icon_titles">clone</span>
          </div>
          <div className="node_tooltip">
            <AiOutlineDelete
              className="mx-2 node_icons cursor-pointer"
              onClick={() => deleteNodeHandler(data)}
            />
            <span className="node_icon_titles">Delete</span>
          </div>
        </div>
        <Handle
          type="target"
          position="top"
          style={{ background: "#555" }}
          className="w-2 h-2 "
        />
        <Handle
          type="source"
          position="bottom"
          style={{ background: "#555" }}
          className="w-2 h-2 "
        />
      </div>
    </>
  );
};

export default GeneratedNodes;
