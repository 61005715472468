import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { RiDeleteBin3Line } from "react-icons/ri";
import { setGlobalParameters } from "../../../../features/PipeLines";

const GlobalCollectors = () => {
  const [variablesArray, setVariablesArray] = useState([]);
  const [error, setError] = useState("");

  const { global_parameters } = useSelector((state) => state?.pipeLines);
  const dispatch = useDispatch();

  useEffect(() => {
    setVariablesArray(global_parameters);
  }, [global_parameters]);

  const handleAddRow = () => {
    setVariablesArray([
      ...variablesArray,
      { id: uuid(), name: "", value: "", type: "int" },
    ]);
  };

  const handleDeleteRow = (id) => {
    setVariablesArray((prevState) =>
      prevState.filter((node) => node.id !== id)
    );
  };

  const handleChange = (index, field, value) => {
    setVariablesArray((prevState) =>
      prevState.map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      )
    );
  };
  const handleSave = (e) => {
    e.preventDefault();
    dispatch(setGlobalParameters(variablesArray));
  };

  return (
    <div className="p-4 space-y-4">
      <div>
        <h1 className="text-2xl pb-1 border-b-2 border-gray-400">
          Global Parameters
        </h1>
      </div>
      <button
        onClick={handleAddRow}
        className="text-blue-500  flex place-items-center hover:underline property_labels"
      >
        <img
          src="/images/add.png"
          className="w-4 h-4 border border-blue-500 rounded mx-2 "
          alt=""
        />{" "}
        New
      </button>
      {error && <div className="text-red-500">{error}</div>}
      <form className="space-y-4" onSubmit={handleSave}>
        {variablesArray?.length > 0 ? (
          <table className="table-auto w-full border-collapse border-l">
            <thead>
              <tr className="border-b">
                <th className="text-start p-1 property_labels border-r">
                  Name
                </th>
                <th className="text-start p-1 property_labels border-r">
                  Default Value
                </th>
                <th className="text-start p-1 property_labels border-r">
                  Type
                </th>
              </tr>
            </thead>
            <tbody className="">
              {variablesArray.map((row, index) => (
                <tr key={index} className="border-b">
                  <td className=" p-1 border-r">
                    <input
                      type="text"
                      value={row.name}
                      onChange={(e) =>
                        handleChange(index, "name", e.target.value)
                      }
                      className="w-full p-1 border border-black outline-none property_labels "
                    />
                  </td>
                  <td className=" p-1 border-r">
                    <input
                      type="text"
                      value={row.value}
                      onChange={(e) =>
                        handleChange(index, "value", e.target.value)
                      }
                      className="w-full p-1 border border-black outline-none property_labels"
                    />
                  </td>
                  <td className=" p-1 border-r">
                    <select
                      value={row.type}
                      onChange={(e) =>
                        handleChange(index, "type", e.target.value)
                      }
                      className="w-full p-1 border border-black outline-none property_labels"
                    >
                      <option value="int">int</option>
                      <option value="float">float</option>
                      <option value="bool">bool</option>
                      <option value="string">string</option>
                      <option value="array">array</option>
                      <option value="object">object</option>
                      <option value="secureString">secureString</option>
                    </select>
                  </td>
                  <td className="text-center">
                    <RiDeleteBin3Line
                      onClick={() => handleDeleteRow(row?.id)}
                      className="text-blue-500 w-6 h-6"
                    />
                  </td>
                  {/* <td className=" p-1 text-center">
                        <button
                          type="button"
                          onClick={() => handleDeleteRow(index)}
                          className="text-red-500"
                        >
                          Delete
                        </button>
                      </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        <div className="flex justify-end space-x-4 ">
          {variablesArray?.length >= 1 && (
            <button
              type="submit"
              className="relative text-blue-500 px-8 py-2 rounded-sm bg-white isolation-auto z-10 border-2 border-blue-500
              before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-right-full before:hover:right-0 before:rounded-lg hover:text-white before:bg-blue-500 before:-z-10  before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-500 property_labels"
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default GlobalCollectors;
