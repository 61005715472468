import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFolder, FaFolderOpen, FaFile, FaDatabase } from "react-icons/fa";
import FolderList from "./FolderList";
import { FaPlay } from "react-icons/fa";

import {
  addItem,
  flattenDDLTree,
  renameItem,
  setCurrentDataSet,
  setCurrentSQLquerry,
  toggleFolder,
} from "../../../../features/DDL";
import { GiDatabase } from "react-icons/gi";
import { BsDatabase } from "react-icons/bs";
import { setActiveContainer } from "../../../../features/PipeLines";
import axios from "axios";
const FolderItem = ({ item, openMenuId, setOpenMenuId }) => {
  const { flattenedData, ddlData, currentDataset } = useSelector(
    (state) => state.ddlData
  );
  const dispatch = useDispatch();
  const [isRenaming, setIsRenaming] = useState(false);
  const [newName, setNewName] = useState(item?.displayName);
  const menuRef = useRef(null); // Reference for detecting clicks outside
  const inputRef = useRef(null); // Reference for the input field
  useEffect(() => {
    if (isRenaming && inputRef.current) {
      inputRef.current.select(); // Auto-select input text when renaming starts
    }
  }, [isRenaming]);
  const handleRename = () => {
    if (newName.trim()) {
      dispatch(renameItem({ itemId: item?.id, newName }));
      setIsRenaming(false);
    }
  };

  const isFolderLike = item?.type === "folder" || item?.type === "dataset";
  const isMenuOpen = openMenuId === item?.id;

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenuId(null); // Close menu if click is outside
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleSQLeditor = (item) => {
    if (item?.type === "dataset") {
      dispatch(setActiveContainer("dataset"));
      dispatch(setCurrentDataSet(item));
    }
    if (item?.type === "file") {
      dispatch(setActiveContainer("file"));
      dispatch(setCurrentSQLquerry(item));
    }
  };

  useEffect(() => {
    console.log(flattenedData, ddlData);
    const handleDDLSubmit = async () => {
      // try {
      //   const response = await axios.post(
      //     "http://192.168.31.159:5000/spark/submit-job",
      //     flattenedData,
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   );
      //   if (response) {
      //     console.log("Response:", response.data);
      //   }
      // } catch (error) {
      //   console.error(
      //     "API Error:",
      //     error.response ? error.response.data : error
      //   );
      // }
    };
    if (flattenedData?.id === currentDataset?.id) {
      handleDDLSubmit();
    }
  }, [flattenedData]);

  return (
    <li className="relative">
      <div
        className="flex items-center text-black justify-between px-2 py-1  border-b text-[14px] cursor-pointer"
        onClick={() =>
          isFolderLike && dispatch(toggleFolder({ folderId: item.id }))
        }
      >
        <div className="flex items-center space-x-2">
          {/* Folder / Dataset Icon Logic */}
          {isFolderLike ? (
            item?.type === "folder" ? (
              item?.open ? (
                <FaFolderOpen className="text-yellow-400" />
              ) : (
                <FaFolder className="text-yellow-400" />
              )
            ) : item?.open ? (
              <BsDatabase className="text-blue-300" />
            ) : (
              <GiDatabase className="text-blue-300" />
            )
          ) : (
            <FaFile className="text-green-400" />
          )}

          {isRenaming ? (
            <input
              ref={inputRef} // Attach ref to input field
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onBlur={handleRename}
              onKeyDown={(e) => e.key === "Enter" && handleRename()}
              className="px-1 border border-gray-600 rounded"
              autoFocus
            />
          ) : (
            <span className="text-black" onClick={() => handleSQLeditor(item)}>
              {item?.displayName}
            </span>
          )}
        </div>

        <div className="flex justify-between place-items-center">
          {item.type === "dataset" && (
            <button
              className="text-gray-400 hover:text-gray-200 px-2"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentDataSet(item);
                dispatch(flattenDDLTree({ itemId: item.id }));
              }}
            >
              <FaPlay className="text-blue-600" />
            </button>
          )}
          <button
            className="text-gray-400 hover:text-gray-200"
            onClick={(e) => {
              e.stopPropagation();
              setOpenMenuId(isMenuOpen ? null : item?.id); // Toggle menu visibility
            }}
          >
            <BsThreeDotsVertical />
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef} // Attach ref for outside click detection
          className="absolute right-0 top-0 bg-white text-black border shadow-md rounded-md w-40 z-50"
        >
          {item?.type === "folder" && (
            <>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  dispatch(addItem({ parentId: item?.id, type: "folder" }));
                  dispatch(toggleFolder({ folderId: item?.id })); // Ensure parent opens
                  setOpenMenuId(null);
                }}
              >
                📂 New Folder
              </button>

              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  dispatch(addItem({ parentId: item?.id, type: "dataset" }));
                  dispatch(toggleFolder({ folderId: item?.id }));
                  dispatch(setActiveContainer("dataset")); // Ensure parent opens
                  setOpenMenuId(null);
                }}
              >
                📊 New Dataset
              </button>

              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  dispatch(
                    addItem({
                      parentId: item?.id,
                      type: "file",
                      displayName: "NewFile.sql",
                    })
                  );
                  dispatch(toggleFolder({ folderId: item?.id })); // Ensure parent opens
                  setOpenMenuId(null);
                }}
              >
                📄 New DDL
              </button>

              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  setIsRenaming(true);
                  setOpenMenuId(null);
                }}
              >
                ✏️ Rename
              </button>
            </>
          )}

          {item?.type === "dataset" && (
            <>
              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  dispatch(addItem({ parentId: item?.id, type: "folder" }));
                  dispatch(toggleFolder({ folderId: item?.id })); // Ensure parent opens
                  setOpenMenuId(null);
                }}
              >
                📂 New Folder
              </button>

              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  dispatch(
                    addItem({
                      parentId: item?.id,
                      type: "file",
                      displayName: "NewFile.sql",
                    })
                  );
                  dispatch(toggleFolder({ folderId: item?.id })); // Ensure parent opens
                  setOpenMenuId(null);
                }}
              >
                📄 New DDL
              </button>

              <button
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                onClick={() => {
                  setIsRenaming(true);
                  setOpenMenuId(null);
                }}
              >
                ✏️ Rename
              </button>
            </>
          )}

          {item?.type === "file" && (
            <button
              className="block w-full text-left px-4 py-2 hover:bg-gray-200"
              onClick={() => {
                setIsRenaming(true);
                setOpenMenuId(null);
              }}
            >
              ✏️ Rename
            </button>
          )}
        </div>
      )}

      {isFolderLike && item?.open && item?.children && (
        <ul className="pl-5 border-l border-gray-600">
          <FolderList
            items={item?.children}
            openMenuId={openMenuId}
            setOpenMenuId={setOpenMenuId}
          />
        </ul>
      )}
    </li>
  );
};

export default FolderItem;
