import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
const initialState = {
  dropdownOptions: ["Load-Bronze", "Bronze-Silver", "Silver-Gold"],
  isDropdownOpen: false,
  activeMedallion: "Load-Bronze",
  medallions: {
    "Load-Bronze": [
      {
        id: uuid(),
        name: "",
        pipeline_name: "",
        u_id: 1,
        input_values: [
          {
            source_conn_id: 1,
            source_type: "",
            source_format: "",
            file_validation: true,
            count_validation: true,
            schema_validation: true,
            validation_path: "",
            schema_path: "",
            target_path: "",
            target_format: "",
            tbname: "",
            header: true,
            footer: true,
            header_count: 3,
            footer_count: 2,
          },
        ],
      },
    ],
    "Bronze-Silver": [
      {
        id: uuid(),
        name: "",
        pipeline_name: "",
        u_id: 1,
        input_values: [
          {
            bronze_conn_id: "",
            transformation_type: "",
            count_validation: true,
            schema_validation: true,
            pipeline_path_or_query: "",
            silver_conn_id: 1,
            tbname: "",
          },
        ],
      },
    ],
    "Silver-Gold": [
      {
        id: uuid(),
        name: "",
        pipeline_name: "",
        u_id: 1,
        input_values: [
          {
            silver_conn_id: 1,
            transformation_type: "",
            count_validation: true,
            schema_validation: true,
            pipeline_path_or_query: "",
            gold_conn_id: 1,
            tbname: "",
          },
        ],
      },
    ],
  },
};

const medallionSlice = createSlice({
  name: "medallion",
  initialState,
  reducers: {
    toggleDropdown: (state) => {
      state.isDropdownOpen = !state.isDropdownOpen;
    },
    closeDropdown: (state) => {
      state.isDropdownOpen = false;
    },
    setActiveMedallion: (state, { payload }) => {
      state.activeMedallion = payload;
    },
    addMedallion: (state, { payload }) => {
      const type = state.activeMedallion;
      const uniqueId = uuid();

      const newMedallion = {
        id: uniqueId,
        name: "",
        pipeline_name: "",
        u_id: 1,
        input_values: payload.input_values || [],
      };

      state.medallions[type].push(newMedallion);
    },
  },
});

export const {
  toggleDropdown,
  closeDropdown,
  setActiveMedallion,
  addMedallion,
} = medallionSlice.actions;
export default medallionSlice.reducer;
