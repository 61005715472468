import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFolder,
  addFile,
  renameItem,
  deleteItem,
  setOpenFolder,
  setActiveSchemaFile,
} from "../../../features/schemas";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import { MdOutlineSchema } from "react-icons/md";
import { CiMenuKebab } from "react-icons/ci";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { setActiveContainer } from "../../../features/PipeLines";
const FolderItem = ({ folder, closeAllMenus, setOpenMenuId, openMenuId }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [renamingFileId, setRenamingFileId] = useState(null);
  const [fileName, setFileName] = useState("");
  const [renameFolder, setRenameFolder] = useState("");
  const { activeSchemaFile } = useSelector((state) => state.schemasData);
  const handleMenuToggle = (e, id) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleAddFolder = () => {
    dispatch(addFolder({ parentId: folder.id, name: "New Folder" }));
    closeAllMenus();
  };

  const handleAddFile = () => {
    const name = prompt("Enter File Name:");
    if (name) dispatch(addFile({ folderId: folder.id, fileName: name }));
    dispatch(setActiveContainer("schema"));
    // closeAllMenus();
  };

  const handleRename = () => {
    setRenamingFileId(folder.id);
    setRenameFolder(folder?.name);
    setOpenMenuId(false);
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this folder?")) {
      dispatch(deleteItem({ id: folder.id, isFolder: true }));
    }
    closeAllMenus();
  };

  const handleFileRename = (file) => {
    setRenamingFileId(file.id);
    setFileName(file.name);
    setOpenMenuId(null);
  };

  const handleFileRenameSubmit = (file) => {
    if (fileName.trim()) {
      dispatch(renameItem({ id: file.id, newName: fileName, isFolder: false }));
    }
    setRenamingFileId(null);
  };
  const folderOpenHandler = (folder) => {
    if (folder?.type === "folder") {
      dispatch(setOpenFolder({ id: folder?.id, isFolder: true }));
    } else if (folder?.type === "file") {
      dispatch(setActiveContainer("schema"));
    }
  };

  const setFolderName = (value) => {
    setRenamingFileId(folder.id);
    setRenameFolder(value);
    setFileName(folder.name);
    setOpenMenuId(null);
  };
  const renameFolderSubmit = (folder) => {
    if (renameFolder.trim()) {
      dispatch(
        renameItem({ id: folder.id, newName: renameFolder, isFolder: true })
      );
      setRenamingFileId(null);
    }
  };
  return (
    <div className="pl-2 mt-1">
      <div
        className="flex justify-between items-center bg-white px-2 py-1  shadow cursor-pointer relative"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {renamingFileId === folder.id ? (
          <input
            type="text"
            value={renameFolder}
            onChange={(e) => setFolderName(e.target.value)}
            onBlur={() => renameFolderSubmit(folder)}
            onKeyDown={(e) => e.key === "Enter" && renameFolderSubmit(folder)}
            autoFocus
            className="border px-2 border-gray-400  py-1 rounded"
          />
        ) : (
          <span
            className="font-semibold flex place-items-center px-1 cursor-pointer"
            onClick={() => folderOpenHandler(folder)}
          >
            {folder?.isOpen ? (
              <FaFolderOpen className="text-yellow-500 mx-2" />
            ) : (
              <FaFolder className="text-yellow-500 mx-2" />
            )}
            {folder.name}
          </span>
        )}

        <button onClick={(e) => handleMenuToggle(e, folder.id)}>
          <BsThreeDotsVertical />
        </button>
        {openMenuId === folder.id && (
          <div className="absolute right-0 top-8 z-50 mt-2 bg-white border shadow-md  w-40">
            <button
              onClick={handleAddFolder}
              className="block w-full text-left px-4 py-2 hover:bg-gray-200"
            >
              New Folder
            </button>
            <button
              onClick={handleAddFile}
              className="block w-full text-left px-4 py-2 hover:bg-gray-200"
            >
              New File
            </button>
            <button
              onClick={handleRename}
              className="block w-full text-left px-4 py-2 hover:bg-gray-200"
            >
              Rename
            </button>
            <button
              onClick={handleDelete}
              className="block w-full text-left px-4 py-2 hover:bg-red-200"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      {/* Render Subfolders */}
      {isOpen &&
        folder.subFolders?.map((subFolder) => (
          <FolderItem
            key={subFolder.id}
            folder={subFolder}
            closeAllMenus={closeAllMenus}
            setOpenMenuId={setOpenMenuId}
            openMenuId={openMenuId}
          />
        ))}

      {/* Render Files */}
      {isOpen && (
        <ul className="ml-6 mt-2">
          {folder.files?.map((file) => (
            <li
              onClick={() => {
                dispatch(setActiveSchemaFile(file));
                dispatch(setActiveContainer("schema"));
              }}
              key={file.id}
              className={`flex justify-between  px-2 py-1 mb-1 rounded relative ${
                activeSchemaFile?.id === file?.id
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
            >
              {renamingFileId === file.id ? (
                <input
                  type="text"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  onBlur={() => handleFileRenameSubmit(file)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleFileRenameSubmit(file)
                  }
                  autoFocus
                  className="border border-gray-400  py-1 rounded"
                />
              ) : (
                <span>📄 {file.name}</span>
              )}
              <button onClick={(e) => handleMenuToggle(e, file.id)}>
                <BsThreeDotsVertical />
              </button>
              {openMenuId === file.id && (
                <div className="absolute right-0 mt-2 bg-white border shadow-md rounded w-40 z-50">
                  <button
                    onClick={() => handleFileRename(file)}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                  >
                    Rename
                  </button>
                  <button
                    onClick={() =>
                      dispatch(deleteItem({ id: file.id, isFolder: false }))
                    }
                    className="block w-full text-left px-4 py-2 hover:bg-red-200"
                  >
                    Delete
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const Schemas = () => {
  const dispatch = useDispatch();
  const { folders } = useSelector((state) => state.schemasData);
  const [isOpen, setIsOpen] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".relative")) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const handleMenuToggle = (e, id) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === id ? null : id);
  };

  return (
    <div className="w-full border-b">
      <div
        className={`flex justify-between items-center p-1 ${
          isOpen ? "bg-[rgb(38,154,248)] text-white " : "bg-white text-black"
        }  cursor-pointer`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {/* {isOpen ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}{" "}
        <span>Schemas</span> */}
        <div className="flex items-center w-full ">
          <span className={`${isOpen ? "text-white" : "text-black"}`}>
            {isOpen ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}
          </span>
          <MdOutlineSchema className="mx-1 text-[rgb(38,154,248)]" />
          <h2 className={`text-md ${isOpen ? "text-white" : "text-black"}`}>
            Schemas
          </h2>
        </div>

        <div className="relative">
          <div onClick={(e) => handleMenuToggle(e, "schemas")} className="p-1">
            <CiMenuKebab />
          </div>
          {openMenuId === "schemas" && (
            <div className="absolute right-0 mt-2 bg-white border shadow-md rounded w-40 z-50">
              <button
                onClick={() => {
                  dispatch(addFolder({ parentId: null, name: "New Folder" }));
                  setIsOpen(true);
                  setOpenMenuId(null);
                }}
                className="block w-full text-left text-black px-4 py-2 hover:bg-gray-200"
              >
                New Folder
              </button>
              <button
                onClick={() => {
                  const name = prompt("Enter File Name:");
                  if (name)
                    dispatch(addFile({ folderId: null, fileName: name }));
                  setOpenMenuId(null);
                }}
                className="block w-full text-left text-black px-4 py-2 hover:bg-gray-200"
              >
                New File
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Root Folder List */}
      {isOpen && (
        <ul className="mt-1 mb-1">
          {folders?.length >= 1 ? (
            folders?.map((folder) => (
              <FolderItem
                key={folder.id}
                folder={folder}
                closeAllMenus={() => setOpenMenuId(null)}
                setOpenMenuId={setOpenMenuId}
                openMenuId={openMenuId}
              />
            ))
          ) : (
            <div className="border w-full text-center">No Folders Found </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default Schemas;
