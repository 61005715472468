import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPipeLineTasks } from "../../features/PipeLines";

const RunnerInput = () => {
  const runners = ["sparkprocess"];
  const { pipeLines, activePipeline } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();

  // Find the active pipeline
  const activePipelineData = pipeLines.find(
    (pipeline) => pipeline.id === activePipeline
  );

  const handleInputChange = ({ target: { value } }) => {
    if (!activePipelineData) return; // Avoid errors if no pipeline is active

    const updatedPipelines = pipeLines.map((pipeline) =>
      pipeline.id === activePipeline
        ? { ...pipeline, run_type: value }
        : pipeline
    );
    dispatch(setPipeLineTasks(updatedPipelines));
  };

  return (
    <select
      name="run_type"
      className="w-54 p-1 outline-none border border-black text-black job_name transition-all duration-300"
      value={activePipelineData?.run_type || ""}
      onChange={handleInputChange}
    >
      <option value="">Select Runner Type</option>
      {runners.map((runner, index) => (
        <option key={index} value={runner}>
          {runner}
        </option>
      ))}
    </select>
  );
};

export default RunnerInput;
