import React, { useEffect, useState, useMemo } from "react";
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Handle,
} from "react-flow-renderer";
import withEventTracking from "../../hoc/withEventTracking";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTask,
  getSelectedNodeId,
  getYamlData,
} from "../../features/nodesData";
// import {connectMedallions} from "../../features/medallionSlice"
import {
  getCurrentTab,
  getSourceCodeOpen,
  getSourdeObject,
} from "../../features/commonProperties";
import { IoCodeSlash } from "react-icons/io5";

import { IoMdCopy } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import Properties from "../Properties";
import {
  getNodesData,
  setCurrentPipeLineData,
  setCurrentPipeLineEdges,
  setCurrentPipeLineTasks,
  setNodeEdges,
  setPipeLineTasks,
} from "../../features/PipeLines";
import GeneratedNodes from "./Components/CustomNodes";

const Canvas = ({
  canvasItems = [],
  onItemDrop,
  onItemClick,
  onConnectItems,
  onDeleteItem,
  selectedItem,
  updateHistory,
}) => {
  const { tasksData, uniq_id, yamlData, renderingyaml, selectedNodeId } =
    useSelector((state) => state.totalNodes);
  const {
    pipeLines,
    activePipeline,
    currentPiplineData,
    currentPipeLineTasks,
    currentPipeLineEdges,
    nodeEdges,
    nodesData,
  } = useSelector((state) => state.pipeLines);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [editableNodeId, setEditableNodeId] = useState(null);
  const [newName, setNewName] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const handleCustomItemDrop = (e) => {
      const { item, x, y } = e.detail;
      const canvasRect = e.currentTarget.getBoundingClientRect();
      const adjustedX = x - canvasRect.left;
      const adjustedY = y - canvasRect.top;

      if (!Number.isFinite(adjustedX) || !Number.isFinite(adjustedY)) {
        console.error(
          "Error in handleCustomItemDrop: Invalid drop coordinates",
          { x, y }
        );
        return;
      }
      onItemDrop(item, adjustedX, adjustedY);
    };

    const canvasElement = document.querySelector(".react-flow__renderer");
    canvasElement.addEventListener("item-drop", handleCustomItemDrop);

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("item-drop", handleCustomItemDrop);
      }
    };
  }, [onItemDrop]);
  useEffect(() => {
    const currentPipeline = nodesData?.find(
      (node) => node?.id === activePipeline
    );
    const currentEdges = nodeEdges?.find((node) => node?.id === activePipeline);
    const currentTasks = pipeLines?.find((task) => task?.id === activePipeline);
    dispatch(setCurrentPipeLineData(currentPipeline));
    dispatch(setCurrentPipeLineTasks(currentTasks));
    dispatch(setCurrentPipeLineEdges(currentEdges));
    const updatedNodes = currentPipeline?.job_nodes?.map((item) => {
      return {
        id: item.uniqueId,
        customId: item?.u_id,
        type: "customNodes",
        position: { x: item.x, y: item.y },
        data: {
          label: item?.name,
          image: item?.imgpath,
          id: item.uniqueId,
          path: item?.yamlPath,
          activity_state: item?.activity_state,
        },
      };
    });
    setNodes(updatedNodes);
    setEdges(currentEdges?.edges);
    // const updatedEdges = [];

    // const nodeEdges = currentPipeline?.job_nodes?.forEach((item) => {
    //   if (item.dependency) {
    //     const dependencies = item.dependency.split(",");
    //     dependencies.forEach((dep) => {
    //       updatedEdges.push({
    //         id: `e-${dep}-${item.uniqueId}`,
    //         source: dep,
    //         target: item.uniqueId,
    //       });
    //       return updatedEdges;
    //     });
    //   }
    // });
    // Set edges by merging with previous edges
    // setEdges((prevEdges) => [...prevEdges, ...updatedEdges]);
  }, [
    currentPiplineData,
    setNodes,
    setEdges,
    selectedNodeId,
    nodesData,
    currentPipeLineEdges,
  ]);

  const handleDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData("item"));
    const canvasRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - canvasRect.left;
    const y = e.clientY - canvasRect.top;

    if (!Number.isFinite(x) || !Number.isFinite(y)) {
      console.error("Error in handleDrop: Invalid drop coordinates", { x, y });
      return;
    }

    onItemDrop(item, x, y);
  };
  // console.log(edges, nodeEdges);
  const handleTouchDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.target.dataset.item);
    const canvasRect = e.currentTarget.getBoundingClientRect();
    const x = e.touches[0].clientX - canvasRect.left;
    const y = e.touches[0].clientY - canvasRect.top;

    if (!Number.isFinite(x) || !Number.isFinite(y)) {
      console.error("Error in handleTouchDrop: Invalid drop coordinates", {
        x,
        y,
      });
      return;
    }

    onItemDrop(item, x, y);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleTouchDragOver = (e) => {
    e.preventDefault();
  };

  const handleNodeDoubleClick = (event, node) => {
    setEditableNodeId(node.id);
    setNewName(node.data.label);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleNameSubmit = (e) => {
    if (e.key === "Enter") {
      const updatedNodes = nodes.map((node) =>
        node.id === editableNodeId
          ? { ...node, data: { ...node.data, label: newName } }
          : node
      );
      setNodes(updatedNodes);
      setEditableNodeId(null);
    }
  };

  const onConnect = (params) => {
    setEdges((els) => addEdge(params, els));
    // console.log(params);
    const updatedNodeEdges = {
      ...currentPipeLineEdges,
      edges: [
        ...currentPipeLineEdges?.edges,
        {
          ...params,
          id: `reactflow__edge-${params?.source}-${params?.target}`,
        },
      ],
    };
    const updateDependency = currentPipeLineTasks?.tasks?.map((task) => {
      console.log(task?.u_id, params?.target);
      if (task?.u_id === params?.target) {
        return { ...task, dependency: [params?.source] };
      } else {
        return task;
      }
    });
    console.log(updateDependency);
    dispatch(
      setCurrentPipeLineTasks({
        ...currentPipeLineTasks,
        tasks: updateDependency,
      })
    );
    const updatedPipeLines = pipeLines?.map((pipeline) => {
      if (pipeline?.id === activePipeline) {
        return { ...pipeline, tasks: updateDependency };
      } else {
        return pipeline;
      }
    });
    console.log(currentPipeLineTasks, updatedPipeLines);
    dispatch(setPipeLineTasks(updatedPipeLines));
    const updatedPipeLineEdges = nodeEdges.map((edges) => {
      if (edges?.id === activePipeline) {
        return updatedNodeEdges;
      } else {
        return edges;
      }
    });

    dispatch(setCurrentPipeLineEdges(updatedNodeEdges));
    dispatch(setNodeEdges(updatedPipeLineEdges));
    const fromItem = currentPiplineData?.job_nodes.find(
      (item) => item.uniqueId === params.source
    );
    const toItem = currentPiplineData?.job_nodes.find(
      (item) => item.uniqueId === params.target
    );
    if (typeof onConnectItems === "function") {
      onConnectItems(fromItem, toItem);
    } else {
      console.error("onConnectItems is not a function");
    }
  };
  const onNodeClick = (event, node) => {
    const clickedItem = currentPiplineData?.job_nodes.find((item) => {
      return item.u_id === node.customId;
    });
    onItemClick(clickedItem);
    dispatch(getSelectedNodeId(clickedItem?.uniqueId));
    const renderJson = renderingyaml.find((data) => {
      return data.custom_id === clickedItem?.uniqueId;
    });
    dispatch(getYamlData(renderJson));
  };

  const onNodeDragStop = (event, node) => {
    const updatedItems = currentPiplineData?.job_nodes.map((item) =>
      item.uniqueId === node.id
        ? { ...item, x: node.position.x, y: node.position.y }
        : item
    );
    const UpdatedNodes = nodesData.map((node) => {
      if (node?.id === activePipeline) {
        return { ...node, job_nodes: updatedItems };
      } else {
        return node;
      }
    });
    dispatch(getNodesData(UpdatedNodes));
    updateHistory(updatedItems);
  };

  const CustomNodes = ({ data, isConnectable }) => {
    return (
      <>
        <GeneratedNodes data={data} />
      </>
    );
  };
  const nodeTypes = useMemo(
    () => ({
      customNodes: CustomNodes,
    }),
    [selectedNodeId]
  );
  return (
    <>
      <div
        className="canvas_container h-full z-40 "
        onClick={(e) => {
          dispatch(getSelectedNodeId("parent"));
          dispatch(getCurrentTab("parameters"));
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onTouchEnd={handleTouchDrop}
        onTouchMove={handleTouchDragOver}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onNodeClick={(event, node) => {
            event.stopPropagation();
            onNodeClick(event, node);
            dispatch(getCurrentTab("general"));
          }}
          onNodeDragStop={onNodeDragStop}
          onNodeDoubleClick={handleNodeDoubleClick}
          className="p-1 z-40"
        >
          {/* <Background color="green" /> */}
          <Controls />
        </ReactFlow>

        {editableNodeId && (
          <div className="absolute">
            <input
              type="text"
              value={newName}
              onChange={handleNameChange}
              onKeyDown={handleNameSubmit}
              className="border p-1"
              autoFocus
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withEventTracking(Canvas);
