import React, { useState } from 'react';
import ReadIOConfigs from '../ReadIOConfigs'; 
import WriteIOConfigs from '../WriteIOConfigs';

const ConfigSidebar = () => {
  const [openItems, setOpenItems] = useState({});
  const [activeComponent, setActiveComponent] = useState(null); 

  const items = [
    { 
      id: 1, 
      name: 'IO Configs', 
      type: 'input',
      subcomponent: [
        { id: '1-1', name: "Read IO Configs", type: 'default' },
        { id: '1-2', name: "Write IO Configs", type: 'default' }
      ]
    },
    { id: 2, name: 'Transformation Configs', type: 'default' },
    { id: 3, name: 'Datasets Configs', type: 'default' }
  ];

  const toggleItems = (id) => {
    setOpenItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleClick = (subItem) => {
    if (subItem.name === 'Read IO Configs') {
      setActiveComponent(<ReadIOConfigs />);
    } else if (subItem.name === 'Write IO Configs') {
      setActiveComponent(<WriteIOConfigs />);
    } else {
      setActiveComponent(null);
    }
  };
  

  return (
    <div className="flex">
      <div className="w-40 h-full bg-gray-400 text-white flex flex-col items-center p-4 space-y-8 fixed left-0 top-0">
        <h4>Configuration Sidebar</h4>
        {items.map((item) => (
          <div key={item.id} className="w-full">
            <div
              className="text-1xl p-2 bg-gray-600 rounded cursor-pointer flex justify-between items-center"
              onClick={() => item.subcomponent && toggleItems(item.id)}
            >
              {item.name}
              {item.subcomponent && (
                <span>{openItems[item.id] ? '-' : '+'}</span>
              )}
            </div>
            {openItems[item.id] && item.subcomponent && (
              <div className="pl-4">
                {item.subcomponent.map(subItem => (
                  <div 
                    key={subItem.id} 
                    className="p-2 cursor-pointer"
                    onClick={() => handleClick(subItem)} // Handle click for sub items
                  >
                    {subItem.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="ml-40 p-4">
        {activeComponent}
      </div>
    </div>
  );
};

export default ConfigSidebar;

