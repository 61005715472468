const activities = [
  {
    id: 2,
    name: "IO Connectors",
    type: "default",
    imgpath: "/images/sidebar/connectors.png",
    subcomponents: [
      {
        id: "2-1",
        name: "Read IO Connectors",
        type: "category",
        imgpath: "/images/sidebar/readjdbc.png",
        subcomponents: [
          {
            id: "2-1-1",
            name: "ReadJDBC",
            type: "read io",
            connection_type: "JDBC",
            yamlPath: "/path/to/IOConnectors/ReadIO/sample.yaml",
            imgpath: "/images/sidebar/readjdbc1.png",
          },
          {
            id: "2-1-2",
            name: "ReadS3",
            type: "read io",
            connection_type: "S3",
            imgpath: "/images/sidebar/s3.png",
          },
          {
            id: "2-1-3",
            name: "ReadFiles",
            type: "read io",
            connection_type: "FILE",
            imgpath: "/images/sidebar/eye.png",
          },
          {
            id: "2-1-4",
            name: "ReadMongoDB",
            type: "read io",
            imgpath: "/images/sidebar/server.png",
          },
          {
            id: "2-1-5",
            name: "ReadDeltaLake",
            type: "read io",
            imgpath: "/images/sidebar/deltalake.png",
          },
          {
            id: "2-1-6",
            name: "ReadParquet",
            type: "read io",
            imgpath: "/images/sidebar/parquet.png",
          },
          {
            id: "2-1-7",
            name: "ReadAZS",
            type: "read io",
            imgpath: "/images/sidebar/azure.png",
          },
          {
            id: "2-1-8",
            name: "ReadGCS",
            type: "read io",
            imgpath: "/images/sidebar/gcs.png",
          },
          {
            id: "2-1-9",
            name: "ReadADLS",
            type: "read io",
            imgpath: "/images/sidebar/data-lake.png",
            connection_type: "ADLS",
          },
          {
            id: "2-1-10",
            name: "CSV_Reader",
            yamlPath: "/path/to/IOConnectors/ReadIO/csvreader.yaml",
            imgpath: "/images/sidebar/csv.png",
          },
        ],
      },
      {
        id: "2-2",
        name: "Write IO Connectors",
        type: "category",
        imgpath: "/images/sidebar/writedata.png",
        subcomponents: [
          {
            id: "2-2-1",
            name: "WriteJDBC",
            type: "write io",
            imgpath: "/images/sidebar/writejdbc.png",
            yamlPath: "/path/to/IOConnectors/WriteIO/sample.yaml",
          },
          {
            id: "2-2-2",
            name: "WriteS3",
            type: "write io",
            imgpath: "/images/sidebar/s3.png",
          },
          {
            id: "2-2-3",
            name: "WriteFiles",
            type: "write io",
            imgpath: "/images/sidebar/article.png",
          },
          {
            id: "2-2-4",
            name: "WriteMangoDB",
            type: "write io",
            imgpath: "/images/sidebar/data-server.png",
          },
          {
            id: "2-2-5",
            name: "WriteDeltaLake",
            type: "write io",
            imgpath: "/images/sidebar/deltalake.png",
          },
          {
            id: "2-2-6",
            name: "WriteParquet",
            type: "write io",
            imgpath: "/images/sidebar/parquet.png",
          },
          {
            id: "2-2-7",
            name: "WriteAZS",
            type: "write io",
            imgpath: "/images/sidebar/azure.png",
          },
          {
            id: "2-2-8",
            name: "WriteGCS",
            type: "write io",
            imgpath: "/images/sidebar/gcs.png",
          },
          {
            id: "2-2-9",
            name: "WriteADLS",
            type: "write io",
            imgpath: "/images/sidebar/adls.png",
          },
          {
            id: "2-2-10",
            name: "CSV_Writer",
            imgpath: "/images/sidebar/csv.png",
            yamlPath: "/path/to/IOConnectors/WriteIO/csvwriter.yaml",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Transformation",
    type: "default",
    imgpath: "/images/sidebar/transformation.png",
    subcomponents: [
      {
        id: "3-1",
        name: "Filter",
        type: "default",
        imgpath: "/images/sidebar/filter.png",
        yamlPath: "/path/to/filter/sample.yaml",
      },
      {
        id: "3-2",
        name: "Union",
        type: "default",
        imgpath: "/images/sidebar/union.png",
        yamlPath: "/path/to/union/sample.yaml",
      },
      // Additional transformation components can be added here
    ],
  },
  {
    id: 4,
    name: "Datasets",
    type: "output",
    imgpath: "/images/...",
  },
  {
    id: 5,
    name: "UI to YAML File",
    type: "default",
    imgpath: "/images/sidebar/yaml.png",
    subcomponents: [
      {
        id: "5-1",
        name: "YAML-1",
        type: "default",
        imgpath: "/images/sidebar/yaml-1.png",
      },
    ],
  },
];
export default activities;
