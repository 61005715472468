import React, { useContext } from 'react';
import { YamlContext } from '../YamlProvider';

const SavedItems = () => {
  const { yamlData } = useContext(YamlContext);

  return (
    <div className="text-center">
      <h3 className="text-4xl font-bold mb-4">Saved Items</h3>
      <p className="text-lg">Here are your saved items.</p>
      {yamlData && (
        <pre className="mt-4 p-4 bg-gray-100 rounded text-left">
          {yamlData}
        </pre>
      )}
    </div>
  );
};

export default SavedItems;


