import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobDetails } from "../../features/generatedJSON";

const ProjectNameInput = ({ value }) => {
  const { jobDetails } = useSelector((state) => state.jobData);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);

  const getJobName = ({ target: { value } }) => {
    dispatch(getJobDetails({ name: "job_name", value }));
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <input
      className={`w-full  mr-2 md:mr-0 p-2 md:p-1 outline-none border border-black lg:border-none  text-black job_name transition-all duration-300`}
      autoComplete="on"
      type="text"
      placeholder="Enter Job Name"
      value={jobDetails?.job_name || ""}
      onChange={getJobName}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default ProjectNameInput;
