import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineSave } from "react-icons/ai";
import ProjectNameInput from "../../ProjectNameInput";
import RunnerInput from "../../RunnerInput";
import { CiMenuKebab } from "react-icons/ci";

const MobileView = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      {/* Top Bar */}
      <div className="w-screen  z-50 navbar flex place-items-center">
        <div className="w-1/3 flex justify-start place-items-center px-5">
          <div className="flex lg:hidden ">
            <CiMenuKebab />
          </div>
          <span className="text-white font-black">ETL</span>
        </div>
        <div className="w-1/3">
          <ul className="flex justify-center place-items-center space-x-3">
            <li>
              <Link
                to="/"
                className="text-gray-300 hover:text-white"
                title="Home"
              >
                <AiOutlineHome className="navicons" />
              </Link>
            </li>
            <li>
              <Link
                to="/configurations"
                className="text-gray-300 hover:text-white"
                title="Configurations"
              >
                <IoSettingsOutline className="navicons" />
              </Link>
            </li>
            <li>
              <Link
                to="/saved-items"
                className="text-gray-300 hover:text-white"
                title="Saved Items"
              >
                <AiOutlineSave className="navicons" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-1/3 flex justify-end place-items-center">
          <label>
            <div className="w-9 h-10 cursor-pointer flex flex-col items-center justify-center">
              <input
                className="hidden peer"
                type="checkbox"
                id="menuToggle"
                onChange={() => setOpenMenu(!openMenu)}
              />
              <div className="w-[50%] h-[2px] bg-white rounded-sm transition-all duration-300 origin-left translate-y-[0.45rem] peer-checked:rotate-[-45deg]"></div>
              <div className="w-[50%] h-[2px] bg-white rounded-md transition-all duration-300 origin-center peer-checked:hidden"></div>
              <div className="w-[50%] h-[2px] bg-white rounded-md transition-all duration-300 origin-left -translate-y-[0.45rem] peer-checked:rotate-[45deg]"></div>
            </div>
          </label>
        </div>
      </div>

      {openMenu && (
        <div
          className={`grid w-screen h-1/2  relative top-10 bg-white border-b border-black py-5 
       `}
        >
          <ProjectNameInput />
          <RunnerInput />
          <div className=" w-screen ">
            <ul className=" text-black items-center  text-center w-full  space-y-4">
              <li className="cursor-pointer flex items-center hover:bg-blue-200 p-2 hover:text-white sidebar_names ">
                <img
                  src="/images/sidebar/add-folder.png"
                  className="w-5 h-5 mx-2"
                  alt=""
                />
                <span> New</span>
              </li>
              <li className="cursor-pointer flex items-center hover:bg-blue-200 p-2 hover:text-white sidebar_names">
                <img
                  src="/images/sidebar/data.png"
                  className="w-5 h-5 mx-2"
                  alt=""
                />
                <span> Save</span>
              </li>
              <li className="cursor-pointer flex items-center hover:bg-blue-200 p-2 hover:text-white sidebar_names">
                <img
                  src="/images/sidebar/open.png"
                  className="w-5 h-5 mx-2"
                  alt=""
                />
                <span> Open</span>
              </li>
              <li className="cursor-pointer flex items-center hover:bg-blue-200 p-2 hover:text-white sidebar_names">
                <img
                  src="/images/sidebar/deletefile.png"
                  className="w-5 h-5 mx-2"
                  alt=""
                />
                <span> Delete</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileView;
