import React, { useState,useEffect } from 'react';
import axios from 'axios';
import yaml from 'js-yaml';

const ReadIOConfigs = ({ itemId }) => {
  const [yamlPath, setYamlPath] = useState('/sample-config.yaml'); // Default path to the main YAML file
  const [mainYamlData, setMainYamlData] = useState(null);
  const [childYamlData, setChildYamlData] = useState(null);
  const [grandChildYamlData, setGrandChildYamlData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Update yamlPath based on itemId
    if (itemId) {
      setYamlPath(`/configs/${itemId}.yaml`);
    }
  }, [itemId]);

  const handleInputChange = (e) => {
    setYamlPath(e.target.value);
  };

  const fetchYamlData = async (path) => {
    try {
      const response = await axios.get(path);
      return yaml.load(response.data);
    } catch (err) {
      throw new Error(`Failed to load YAML file at path: ${path}`);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setError('');
      const mainYamlData = await fetchYamlData(yamlPath);
      setMainYamlData(mainYamlData);
  
      if (mainYamlData.child_configs) {
        const childYamlPromises = mainYamlData.child_configs.map(childPath => fetchYamlData(childPath));
        const childYamlData = await Promise.all(childYamlPromises);
        setChildYamlData(childYamlData);
  
        const grandChildYamlPromises = [];
        childYamlData.forEach(childData => {
          if (childData.grandchild_configs) {
            childData.grandchild_configs.forEach(grandchildPath => {
              grandChildYamlPromises.push(fetchYamlData(grandchildPath));
            });
          }
        });
        const grandChildYamlData = await Promise.all(grandChildYamlPromises);
        setGrandChildYamlData(grandChildYamlData);
      } else {
        setChildYamlData([]); 
        setGrandChildYamlData([]); 
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderFormFields = (data) => {
    return Object.keys(data).map((key) => {
      if (typeof data[key] === 'object' && data[key] !== null) {
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
              {key}
            </label>
            <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              {renderFormFields(data[key])}
            </div>
          </div>
        );
      } else {
        return (
          <div key={key} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
              {key}
            </label>
            <input
              id={key}
              name={key}
              type="text"
              defaultValue={data[key]}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    if (yamlPath) {
      handleFormSubmit();
    }
  }, [yamlPath]);

  return (
    <div className="flex flex-col items-center mb-8">
      <div className="w-full max-w-3xl mb-8 flex justify-center">
        <form onSubmit={handleFormSubmit} className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mt-16 flex items-center">
          <input
            id="yamlPath"
            name="yamlPath"
            type="text"
            value={yamlPath}
            onChange={handleInputChange}
            placeholder="Enter YAML File Path"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-4 rounded focus:outline-none focus:shadow-outline"
          >
            {loading ? 'Loading...' : 'Load YAML'}
          </button>
        </form>
      </div>
      {mainYamlData && (
        <div className="flex">
          <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
            <h4 className="text-2xl font-bold mb-4 text-center">Main YAML Data</h4>
            {renderFormFields(mainYamlData)}
          </div>
          <div className="flex flex-col ml-4">
            {childYamlData && childYamlData.map((childData, index) => (
              <div key={`child-${index}`} className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
                <h4 className="text-2xl font-bold mb-4 text-center">Child YAML Data {index + 1}</h4>
                {renderFormFields(childData)}
              </div>
            ))}
            {grandChildYamlData && grandChildYamlData.map((grandChildData, index) => (
              <div key={`grandchild-${index}`} className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
                <h4 className="text-2xl font-bold mb-4 text-center">Grand Child YAML Data {index + 1}</h4>
                {renderFormFields(grandChildData)}
              </div>
            ))}
          </div>
        </div>
      )}
       {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default ReadIOConfigs;

