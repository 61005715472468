import React, { useEffect, useState, useRef } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  getNodesData,
  setActivePipeline,
  setPipeLineTasks,
  setValidationErrors,
} from "../../../features/PipeLines";
import uuid from "react-uuid";

const CopyPipeLineModal = ({ isOpen, onClose, pipeLineName }) => {
  const [inputValue, setInputValue] = useState("");
  const { pipeLines, nodesData, validationErrors } = useSelector(
    (state) => state.pipeLines
  );
  const [currentNode, setCurrentNode] = useState(null);
  const [validations, setValidations] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && pipeLineName) {
      setInputValue(pipeLineName.job_name || "");
    }

    const copypipelinenodes = nodesData.find(
      (node) => node?.id === pipeLineName?.id
    );
    const newValidations = validationErrors?.find(
      (err) => err?.id === pipeLineName?.id
    );
    setValidations(newValidations);
    setCurrentNode(copypipelinenodes);
  }, [isOpen, pipeLineName]);

  if (!isOpen) return null;

  const confirmCopy = () => {
    const u_id = uuid();
    const { id, job_name, ...remainingData } = pipeLineName;
    const copiedPipeLine = {
      id: u_id,
      job_name: `${job_name} Copy`,
      ...remainingData,
    };
    const copiedNodes = {
      ...currentNode,
      id: u_id,
    };
    const copiedvalidations = { ...validations, id: u_id };
    const newNode = [...nodesData, copiedNodes];
    const newpipeline = [...pipeLines, copiedPipeLine];
    const newvalidations = [...validationErrors, copiedvalidations];
    dispatch(setPipeLineTasks(newpipeline));
    dispatch(getNodesData(newNode));
    dispatch(setActivePipeline(copiedNodes?.id));
    dispatch(setValidationErrors(newvalidations));
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30"></div>
      <div className="relative bg-white p-6  shadow-lg max-w-md w-full">
        {/* <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <ImCancelCircle />
        </button> */}

        <form className="mt-4 space-y-3">
          <label htmlFor="">Are you sure to copy pipeline</label>
          <input
            type="text"
            value={inputValue}
            disabled
            className="mt-1 p-2 w-full border border-black focus:outline-none "
          />

          <div className="flex justify-end place-items-center mt-4 h-full gap-2">
            <button
              type="button"
              className="mx-2 px-4 border property_labels border-blue-600 rounded-[2px] py-1 bg-blue-600 text-white hover:bg-[rgb(38,154,248)]"
              onClick={confirmCopy}
            >
              Copy
            </button>
            <button
              type="button"
              className="rounded-[2px] mx-2 px-4 border property_labels py-1 border-black hover:border-[rgb(38,154,248)] hover:text-white bg-white text-black hover:bg-[rgb(38,154,248)]"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CopyPipeLineModal;
