import React, { useEffect } from "react";
import PipeLineFilters from "./PipeLineFilters";
import { FaEye, FaPlay } from "react-icons/fa";
import MobileFIlters from "./MobileFIlters";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { filterJobs, setJobs } from "../../../../features/moniterFilters";

const MoniterDetails = () => {
  const { jobs, filteredJobs, startTime, endTime } = useSelector(
    (state) => state.jobFilters
  );
  const { currentUrl } = useSelector((state) => state.commonProps);
  console.log(filteredJobs);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      start_date: "2024-02-24 10:00:00",
      end_date: "2024-02-26 10:00:00",
    };
    async function getUserPipeLines() {
      try {
        const response = await axios.post(
          `${currentUrl?.url}log_monitor_time.php`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          dispatch(setJobs(response.data.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
    getUserPipeLines();
    setTimeout(() => {
      dispatch(filterJobs());
    }, 100);
  }, []);
  return (
    <div className="h-full w-full  grid grid-rows-[25%_1fr] overflow-hidden bg-white ">
      <div className="grid ">
        <div className="px-2">
          <h2 className="text-xl font-bold ">Pipeline Monitor</h2>
          <p className="text-gray-700">
            Total Pipelines: {filteredJobs?.length}
          </p>
        </div>
        <PipeLineFilters />
        <MobileFIlters />
      </div>
      <div className="relative h-[72vh]  overflow-y-scroll con_sidebar p-2 bg-slate-50 border-t-2">
        <table className="w-full border-collapse">
          <thead className=" bg-[rgb(38,154,248)] sticky top-0 z-10">
            <tr className="text-white">
              <th className="border-b text-start p-2">Job ID</th>
              <th className="border-b text-start p-2">Pipeline Name</th>
              <th className="border-b text-start  p-2">Triggered By</th>
              <th className="border-b text-start p-2">Trigger ID</th>
              <th className="border-b text-start p-2">Start Time</th>
              <th className="border-b text-start p-2">End Time</th>
              <th className="border-b text-start p-2">Status</th>
              <th className="border-b text-start p-2">Action</th>
            </tr>
          </thead>
          <tbody className="h-full  overflow-y-scroll">
            {jobs?.map((pipeline, index) => (
              <tr
                key={index}
                className="border-b hover:bg-blue-300 py-2 hover:text-white"
              >
                <td className="border-b property_labels p-2">
                  {pipeline.job_id}
                </td>
                <td className="border-b property_labels p-2 text-blue-600">
                  {pipeline.pipeline_name}
                </td>
                <td className="border-b property_labels p-2">
                  {pipeline.triggered_by}
                </td>
                <td className="border-b property_labels p-2">
                  {pipeline.trigger_id || "N/A"}
                </td>
                <td className="border-b property_labels p-2">
                  {pipeline.start_date}
                </td>
                <td className="border-b property_labels p-2">
                  {pipeline.end_date}
                </td>
                <td
                  className={`border-b property_labels p-2 ${
                    pipeline.job_status === "success"
                      ? "text-green-600"
                      : pipeline?.job_status === "pending"
                      ? "text-blue-600"
                      : "text-red-600"
                  } `}
                >
                  {pipeline.job_status}
                </td>
                <td className="border-b property_labels p-2">
                  {pipeline.job_status === "success" ? (
                    <FaEye title="view logs" />
                  ) : (
                    <FaPlay title="Retry" className="text-blue-600" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MoniterDetails;
