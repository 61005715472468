import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renameItem, updateDatasetInDDL } from "../../../../features/DDL";

const DatasetForm = () => {
  const { connections } = useSelector((state) => state.connections);
  const { currentDataset, ddlData } = useSelector((state) => state.ddlData);
  const dispatch = useDispatch();
  const [connection, setConnection] = useState([]);

  const [formData, setFormData] = useState({
    connectionName: null, // Store full child object here
    displayName: "",
    datasetName: "",
  });

  // 🟢 Update `formData` when `currentDataset` changes
  useEffect(() => {
    if (currentDataset) {
      setFormData({
        connectionName: currentDataset.connections?.[0] || null, // Load existing connection if available
        displayName: currentDataset.displayName || "",
        datasetName: currentDataset.datasetName || "",
      });
    }
  }, [currentDataset]);

  // 🟢 Ensure `connections` is properly structured
  useEffect(() => {
    if (connections && Array.isArray(connections)) {
      const updatedData = connections.map((item) => ({
        ...item,
        children: Array.isArray(item.children) ? item.children : [],
      }));
      setConnection(updatedData);
    }
  }, [connections]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "connectionName") {
      // Find the selected connection in the children array
      const selectedChild = connection
        .flatMap((conn) => conn.children)
        .find((child) => child.id.toString() === value);

      setFormData({
        ...formData,
        connectionName: selectedChild || null,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.connectionName ||
      !formData.displayName ||
      !formData.datasetName
    ) {
      alert("All fields are required!");
      return;
    }

    // Create updated dataset object (Replace connection instead of appending)
    const updatedDataset = {
      ...currentDataset,
      displayName: formData.displayName,
      datasetName: formData.datasetName,
      connections: [formData.connectionName], // 🔹 Replace instead of append
    };

    dispatch(
      updateDatasetInDDL({ datasetId: currentDataset?.id, updatedDataset })
    );
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white p-6 shadow-lg">
        <h2 className="text-xl font-semibold mb-4 text-center">
          Create Dataset
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Connection Name (Dropdown) */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Connection Name
            </label>
            <select
              name="connectionName"
              value={formData.connectionName?.id || ""}
              onChange={handleChange}
              className="w-full border p-2 focus:ring focus:ring-blue-300"
            >
              <option value="">Select Connection</option>
              {connection.map((conn) => (
                <optgroup key={conn.id} label={conn.connection_name}>
                  {conn.children.length > 0 ? (
                    conn.children.map((child) => (
                      <option key={child.id} value={child.id.toString()}>
                        {child.connection_name}
                      </option>
                    ))
                  ) : (
                    <option key={conn.id} value={conn.id} disabled>
                      No Connection Available
                    </option>
                  )}
                </optgroup>
              ))}
            </select>
          </div>

          {/* Display Name (Input) */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Display Name
            </label>
            <input
              type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              placeholder="Enter Display Name"
              className="w-full border p-2 focus:ring focus:ring-blue-300"
            />
          </div>

          {/* Dataset Name (Input) */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Dataset Name
            </label>
            <input
              type="text"
              name="datasetName"
              value={formData.datasetName}
              onChange={handleChange}
              placeholder="Enter Dataset Name"
              className="w-full border p-2 focus:ring focus:ring-blue-300"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 hover:bg-blue-600 transition"
          >
            Create Dataset
          </button>
        </form>
      </div>
    </div>
  );
};

export default DatasetForm;
