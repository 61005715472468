import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  triggers: [],
};
const triggerSlice = createSlice({
  name: "triggers",
  initialState,
  reducers: {
    setTriggers: (state, { payload }) => {
      state.triggers = payload;
    },
  },
});
export const { setTriggers } = triggerSlice.actions;
export default triggerSlice.reducer;
