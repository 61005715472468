import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import SavedItems from "./components/SavedItems";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Properties from "./components/Properties";
import WriteIOConfigs from "./components/WriteIOConfigs";
import Configurations from "./components/Configurations/index";
import { YamlProvider } from "./components/YamlProvider";
import { CanvasProvider } from "./components/CanvasProvider";
import { useConnection } from "./components/ConnectionContext/index.jsx";
import "./index.css";
import "./styles/globals.css";
import Testing from "./components/Testing/Test.jsx";
import Registration from "./components/pages/Registration/index.jsx";
import MainHome from "./components/pages/MainHome/index.jsx";
import Forms from "./components/pages/Forms/index.jsx";
import APISelector from "./components/apiselector/ApiSelector.jsx";
const App = () => {
  const { selectedConnection } = useConnection();
  return (
    <YamlProvider>
      <CanvasProvider>
        <Router>
          {/* <Navbar /> */}
          <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/configurations" element={<Configurations />} />
            <Route path="/saved-items" element={<SavedItems />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/new-id" element={<WriteIOConfigs />} />
            <Route path="/test" element={<Testing />} />
            <Route path="/user-registration" element={<Registration />} />
            <Route path="/home" element={<Home />} />
            <Route path="/user-login" element={<Forms />} />
            <Route path="api-selector" element={<APISelector />} />
          </Routes>
          {selectedConnection && <WriteIOConfigs />}
          {/* <Footer /> */}
        </Router>
      </CanvasProvider>
    </YamlProvider>
  );
};

export default App;
