import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FolderList from "./FolderList";

const SidebarFolderTree = () => {
  const { ddlData } = useSelector((state) => state.ddlData);
  const [openMenuId, setOpenMenuId] = useState(null);
  return (
    <div className="flex min-h-[40vh]  overflow-y-scroll hide_scrollbar">
      {/* Sidebar */}
      <div className="w-full text-white p-1">
        <FolderList
          items={ddlData}
          openMenuId={openMenuId}
          setOpenMenuId={setOpenMenuId}
        />
      </div>
    </div>
  );
};

export default SidebarFolderTree;
