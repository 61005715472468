import React, { useState, useEffect } from "react";
import withEventTracking from "../../hoc/withEventTracking";
import yaml from "js-yaml";
import axios from "axios";
import ConnectionDetails from "../ConnectionDetails";
import Navbar from "../Navbar";
import { useDispatch, useSelector } from "react-redux";
import { TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";
import { getConnectionSidebar } from "../../features/commonProperties";
const WriteIOConfigs = () => {
  const [newConnectionName, setNewConnectionName] = useState("");
  const { currentUrl } = useSelector((state) => state.commonProps);
  const [connectionData, setConnectionData] = useState(null);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [showConnectionDetails, setShowConnectionDetails] = useState(false);
  const [editId, setEditId] = useState(null);
  const { connectionSidebar } = useSelector((state) => state.commonProps);
  const dispatch = useDispatch();
  useEffect(() => {
    const connectionName = sessionStorage.getItem("newConnectionName");
    if (connectionName) {
      setNewConnectionName(connectionName);
    }
  }, []);

  // Read and parse YAML file
  useEffect(() => {
    const fetchYaml = async () => {
      try {
        const response = await fetch("/path/connections/fetchData.yaml");
        const text = await response.text();
        const data = yaml.load(text);
        const connection = Object.values(data.connections).find(
          (conn) => conn.type === newConnectionName
        );

        if (connection) {
          setConnectionData(connection);
          setDynamicFields(connection.input_items);
        } else {
          console.error("No matching connection found in YAML");
        }
      } catch (err) {
        console.error("Error loading YAML file", err);
      }
    };

    fetchYaml();
  }, [newConnectionName, formData, isEditing]);

  // Load existing data from session storage if it exists
  useEffect(() => {
    const existingData = sessionStorage.getItem("s3ConnectionData");
    if (existingData) {
      setFormData(JSON.parse(existingData));
    }
  }, []);

  const handleDataFromChild = (data) => {
    setEditId(data.id);
    if (data) {
      setFormData(data);
      setIsEditing(true);
    } else {
      alert("No existing connection data found to edit.");
      setIsEditing(false);
    }
  };

  // Render input fields dynamically based on the input_items array from YAML
  const renderInputField = (field) => {
    return (
      <div key={field.field_name} className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-1">
          {field.label}
        </label>
        <input
          type={field.type}
          name={field.field_name}
          required={field.required}
          value={formData[field.field_name] || ""}
          onChange={(e) =>
            setFormData({ ...formData, [field.field_name]: e.target.value })
          }
          className="shadow appearance-none border border-gray-500 w-full py-2 px-3 text-gray-700 "
        />
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = {
      id: editId,
      conn_type: newConnectionName,
      conn_data: {
        connection_name: formData.connection_name,
        access_key: formData.access_key,
        secret_key: formData.secret_key,
        bucket_name: formData.bucket_name,
        tenant_id: formData.tenant_id,
        client_id: formData.client_id,
        client_secret: formData.client_secret,
        jdbc_url: formData.jdbc_url,
        username: formData.username,
        password: formData.password,
      },
      user_id: 1,
    };

    try {
      let response;
      if (isEditing) {
        response = await axios.post(
          `${currentUrl?.url}connections_update.phpp`,
          dataToSubmit
        );
      } else {
        response = await axios.post(
          `${currentUrl?.url}connInsert.php`,
          dataToSubmit
        );
      }

      if (response.status === 200) {
        alert(
          isEditing
            ? "Connection data successfully updated!"
            : "Connection data successfully stored in the database!"
        );
        setIsEditing(false);
        setFormData({});
        sessionStorage.removeItem("s3ConnectionData");
      } else {
        console.error("Failed to save connection data:", response);
        alert("Failed to save connection data.");
      }
    } catch (error) {
      console.error("Error saving connection data:", error);
      alert("An error occurred while saving connection data.");
    }
  };

  // Toggle function for small/medium screens
  const handleToggle = () => {
    setShowConnectionDetails(!showConnectionDetails);
  };

  return (
    <div className="w-screen h-screens  grid ">
      <div>
        <Navbar />
      </div>

      <div className="flex w-screen h-screen shadow-xl pt-9">
        <div className="fixed top-10 block md:hidden p-2 ">
          <TbPlugConnected
            onClick={() => dispatch(getConnectionSidebar(!connectionSidebar))}
            className="shadow-xl bg-blue-500 w-9 h-9 p-1 text-white rounded "
          />
        </div>
        <div className="hidden md:block border-r shadow-xl md:w-2/5 lg:w-1/4 py-5">
          <ConnectionDetails
            setNewConnectionName={setNewConnectionName}
            onEdit={handleDataFromChild}
          />
        </div>
        <div
          className={`fixed top-0  w-11/12 mt-10 border-r shadow-xl sm:w-1/2 bg-white h-screen z-40 block md:hidden transform ${
            connectionSidebar ? "translate-x-0" : "-translate-x-full"
          } transition-transform lg:translate-x-0`}
        >
          <div className=" p-1 flex justify-end m-2 ">
            <TbPlugConnectedX
              onClick={() => dispatch(getConnectionSidebar(!connectionSidebar))}
              className="shadow-xl bg-red-500 w-9 h-9 p-1  text-white rounded "
            />
          </div>
          <ConnectionDetails
            setNewConnectionName={setNewConnectionName}
            onEdit={handleDataFromChild}
          />
        </div>
        <div className="w-full flex justify-center place-items-center ">
          <div className="m-1 md:m-0 md:9/12 lg:w-1/2  p-8 md:border-t shadow-xl">
            {connectionData ? (
              <>
                <h3 className="text-2xl font-bold mb-6 text-white bg-blue-400 p-2 rounded">
                  Connection: {connectionData.name}
                </h3>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  {dynamicFields.length > 0 ? (
                    dynamicFields.map((field) => renderInputField(field))
                  ) : (
                    <p className="text-red-500">
                      No input fields found for this connection.
                    </p>
                  )}
                  <div className="flex justify-between">
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none w-full focus:shadow-outline"
                    >
                      {isEditing ? "Save Changes" : "Submit"}
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <p className="text-gray-600">Loading connection data...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withEventTracking(WriteIOConfigs);
