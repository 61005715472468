import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPipeline,
  setActivePipeline,
  deletePipeline,
  setPipeLineCount,
  setActiveContainer,
} from "../../features/PipeLines";
import { CiMenuKebab } from "react-icons/ci";
import { RiArrowDropRightFill, RiArrowDropDownFill } from "react-icons/ri";
import { TbCloudDataConnection } from "react-icons/tb";
import { FaRegSquarePlus } from "react-icons/fa6";
import { IoTrashOutline } from "react-icons/io5"; // Trash icon for delete
import { MdDriveFileRenameOutline } from "react-icons/md";
import { getSelectedNodeId } from "../../features/nodesData";
import ReNameModal from "./components/RenamePipeline";
import { IoMdCopy } from "react-icons/io";
import CopyPipeLineModal from "./components/CopyPipeLine";
import DeleteModal from "./components/DeletepipeLine";
import Moniter from "./moniter";
import DDL from "./ddl";
import Schemas from "./schema";
import SQLTransformations from "./sql";
import Templates from "./templates";
import Medallion from "./medallion";
import GlobalProperties from "./GlobalProperties";
import { GiTeePipe } from "react-icons/gi";
import GitBranches from "../git/components/GitBranches";
import { BsThreeDotsVertical } from "react-icons/bs";
function PipeLineSideBar() {
  const { pipeLines, activePipeline } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCopy, setIsOpenCopy] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false); // State for main kebab menu
  const [openMenu, setOpenMenu] = useState(null); // State for individual pipeline menu
  const [reNamePipeLine, setReNamePipeLine] = useState(null);
  const [deletePipeLine, setDeletePipeLine] = useState(null);
  const [copypipeLine, setCopyPipeLine] = useState(null);
  const addPipeLineHandler = () => {
    dispatch(addPipeline());
    dispatch(setActiveContainer("pipelines"));
    setShowMainMenu(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleReName = (pipeLine) => {
    setIsModalOpen(true);
    setReNamePipeLine(pipeLine);
  };
  const handleCopy = (pipeline) => {
    setIsOpenCopy(true);
    setCopyPipeLine(pipeline);
  };
  return (
    <div
      className={`w-full p-1 relative overflow-hidden max-h-full overflow-y-scroll con_sidebar`}
    >
      {/* Dropdown Header */}
      <GitBranches />
      <div
        className={`cursor-pointer flex justify-between place-items-start hover:bg-blue-500 hover:text-white transition p-1 border-b   ${
          isOpen ? "bg-blue-500 text-white" : "bg-white text-black"
        }`}
      >
        <div
          className="flex items-center w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {isOpen ? (
              <RiArrowDropDownFill className=" text-white" />
            ) : (
              <RiArrowDropRightFill className={` text-black`} />
            )}
          </span>
          <GiTeePipe
            className={`mx-1 ${
              isOpen ? "text-white" : "text-[rgb(38,154,248)]"
            } `}
          />{" "}
          <h2 className="text-md">Pipelines</h2>
        </div>
        <div
          className="p-1 hover:bg-white relative"
          onMouseEnter={() => setShowMainMenu(true)}
          onMouseLeave={() => setShowMainMenu(false)}
        >
          <CiMenuKebab />
          {showMainMenu && (
            <div className="absolute w-[150px] border bg-white shadow-md p-1 right-0 top-5 z-40">
              <button
                className=" w-full text-left text-gray-500 px-2 py-1 hover:border-t hover:border-b transition flex items-center"
                onClick={addPipeLineHandler}
              >
                <FaRegSquarePlus className="mx-1 text-blue-500" />
                <span className="text-sm">New Pipeline</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Dropdown Content */}
      {isOpen && (
        <div className="bg-white border transition-all max-h-[auto]  min-h-[200px] overflow-auto hide_scrollbar">
          <div className="z-50">
            {pipeLines.length >= 1 ? (
              pipeLines.map((pipeline) => (
                <div
                  key={pipeline?.id}
                  className={`cursor-pointer  flex justify-between px-2 py-1 items-center transition z-50 ${
                    pipeline.id === activePipeline
                      ? "bg-blue-50"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => {
                    dispatch(setActivePipeline(pipeline.id));
                    dispatch(setActiveContainer("pipelines"));
                    dispatch(getSelectedNodeId("parent"));
                  }}
                >
                  <div className="flex items-center max-w-full overflow-hidden">
                    <TbCloudDataConnection className="text-gray-500 hover:text-blue-500" />
                    <span className="text-sm text-gray-600 pl-3 whitespace-nowrap overflow-ellipsis overflow-hidden">
                      {pipeline.job_name}
                    </span>
                  </div>

                  {/* Inner Pipeline Menu */}
                  <div
                    className="p-1 hover:bg-white relative "
                    onMouseEnter={() => setOpenMenu(pipeline.id)}
                    onMouseLeave={() => setOpenMenu(null)}
                  >
                    <BsThreeDotsVertical className="text-gray-400 hover:text-gray-200" />
                    {openMenu === pipeline.id && (
                      <div className="absolute w-[150px] border bg-white shadow-md p-1 right-0 top-5 z-50">
                        <button
                          className=" w-full text-left text-gray-500 px-2 py-1 hover:border-t hover:border-b transition flex items-center"
                          onClick={() => {
                            handleReName(pipeline);
                          }}
                        >
                          <MdDriveFileRenameOutline className="mx-1 text-blue-500" />
                          <span className="text-sm text-gray-500">Rename</span>
                        </button>
                        <button
                          onClick={() => handleCopy(pipeline)}
                          className=" w-full text-left text-gray-500 px-2 py-1 hover:border-t hover:border-b transition flex items-center"
                        >
                          <IoMdCopy className="mx-1 text-blue-500" />
                          <span className="text-sm text-gray-500">Copy</span>
                        </button>
                        <button
                          className=" w-full text-left text-gray-500 px-2 py-1 hover:border-t hover:border-b transition flex items-center"
                          onClick={() => {
                            setDeletePipeLine(pipeline);
                            setIsDeleteModalOpen(true);
                          }}
                        >
                          <IoTrashOutline className="mx-1 text-red-500" />
                          <span className="text-sm text-red-500">Delete</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center p-1">
                <img src="/images/sidebar/box.png" className="w-5 h-5" alt="" />
                <span>No Pipelines Found</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="z-40">
        <DDL />
        <Schemas />
        <Medallion/>
        <SQLTransformations />
        <Templates />
        <GlobalProperties />
        <Moniter />
      </div>

      <ReNameModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        pipeLineName={reNamePipeLine}
      />
      <CopyPipeLineModal
        isOpen={isOpenCopy}
        onClose={() => setIsOpenCopy(false)}
        pipeLineName={copypipeLine}
      />
      <DeleteModal
        isOpen={isdeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        pipeLineName={deletePipeLine}
      />
    </div>
  );
}

export default PipeLineSideBar;
