import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobDetails: {
    job_name: "",
    run_type: "",
  },
  connections: [
    {
      conn_id: 1,
      id: 1,
      name: "",
      input_items: [
        {
          url: "",
          username: "",
          password: "",
          database: "",
        },
      ],
    },
  ],
};

const jobDetailsSlice = createSlice({
  name: "jobDetails",
  initialState,
  reducers: {
    getJobDetails: (state, { payload }) => {
      state.jobDetails = {
        ...state.jobDetails,
        [payload.name]: payload.value,
      };
    },
    getConnectionDetails: (state, { payload }) => {
      state.connections = payload;
    },
  },
});

export const { getJobDetails, getConnectionDetails } = jobDetailsSlice.actions;
export default jobDetailsSlice.reducer;
