import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import withEventTracking from "../../hoc/withEventTracking";
import { FaCaretUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getBottomTabheight } from "../../features/commonProperties";
import PropSettings from "./components/PropSettings";
import GeneralProperties from "./components/GeneralProperties";
import UserProperties from "./components/UserProperties";
import Parameters from "./components/Parameters";
import Variables from "./components/Variables";
import TabSettings from "./components/TabSettings";
import Output from "./components/Output";

const Properties = ({ item, yamlData }) => {
  const { bottomTabHeight, curretPropTab } = useSelector(
    (state) => state.commonProps
  );
  const { selectedNodeId } = useSelector((state) => state.totalNodes);
  const { currentUrl } = useSelector((state) => state.commonProps);
  const [inputValues, setInputValues] = useState({}); // Track inputs per item
  const [errors, setErrors] = useState({});
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (item) {
      const storedData = sessionStorage.getItem(
        `propertiesData-${item.uniqueId}`
      );
      if (storedData) {
        setInputValues(JSON.parse(storedData));
      } else {
        setInputValues({});
      }
    }
  }, [item]);

  useEffect(() => {
    const fetchConnectionTypes = async () => {
      try {
        const response = await fetch(
          `${currentUrl?.url}connections_readAll.php`
        );
        const responseText = await response.text();
        const jsonString = responseText
          .replace("Connected successfully to the database!", "")
          .trim();
        const responseData = JSON.parse(jsonString);

        const options = responseData.data.map((item) => {
          const connData = JSON.parse(item.conn_data);
          return {
            value: connData.connection_name,
            label: connData.connection_name,
            ...connData,
          };
        });

        setConnectionOptions(options);
      } catch (error) {
        console.error("Error fetching connection types:", error);
      }
    };

    fetchConnectionTypes();
  }, []);

  const saveToDatabase = async (data) => {
    try {
      const endpoint = `${currentUrl?.url}properties_insert.php`;
      const items = Object.keys(data).map((key) => ({
        u_id: `user-${key}`,
        id: key,
        name: `Item Name for ${key}`,
        input_items: data[key].split(",").map((item) => item.trim()),
        dependency: `dependency-${key}`,
      }));

      const payload = { tbname: "properties", items };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error(`Failed to save data. Status: ${response.status}`);
    } catch (error) {
      console.error("Error saving data to the database:", error);
    }
  };
  const handleInputChange = (name, value) => {
    if (!item) return;

    setInputValues((prev) => {
      const updated = { ...prev, [name]: value };
      sessionStorage.setItem(
        `propertiesData-${item.uniqueId}`,
        JSON.stringify(updated)
      ); // Store data for the specific item in sessionStorage
      return updated;
    });
  };

  const handleSelectConnection = (connection) => {
    setSelectedConnection(connection);
    setInputValues((prev) => ({
      ...prev,
      connectionId: connection.conn_id,
      connectionName: connection.label,
    }));
  };

  const renderProperty = (property) => {
    if (property.name === "connectionId") {
      return (
        <input
          type="text"
          value={
            selectedConnection?.conn_id || inputValues[property.name] || ""
          }
          readOnly
          className=" border w-96  p-1 property_input "
        />
      );
    }
    return (
      <input
        type="text"
        value={inputValues[property.name] || ""}
        onChange={(e) => handleInputChange(property.name, e.target.value)}
        className={`  border w-96 border-black p-1 ${
          errors[property.name] ? "border-red-500" : "border-gray-300"
        }`}
      />
    );
  };

  const handlePanelSize = () => {
    if (bottomTabHeight === 10) {
      dispatch(getBottomTabheight(60));
    } else {
      dispatch(getBottomTabheight(10));
    }
  };
  return (
    <>
      <div>
        {selectedNodeId === "parent" ? (
          <>
            {curretPropTab === "parameters" && (
              <>
                <Parameters yamlData={yamlData} />
              </>
            )}
            {curretPropTab === "variables" && (
              <>
                <Variables yamlData={yamlData} />
              </>
            )}
            {curretPropTab === "tabsettings" && (
              <>
                <TabSettings yamlData={yamlData} />
              </>
            )}
            {curretPropTab === "output" && (
              <>
                <Output yamlData={yamlData} />
              </>
            )}
          </>
        ) : (
          <>
            {curretPropTab === "general" && (
              <>
                <div className=" ">
                  <GeneralProperties />
                </div>
              </>
            )}
            {curretPropTab === "settings" && (
              <>
                <PropSettings yamlData={yamlData} />
              </>
            )}
            {curretPropTab === "userproperties" && (
              <>
                <UserProperties yamlData={yamlData} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withEventTracking(Properties);
