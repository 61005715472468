// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   pipelineName: "",
//   jobStatus: "",
//   startTime: "2024-02-24 00:00:01",
//   endTime: "2029-12-31 11:59:99",
//   jobs: [
//     {
//       job_id: "J12351",
//       pipeline_id: "P007",
//       pipeline_name: "Data Processing",
//       is_trigger: false,
//       trigger_id: "",
//       start_timestamp: "2025-02-24T16:00:00Z",
//       end_timestamp: "2025-02-24T16:40:00Z",
//       job_status: "Success",
//       executed_by: "developer",
//       error_log: null,
//     },
//     {
//       job_id: "J12352",
//       pipeline_id: "P008",
//       pipeline_name: "ETL Job",
//       is_trigger: true,
//       trigger_id: "T100",
//       start_timestamp: "2025-02-24T17:00:00Z",
//       end_timestamp: "2025-02-24T17:30:00Z",
//       job_status: "Failed",
//       executed_by: "admin",
//       error_log: "Connection Timeout",
//     },
//   ],
//   filteredJobs: [], // NEW: Store filtered jobs here
// };

// const jobFilterSlice = createSlice({
//   name: "jobFilter",
//   initialState,
//   reducers: {
//     setJobs: (state, { payload }) => {
//       state.jobs = payload;
//     },
//     setPipelineName: (state, action) => {
//       state.pipelineName = action.payload;
//     },
//     setJobStatus: (state, action) => {
//       state.jobStatus = action.payload;
//     },
//     setStartTime: (state, action) => {
//       state.startTime = action.payload;
//     },
//     setEndTime: (state, action) => {
//       state.endTime = action.payload;
//     },
//     filterJobs: (state) => {
//       state.filteredJobs = state.jobs.filter((job) => {
//         const { pipelineName, jobStatus, startTime, endTime } = state;

//         const matchesPipeline =
//           !pipelineName ||
//           job.pipeline_name.toLowerCase().includes(pipelineName.toLowerCase());

//         const matchesStatus =
//           !jobStatus ||
//           job.job_status.toLowerCase() === jobStatus.toLowerCase();

//         const matchesStartTime =
//           !startTime || new Date(job.start_timestamp) >= new Date(startTime);

//         const matchesEndTime =
//           !endTime || new Date(job.end_timestamp) <= new Date(endTime);

//         return (
//           matchesPipeline && matchesStatus && matchesStartTime && matchesEndTime
//         );
//       });
//     },
//     clearFilters: (state) => {
//       state.pipelineName = "";
//       state.jobStatus = "";
//       state.startTime = "";
//       state.endTime = "";
//       state.filteredJobs = state.jobs;
//     },
//   },
// });

// export const {
//   setPipelineName,
//   setJobStatus,
//   setStartTime,
//   setEndTime,
//   filterJobs,
//   clearFilters,
// } = jobFilterSlice.actions;
// export default jobFilterSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pipelineName: "",
  jobStatus: "",
  startTime: "2024-02-24T00:00:00Z",
  endTime: "2029-12-31T11:59:00Z",
  jobs: [],
  filteredJobs: [], // NEW: Store filtered jobs here
};

const jobFilterSlice = createSlice({
  name: "jobFilter",
  initialState,
  reducers: {
    setJobs: (state, { payload }) => {
      state.jobs = payload;
    },
    setPipelineName: (state, action) => {
      state.pipelineName = action.payload;
    },
    setJobStatus: (state, action) => {
      state.jobStatus = action.payload;
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action) => {
      state.endTime = action.payload;
    },
    filterJobs: (state) => {
      state.filteredJobs = state?.jobs?.filter((job) => {
        const { pipelineName, jobStatus, startTime, endTime } = state;

        // const matchesPipeline =
        //   !pipelineName ||
        //   job.pipeline_name
        //     ?.toLowerCase()
        //     .includes(pipelineName?.toLowerCase());

        const matchesStatus =
          !jobStatus ||
          job.job_status?.toLowerCase() === jobStatus?.toLowerCase();

        // Extract and parse the timestamps correctly
        const jobStart = job.start_date ? new Date(job.start_date) : null;
        const jobEnd = job.end_date ? new Date(job.end_date) : null;
        const filterStart = startTime ? new Date(startTime) : null;
        const filterEnd = endTime ? new Date(endTime) : null;

        // Ensure the dates are valid before comparing
        const matchesStartTime =
          !filterStart || (jobStart && jobStart >= filterStart);

        const matchesEndTime = !filterEnd || (jobEnd && jobEnd <= filterEnd);

        return matchesStatus && matchesStartTime && matchesEndTime;
      });
    },

    clearFilters: (state) => {
      state.pipelineName = "";
      state.jobStatus = "";
      state.startTime = "";
      state.endTime = "";
      state.filteredJobs = state.jobs;
    },
  },
});

export const {
  setJobs,
  setPipelineName,
  setJobStatus,
  setStartTime,
  setEndTime,
  filterJobs,
  clearFilters,
} = jobFilterSlice.actions;
export default jobFilterSlice.reducer;
