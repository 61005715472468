import React, { useState } from "react";
import { IoLogoGithub } from "react-icons/io";
import { useDispatch } from "react-redux";
import { setGitCredentials } from "../../features/userData";

const GitCredentialsPopup = ({ isOpen, onClose, onSave }) => {
  const [credentials, setCredentials] = useState({
    user_name: "",
    repo_name: "",
    token: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(setGitCredentials(credentials));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 text-center shadow-lg w-96 rounded-sm">
        <div className="flex justify-center items-center mb-3">
          <IoLogoGithub className="h-7 w-7 text-gray-700" />
        </div>
        <h2 className="text-[15px] font-semibold mb-4 text-center">
          Enter Git Credentials to save your data
        </h2>
        <div className="space-y-4 text-left">
          <div>
            <label
              htmlFor="user_name"
              className="block text-sm font-medium text-gray-700 property_labels"
            >
              GitHub Username
            </label>
            <input
              type="text"
              id="user_name"
              name="user_name"
              placeholder="Enter GitHub Username"
              value={credentials.user_name}
              onChange={handleChange}
              className="w-full py-1 px-2 border rounded-sm mt-1 text-[14px]"
              required
            />
          </div>

          <div>
            <label
              htmlFor="repo_name"
              className="block text-sm font-medium text-gray-700 property_labels"
            >
              Repository Name
            </label>
            <input
              type="text"
              id="repo_name"
              name="repo_name"
              placeholder="Enter Repository Name"
              value={credentials.repo_name}
              onChange={handleChange}
              className="w-full py-1 px-2 border rounded-sm mt-1 text-[14px]"
              required
            />
          </div>

          <div>
            <label
              htmlFor="token"
              className="block text-sm font-medium text-gray-700 property_labels"
            >
              GitHub Token
            </label>
            <input
              type="password"
              id="token"
              name="token"
              placeholder="Enter GitHub Token"
              value={credentials.token}
              onChange={handleChange}
              className="w-full py-1 px-2 border rounded-sm mt-1 text-[14px]"
              required
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-1 bg-gray-300 rounded-sm text-gray-700 hover:bg-gray-400 transition"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-1 bg-green-600 text-white rounded-sm hover:bg-green-700 transition"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GitCredentialsPopup;
