import React, { useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import ConnectionsModal from "./ConnectionsModal";
import {
  setConnectionPopup,
  setSelectedConnection,
} from "../../../features/connections";
import { setPipeLineConnection } from "../../../features/PipeLines";
const TreeNode = ({ node, onNewConnection }) => {
  const { selectedConnection } = useSelector((state) => state.connections);
  const { pipeLines, activePipeline } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const handleConnection = () => {
    setExpanded(!expanded);
    if (!node?.children) {
      dispatch(setSelectedConnection(node));
      const { connection_name, id, ...otherData } = node;
      const finalConnection = [
        {
          conn_id: id,
          name: connection_name,
          ...otherData,
        },
      ];
      const pipelineConnection = pipeLines.map((pipeline) => {
        if (pipeline?.id === activePipeline) {
          return { ...pipeline, connections: finalConnection };
        } else {
          return pipeline;
        }
      });

      dispatch(setPipeLineConnection(pipelineConnection));
      dispatch(
        setConnectionPopup({
          show: true,
          message: `${node?.connection_name} Connection is Selected`,
        })
      );
    }
  };
  return (
    <>
      <div className=" text-md border-l border-slate-200 overflow-auto  ">
        <div
          className={`flex items-center cursor-pointer py-1 m-1  hover:text-white hover:bg-blue-300 property_labels ${
            node?.id === selectedConnection?.id
              ? "border bg-[rgb(38,154,248)] text-white"
              : ""
          }`}
          onClick={handleConnection}
        >
          <hr className="border-b-1 w-2 border-black" />
          {node.children && (
            <span className="my-1">
              {expanded ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
            </span>
          )}
          {node?.connection_name}
        </div>

        {expanded && (
          <div className="ml-4">
            {/* Render child nodes */}
            {node.children &&
              node.children.map((child) => (
                <TreeNode
                  key={child.id}
                  node={child}
                  onNewConnection={onNewConnection}
                />
              ))}

            {/* "New Connection" button (only for parents) */}
            {node.children && (
              <button
                className="mt-2 px-3 py-1 bg-[rgb(38,154,248)] text-white property_labels"
                onClick={() => onNewConnection(node)}
              >
                New Connetcion
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const TreeView = ({ data }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState("");
  const handleNewConnection = (parentName) => {
    setSelectedParent(parentName);
    setPopupOpen(true);
  };

  return (
    <div className="w-[100%] pl-6">
      {data.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          onNewConnection={handleNewConnection}
        />
      ))}

      {popupOpen && (
        <ConnectionsModal
          onClose={() => setPopupOpen(false)}
          parentName={selectedParent}
        />
      )}
    </div>
  );
};

const TreeStructure = () => {
  const { connections } = useSelector((state) => state?.connections);
  return (
    <>
      <TreeView data={connections} />
    </>
  );
};

export default TreeStructure;
