import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bottomTabHeight: 8,
  showAdvanceSettings: false,
  curretPropTab: "parameters",
  sidebarOpen: false,
  showPopup: false,
  sourceCodeOpen: false,
  sourceObject: {},
  connectionSidebar: false,
  rightBar: false,
  jobstatus: { show: false, message: "" },
  phpUrl: {
    language: "PHP",
    url: "https://dev-etl-api.tourdeals.co.in/common/",
  },
  pythonUrl: {
    language: "PYTHON",
    url: "http://34.57.144.120:5001/",
  },
  currentUrl: {
    language: "PHP",
    url: "https://dev-etl-api.tourdeals.co.in/common/",
  },
  customUrl: {
    isCustom: true,
    url: "http://192.168.31.159:5000/spark/submit-job",
  },
};

const commonPropTabSlice = createSlice({
  name: "commonPropTab",
  initialState,
  reducers: {
    getBottomTabheight: (state, { payload }) => {
      state.bottomTabHeight = payload;
    },
    getAdvanceSettings: (state, { payload }) => {
      state.showAdvanceSettings = payload;
    },
    getCurrentTab: (state, { payload }) => {
      state.curretPropTab = payload;
    },
    getSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    getShowPopup: (state, { payload }) => {
      state.showPopup = payload;
    },
    getSourceCodeOpen: (state, { payload }) => {
      state.sourceCodeOpen = payload;
    },
    getSourdeObject: (state, { payload }) => {
      state.sourceObject = payload;
    },
    getConnectionSidebar: (state, { payload }) => {
      state.connectionSidebar = payload;
    },
    getRightSidebar: (state, { payload }) => {
      state.rightBar = payload;
    },
    getJobStatus: (state, { payload }) => {
      state.jobstatus = payload;
    },
    getCurrentUrl: (state, { payload }) => {
      state.currentUrl = payload;
    },
    getisCustomUrl: (state, { payload }) => {
      state.customUrl = payload;
    },
  },
});

export const {
  getBottomTabheight,
  getAdvanceSettings,
  getCurrentTab,
  getSidebarOpen,
  getShowPopup,
  getSourceCodeOpen,
  getSourdeObject,
  getConnectionSidebar,
  getRightSidebar,
  getJobStatus,
  getCurrentUrl,
  getisCustomUrl,
} = commonPropTabSlice.actions;
export default commonPropTabSlice.reducer;
