import React, { useState, useEffect, useContext, useRef } from "react";
import {
  getPanelElement,
  getPanelGroupElement,
  getResizeHandleElement,
} from "react-resizable-panels";

import RightSidebar from "../RightSidebar";
import InputModal from "../InputModal";
import Navbar from "../Navbar";
import yaml from "js-yaml";
import withEventTracking from "../../hoc/withEventTracking";
import { fetchYaml } from "../../utils/fetchYaml";
import { useDispatch, useSelector } from "react-redux";
import {
  getRightSidebar,
  getSourceCodeOpen,
} from "../../features/commonProperties";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { SourceCode } from "../Canvas/Components/SourceCode";
import PiplineName from "../Sidebar/components/PiplineName";
import JobValidation from "../validations/JobValidation";
import PipeLineSideBar from "../pipelines/PipeLine";
import Tabs from "../../PipeLineTabs/Tabs";
import DeleteNodeModal from "../Activity/components/DeleteNodeModal";
import MoniterDetails from "../pipelines/moniter/components/MoniterDetails";
import GlobalCollectors from "../pipelines/GlobalProperties/components/Collector";
import SuccessAnimation from "../pipelines/components/GitSuccess";
import { setIsSavedInGit } from "../../features/userData";
import DDL from "../ddl";
import Medallion from "../pipelines/medallion";
import DatasetForm from "../pipelines/ddl/components/DatasetProps";
import Activities from "../Activity/Activities";
import SchemaEditor from "../pipelines/schema/components/SchemaEditor";
import { CiMenuKebab } from "react-icons/ci";

const Home = ({ logCustomEvent }) => {
  const { showPopup } = useSelector((state) => state.commonProps);
  const { isDeleteModalaOpen } = useSelector((state) => state.totalNodes);
  const { activeContainer } = useSelector((state) => state.pipeLines);
  const { isSavedInGit } = useSelector((state) => state.userData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [canvasItems, setCanvasItems] = useState([]);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();
  const [expandPipeline, setExpandPipeline] = useState(true);
  const [history, setHistory] = useState({
    past: [],
    present: [],
    future: [],
  });
  const refs = useRef({});

  useEffect(() => {
    const groupElement = getPanelGroupElement("group");
    const leftPanelElement = getPanelElement("left-panel");
    const rightPanelElement = getPanelElement("right-panel");
    const resizeHandleElement = getResizeHandleElement("resize-handle");

    refs.current = {
      groupElement,
      leftPanelElement,
      rightPanelElement,
      resizeHandleElement,
    };
  }, []);

  const updateHistory = (newPresent) => {
    setHistory((prevHistory) => ({
      past: prevHistory.present
        ? [...prevHistory.past, prevHistory.present]
        : prevHistory.past,
      present: newPresent,
      future: [],
    }));
    setCanvasItems(newPresent);
  };

  const handleSave = (uniqueId, inputValue) => {
    const newCanvasItems = canvasItems.map((item) =>
      item.uniqueId === uniqueId ? { ...item, inputValue } : item
    );
    updateHistory(newCanvasItems);
    setIsModalOpen(false);
    logCustomEvent("itemSave", { uniqueId, inputValue });

    localStorage.setItem("canvasState", JSON.stringify(newCanvasItems));
  };

  const undo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.past.length === 0) return prevHistory;

      const previous = prevHistory.past[prevHistory.past.length - 1];
      const newPast = prevHistory.past.slice(0, prevHistory.past.length - 1);

      return {
        past: newPast,
        present: previous,
        future: [prevHistory.present, ...prevHistory.future],
      };
    });
  };

  const redo = () => {
    setHistory((prevHistory) => {
      if (prevHistory.future.length === 0) return prevHistory;

      const next = prevHistory.future[0];
      const newFuture = prevHistory.future.slice(1);

      return {
        past: [...prevHistory.past, prevHistory.present],
        present: next,
        future: newFuture,
      };
    });
  };

  useEffect(() => {
    setCanvasItems(history.present || []);
  }, [history.present]);

  useEffect(() => {
    const savedCanvasState = localStorage.getItem("canvasState");
    if (savedCanvasState) {
      const parsedCanvasState = JSON.parse(savedCanvasState);
      updateHistory(parsedCanvasState);
    }
  }, []);

  useEffect(() => {
    const connectionData = async () => {
      try {
        const data = await fetchYaml("/path/connections/fetchData.yaml");
        if (data && Array.isArray(data.items)) {
          // setItems(data.items);
          setItems((prevItems) => [...prevItems, ...data.items]);
        } else {
          console.warn("Items not found or is not an array", data);
        }
      } catch (error) {
        console.error("Failed to load connection data:", error);
      }
    };
    connectionData();
  }, []);

  useEffect(() => {}, [items]);

  const handleFileOpen = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/yaml") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const text = e.target.result;
          const parsedData = yaml.load(text);
          const newCanvasItems = parsedData.items.map((item) => ({
            ...item,
            uniqueId: `${item.path}-${+new Date()}`,
          }));
          updateHistory(newCanvasItems);
        } catch (error) {
          console.error("Error parsing YAML file:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handlePipeline = () => {
    setExpandPipeline(!expandPipeline);
  };
  const closeSideTabs = () => {
    dispatch(getSourceCodeOpen(false));
    dispatch(getRightSidebar(false));
  };
  return (
    <>
      <div
        onClick={closeSideTabs}
        className="w-screen hide_scrollbar h-screen grid grid-rows-[auto_1fr] grid-cols-1 grid-areas-[navbar_navbar]"
      >
        <div className="bg-white grid-area-[navbar] z-50">
          <Navbar handleFileOpen={handleFileOpen} />
        </div>

        <div
          className={`grid  grid:cols-1  lg:grid-cols-[18%_1fr] ${
            expandPipeline
              ? "lg:grid-cols-[18%_1fr]"
              : "lg:grid-cols-[auto_1fr]"
          } lg:grid-areas-[sidebar1_canvas] h-full"`}
        >
          <div className="h-[100%] border-r border-gray-300 overflow-auto  grid-area-[sidebar1] hidden lg:block">
            <div
              className={`bg-white ${
                !expandPipeline && "grid sidebar_close"
              } justify-between h-full`}
            >
              <h2 className="w-full p-1 trasform duration-300 text-start font-bold flex justify-between ">
                {expandPipeline && "Resources"}
                {expandPipeline ? (
                  <VscArrowLeft
                    className="w-6 h-6 hover:bg-[rgb(38,154,248)] p-1 hover:text-white"
                    onClick={handlePipeline}
                  />
                ) : (
                  <VscArrowRight
                    className="w-6 h-6 hover:bg-[rgb(38,154,248)] p-1 hover:text-white "
                    onClick={handlePipeline}
                  />
                )}
              </h2>
              {expandPipeline ? <PipeLineSideBar /> : <PiplineName />}
            </div>
          </div>

          {activeContainer === "moniter" && <MoniterDetails />}
          {activeContainer === "pipelines" && (
            <Tabs logCustomEvent={logCustomEvent} />
          )}
          {activeContainer === "global" && <GlobalCollectors />}
          {activeContainer === "file" && <DDL />}
          {activeContainer === "dataset" && <DatasetForm />}

          {activeContainer === "activities" && (
            <Activities logCustomEvent={logCustomEvent} />
          )}

          {activeContainer === "schema" && <SchemaEditor />}
        </div>
      </div>

      <div className="fixed top-10 right-0 z-40">
        <RightSidebar
          canvasItems={canvasItems}
          updateHistory={updateHistory}
          className=""
        />

        {isModalOpen && (
          <InputModal
            item={currentItem}
            onSave={handleSave}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </div>
      <div
        className={`fixed bottom-4 right-4 bg-green-600 z-40 text-white px-6 py-3 rounded-lg shadow-lg transition-transform duration-500 ease-in-out ${
          showPopup ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
        }`}
        style={{ visibility: showPopup ? "visible" : "hidden" }}
      >
        Properties Saved
      </div>
      <SourceCode />
      <JobValidation />
      {isSavedInGit && (
        <SuccessAnimation onComplete={() => dispatch(setIsSavedInGit(false))} />
      )}
      {isDeleteModalaOpen && <DeleteNodeModal />}
    </>
  );
};

export default withEventTracking(Home);
