import React from "react";
import { eventLogger } from "../utils/eventLogger";

const withEventTracking = (WrappedComponent) => {
  return (props) => {
    const logCustomEvent = (eventName, eventData) => {
      const event = { eventName, eventData, timestamp: new Date() };
      eventLogger.logEvent(event);
    };

    return <WrappedComponent {...props} logCustomEvent={logCustomEvent} />;
  };
};

export default withEventTracking;
