import React, { useState, useRef, useEffect } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { AiFillDatabase } from "react-icons/ai";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setActiveContainer } from "../../../features/PipeLines";
import SidebarFolderTree from "./components/DdlTreeSidebar";
import { addItem } from "../../../features/DDL";
import { DiDatabase } from "react-icons/di";
import { IoFolderOpen } from "react-icons/io5";
import { MdOutlineSchema } from "react-icons/md";
const DDL = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const { ddlData } = useSelector((state) => state.ddlData);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent triggering parent click
    setMenuOpen(!menuOpen);
  };

  const closeMenu = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeMenu);
    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, []);

  const handleNewDataSet = (e) => {
    e.stopPropagation(); // Prevent parent click event
    dispatch(addItem({ parentId: null, type: "dataset" }));
    setMenuOpen(false); // Close menu after action
  };

  const handleNewFolder = (e) => {
    e.stopPropagation(); // Prevent parent click event
    dispatch(addItem({ parentId: null, type: "folder" }));
    setMenuOpen(false); // Close menu after action
  };

  return (
    <div className="w-full border-b z-50">
      <div
        className={`flex justify-between items-center p-1 ${
          isOpen ? "bg-[rgb(38,154,248)] text-white " : "bg-white text-black"
        }  cursor-pointer`}
        onClick={toggleDropdown}
      >
        {/* {isOpen ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}{" "}
              <span>Schemas</span> */}
        <div className="flex items-center w-full ">
          <span className={`${isOpen ? "text-white" : "text-black"}`}>
            {isOpen ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}
          </span>
          <MdOutlineSchema className="mx-1 text-[rgb(38,154,248)]" />
          <h2 className={`text-md ${isOpen ? "text-white" : "text-black"}`}>
            DDL
          </h2>
        </div>

        <div className="relative">
          <div onClick={toggleMenu} className="p-1">
            <CiMenuKebab />
          </div>
          {menuOpen && (
            <div className="absolute right-0 mt-2 bg-white border shadow-md rounded w-40 z-50">
              <button
                onClick={handleNewDataSet}
                className="block w-full text-left text-black px-4 py-2 hover:bg-gray-200"
              >
                New Dataset
              </button>
              <button
                onClick={handleNewFolder}
                className="block w-full text-left text-black px-4 py-2 hover:bg-gray-200"
              >
                New Folder
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="bg-white border border-gray-200 p-2">
          <SidebarFolderTree />
        </div>
      )}
    </div>
  );
};

export default DDL;
