import React, { useEffect } from "react";
import { FaCaretUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTab } from "../../../features/commonProperties";
import { getFocusErrorInput } from "../../../features/PipeLines";

function PropTabNames() {
  const { curretPropTab } = useSelector((state) => state.commonProps);
  const { selectedNodeId } = useSelector((state) => state.totalNodes);

  const dispatch = useDispatch();
  return (
    <>
      <span className="flex justify-center place-items-start w-full relative top-[1.5px]">
        <hr className="w-6 rounded-full border border-gray-500" />
      </span>
      <div className="property_names border-t border-b border-gray-400  ">
        {selectedNodeId === "parent" ? (
          <>
            <ul className="flex justify-start property_labels align-middle  ">
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer flex transition-transform place-items-center ${
                  curretPropTab === "parameters" && "border-b-2 border-blue-500"
                } `}
                onClick={() => dispatch(getCurrentTab("parameters"))}
              >
                <img src="/images/gear.png" className="w-4 h-4 m-1" alt="" />
                Parameters
              </li>
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer transition-transform flex place-items-center ${
                  curretPropTab === "variables" && "border-b-2 border-blue-500"
                }`}
                onClick={() => dispatch(getCurrentTab("variables"))}
              >
                <img src="/images/array.png" className="w-4 h-4 m-1" alt="" />{" "}
                Variables
              </li>
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer transition-transform flex place-items-center ${
                  curretPropTab === "tabsettings" &&
                  "border-b-2 border-blue-500"
                }`}
                onClick={() => dispatch(getCurrentTab("tabsettings"))}
              >
                <img
                  src="/images/settings.png"
                  className="w-4 h-4 m-1"
                  alt=""
                />{" "}
                settings
              </li>
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer transition-transform flex place-items-center ${
                  curretPropTab === "output" && "border-b-2 border-blue-500"
                }`}
                onClick={() => dispatch(getCurrentTab("output"))}
              >
                <img src="/images/logout.png" className="w-4 h-4 m-1" alt="" />{" "}
                Output
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="flex justify-start property_labels align-middle  ">
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer flex transition-transform place-items-center ${
                  curretPropTab === "general" && "border-b-2 border-blue-500"
                } `}
                onClick={() => {
                  dispatch(getCurrentTab("general"));
                  dispatch(getFocusErrorInput(""));
                }}
              >
                <img
                  src="/images/property.png"
                  className="w-4 h-4 m-1"
                  alt=""
                />
                General
              </li>
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer transition-transform flex place-items-center ${
                  curretPropTab === "settings" && "border-b-2 border-blue-500"
                }`}
                onClick={() => dispatch(getCurrentTab("settings"))}
              >
                <img
                  src="/images/cogwheel.png"
                  className="w-4 h-4 m-1"
                  alt=""
                />{" "}
                Settings
              </li>
              <li
                className={` px-2 py-1 text-blue-500 cursor-pointer transition-transform flex place-items-center ${
                  curretPropTab === "userproperties" &&
                  "border-b-2 border-blue-500"
                }`}
                onClick={() => dispatch(getCurrentTab("userproperties"))}
              >
                <img src="/images/folder.png" className="w-4 h-4 m-1" alt="" />{" "}
                User Properties
              </li>
            </ul>
          </>
        )}
        <div
          className="flex justify-center  place-items-center open_proptab"
          //   onClick={handlePanelSize}
        >
          <FaCaretUp fill="#36abf6" />
        </div>
      </div>
    </>
  );
}

export default PropTabNames;
