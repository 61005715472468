import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SampleNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 bg-[rgb(38,154,248)]  ${
        scroll
          ? "md:bg-white shadow-md bg-[rgb(38,154,248)]  "
          : "md:bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-black">
              Logo
            </Link>
          </div>
          <div className="hidden md:flex space-x-4">
            <Link to="/" className="text-black hover:text-gray-600">
              Home
            </Link>
            <Link to="/about" className="text-black hover:text-gray-600">
              About
            </Link>
            <Link to="/services" className="text-black hover:text-gray-600">
              Services
            </Link>
            <Link to="/contact" className="text-black hover:text-gray-600">
              Contact
            </Link>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none text-black"
            >
              {isOpen ? "✖" : "☰"}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-white p-4 shadow-md">
          <Link to="/" className="block py-2 text-black hover:text-gray-600">
            Home
          </Link>
          <Link
            to="/about"
            className="block py-2 text-black hover:text-gray-600"
          >
            About
          </Link>
          <Link
            to="/services"
            className="block py-2 text-black hover:text-gray-600"
          >
            Services
          </Link>
          <Link
            to="/contact"
            className="block py-2 text-black hover:text-gray-600"
          >
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

export default SampleNav;
