import React, { useEffect, useState, useRef } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { setPipeLineTasks } from "../../../features/PipeLines";

const ReNameModal = ({ isOpen, onClose, onConfirm, pipeLineName }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field
  const { pipeLines } = useSelector((state) => state.pipeLines);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpen && pipeLineName) {
      setInputValue(pipeLineName.job_name || "");

      // Wait for the input to render, then select the text
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select(); // Select the text inside the input
        }
      }, 100);
    }
  }, [isOpen, pipeLineName]);

  if (!isOpen) return null;

  const onConfirmRename = () => {
    const renamedPipeLine = pipeLines?.map((pipeline) => {
      if (pipeline?.id === pipeLineName?.id) {
        return { ...pipeline, job_name: inputValue };
      } else {
        return pipeline;
      }
    });
    dispatch(setPipeLineTasks(renamedPipeLine));
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30"></div>
      <div className="relative bg-white p-6  shadow-lg max-w-md w-full">
        {/* <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <ImCancelCircle />
        </button> */}
        <label htmlFor="">Are you sure want to Rename </label>
        <form className="mt-4 space-y-3">
          <input
            ref={inputRef} // Attach the ref to the input field
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="mt-1 p-2 w-full border border-black focus:outline-none "
          />

          <div className="flex justify-end place-items-center mt-4 h-full gap-2">
            <button
              type="button"
              className="mx-2 px-4 border property_labels border-blue-600 rounded-[2px] py-1 bg-blue-600 text-white hover:bg-[rgb(38,154,248)]"
              onClick={onConfirmRename}
            >
              Rename
            </button>
            <button
              type="button"
              className="rounded-[2px] mx-2 px-4 border property_labels py-1 border-black hover:border-[rgb(38,154,248)] hover:text-white bg-white text-black hover:bg-[rgb(38,154,248)]"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReNameModal;
