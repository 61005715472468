import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSourceCodeOpen } from "../../../features/commonProperties";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdContentCopy } from "react-icons/md";
import { GrLinkNext } from "react-icons/gr";

export const SourceCode = () => {
  const { sourceCodeOpen, sourceObject } = useSelector(
    (state) => state?.commonProps
  );
  const dispatch = useDispatch();
  const [copyStatus, setCopyStatus] = useState({
    showmessage: false,
    status: "",
    message: "",
  });
  const handleCopyToClipboard = () => {
    if (sourceObject) {
      navigator.clipboard
        .writeText(JSON.stringify(sourceObject, null, 2))
        .then(() => {
          setCopyStatus({
            showmessage: true,
            status: "success",
            message: "Sourcecode Copied!",
          });
        })
        .catch(() => {
          setCopyStatus({
            showmessage: true,
            status: "failed",
            message: "Failed to copy Sourcecode! Try again",
          });
        });
    }
    setTimeout(() => {
      setCopyStatus({
        showmessage: false,
        status: "",
        message: "",
      });
    }, 2000);
  };

  return (
    <div
      className={`fixed top-0 right-0 h-screen w-11/12 md:w-1/2 z-40 bg-white border-l text-black shadow-lg transform transition-transform  duration-400 ease-in-out ${
        sourceCodeOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="pt-10">
        <div className="w-full flex flex-wrap justify-between place-items-center border-b px-4">
          <label htmlFor="sourcename">Activity Name</label>
          <input
            value={sourceObject?.name || ""}
            disabled
            id="sourcename"
            className="p-2"
          />
        </div>
        <div className="source_object p-3 overflow-y-scroll">
          <div className="flex justify-end place-items-center pr-10 cursor-pointer">
            <div className="copy_tooltip ">
              <MdContentCopy
                onClick={handleCopyToClipboard}
                className="hover:text-blue-500 copy_icon text-xl"
              />
              <span className={`copy_icon_titles `}>Copy Sourcecode!</span>
            </div>
          </div>
          <SyntaxHighlighter language="json" style={coy}>
            {JSON.stringify(sourceObject, null, 2)}
          </SyntaxHighlighter>
        </div>
      </div>
      <div className="fixed bottom-1 px-4">
        <button
          onClick={() => dispatch(getSourceCodeOpen(false))}
          className="close_button hover:scale-105 text-white px-5 py-1"
        >
          <GrLinkNext />
        </button>
      </div>
      <div
        className={`fixed w-fit bottom-4 right-0  ${
          copyStatus?.status === "success" ? "bg-green-600" : "bg-red-400"
        } z-40 text-white px-6 py-2 text-md shadow-lg transition-transform duration-500 ease-in-out ${
          copyStatus.showmessage
            ? "translate-y-0 opacity-100"
            : "translate-y-10 opacity-0"
        }`}
        style={{ visibility: copyStatus.showmessage ? "visible" : "hidden" }}
      >
        {copyStatus?.message}
      </div>
    </div>
  );
};
