import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setCurrentPipeLineTasks,
  setPipeLineTasks,
} from "../../../features/PipeLines";
import axios from "axios";

const Output = () => {
  const [currentJobLogs, setCurrentJobLogs] = useState([]);
  const { tasksOutput, activePipeline, currentPipeLineTasks } = useSelector(
    (state) => state.pipeLines
  );

  useEffect(() => {
    const currentJob = tasksOutput?.find((job) => job?.id === activePipeline);
    setCurrentJobLogs(currentJob?.tasks || []);
  }, [tasksOutput, activePipeline]);
  // if(data){
  //   dispatch(
  //                 setCurrentPipeLineTasks({
  //                   ...currentPipeLineTasks,
  //                   tracking_url,
  //                   job_id,
  //                   spark_master_ui,
  //                 })
  //               );
  //               const updateJob_id = pipeLines?.map((pipe) => {
  //                 if (pipe?.id === activePipeline) {
  //                   return {
  //                     ...currentPipeLineTasks,
  //                     tracking_url,
  //                     job_id,
  //                     spark_master_ui,
  //                   };
  //                 } else {
  //                   return pipe;
  //                 }
  //               });
  //               dispatch(setPipeLineTasks(updateJob_id));
  // }
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${currentPipeLineTasks.spark_master_ui}${currentPipeLineTasks.tracking_url}`,
        {}, // Empty body (if required)
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Fetched Data:", response.data);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    fetchData(); // Initial API call

    const interval = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="flex justify-center items-start mt-16 h-full w-full">
      {currentJobLogs?.length > 0 ? (
        <div className="p-4 w-full h-[500px] overflow-auto">
          <h2 className="text-xl font-semibold mb-4">Execution Logs</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="p-2 border">Start Time</th>
                  <th className="p-2 border">End Time</th>
                  <th className="p-2 border">Execution Time</th>
                  <th className="p-2 border">Input JSON</th>
                  <th className="p-2 border">Output JSON</th>
                  <th className="p-2 border">Status</th>
                  <th className="p-2 border">Error</th>
                  <th className="p-2 border">Help</th>
                </tr>
              </thead>
              <tbody>
                {currentJobLogs.map((log, index) => (
                  <tr
                    key={index}
                    className="border-b hover:bg-gray-50 text-center"
                  >
                    <td className="p-2 border">{log.start_time_stamp}</td>
                    <td className="p-2 border">{log.end_time_stamp}</td>
                    <td className="p-2 border">{log.execution_time}</td>
                    <td className="p-2 border text-left">
                      <pre className="whitespace-pre-wrap text-xs">
                        {JSON.stringify(log.input_json, null, 2)}
                      </pre>
                    </td>
                    <td className="p-2 border text-left">
                      <pre className="whitespace-pre-wrap text-xs">
                        {JSON.stringify(log.output_json, null, 2)}
                      </pre>
                    </td>
                    <td
                      className={`p-2 border font-semibold ${
                        log.task_status === "Success"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {log.task_status}
                    </td>
                    <td className="p-2 border text-red-500 text-xs">
                      {log.task_error || "-"}
                    </td>
                    <td className="p-2 border text-blue-500 underline cursor-pointer">
                      {log.help || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="grid justify-center place-items-center">
          <img
            src="/images/sidebar/box.png"
            className="w-20 h-20"
            alt="No Output"
          />
          <p className="font-extrabold text-xl text-gray-400">
            No Output Found
          </p>
        </div>
      )}
    </div>
  );
};

export default Output;
