import React, { useRef } from "react";
import withEventTracking from "../../hoc/withEventTracking";
import { MdOutlineSaveAs } from "react-icons/md";
import { VscNewFile } from "react-icons/vsc";
import { VscSaveAs } from "react-icons/vsc";
import { IoFolderOpenOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";

import { AiTwotoneDelete } from "react-icons/ai";

const Buttonbar = ({
  onNewCanvas,
  onDelete,
  onSave,
  onOpen,
  selectedItemId,
}) => {
  const fileInputRef = useRef(null);

  const handleOpenClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const content = await file.text();
      onOpen(event);
    }
  };

  const handleSaveClick = () => {
    onSave();
  };

  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(selectedItemId);
    }
  };

  return (
    <div className="flex justify-center place-items-center w-1/2">
      <button onClick={onNewCanvas} title="New" className="mx-2">
        <VscNewFile className="navicons" />
        {/* <img src="/images/add.png" alt="" className="w-4 bg-white rounded-md" /> */}
      </button>
      <button onClick={handleSaveClick} title="Save" className="mx-2">
        {/* <img src="/images/save.png" alt="" className="w-5" /> */}
        <VscSaveAs className="navicons" />
      </button>
      <button onClick={handleOpenClick} title="Open" className="mx-2">
        {/* <img src="/images/open-folder.png" alt="" className="w-5" /> */}
        <IoFolderOpenOutline className="navicons" />
      </button>
      <button title="Delete" onClick={handleDeleteClick} className="mx-2">
        {/* <img src="/images/delete.png" alt="" className="w-5" /> */}
        <AiOutlineDelete className="navicons" />
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default withEventTracking(Buttonbar);
